import { hot } from 'react-hot-loader/root';
import React, { Fragment, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { Header, Notification, GateKeeper, LoadingScreen } from '@components'
import { Wallet } from '@archetypes'
import { Dashboard, Pool, Asset, Reward, Liquidation, Admin } from '@routes'

import assetController from '@app/App.Controller'
import { Networks } from '@app/App.config'

import { 
	Vars as VarsStyle,
	Base as BaseStyle,
	Layout as LayoutStyle,
	Theme as ThemeStyle
} from '@app/App.style'


const Main = hot(() =>{
	return <Fragment>
		<Notification className='global-notifications'/>
		<Header className='global-header'/>
		<section className="content">
			<Wallet.Upgrade.Banner/>
			<Wallet.UnlockLP/>
			<Switch>
				<Route exact path="/dashboard" component={Dashboard}/>
				<Route path="/pools" component={Pool}/>
				<Route path="/assets" component={Asset}/>
				<Route path="/rewards" component={Reward}/>
				<Route path="/liquidations" component={Liquidation}/>
				<Route path="/admin" component={Admin}/>
				<Redirect to='/pools'/>
			</Switch>
		</section>
	</Fragment>
})


export default () => {
	
	const [ isReady, setReady ] = useState(true)
	const [ errors, setErrors ] = useState({})

	useEffect(() => {
		assetController.configure({
			cache: {
				key: 'perlinx'
			},
			network: {
				available: Networks
			}, 
			onReady: () => {
				setReady(true)
			},
			onStatus: (type, isError, props) => {
				if(isError === false){
					delete errors[type]
				}else{
					errors[type] = props
				}

				setErrors({...errors})
			}
		})
	}, []) // eslint-disable-line

	return <Fragment>
		<VarsStyle/>
		<BaseStyle/>
		<LayoutStyle/>
		<ThemeStyle/>
		<GateKeeper
			assertions={[
				{
					assert: () => Object.keys(errors).length <= 0,
					onFail: () => <LoadingScreen message={'The following issues need to be resolved before using this application'} errors={errors}/>
				},
				{
					assert: () => isReady === true,
					onFail: () => <LoadingScreen message={'Loading application'}/>,
				}
			]}
			dependencies={[errors, isReady]}
			>
			<Main/>
		</GateKeeper>
	</Fragment>
}