import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Panel, Stat, GateKeeper, LazyBoi } from '@components'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import assetController, { Status } from '@app/App.Controller'

export default ({address}) => {
	const { account, perlinx } = assetController
	const [ poolData, setPooldata ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe(`pools.${address}`, pool => {
			if(pool.stake) setPooldata(pool)
		}, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <GateKeeper
		controllerAssertions={[
			{
				controller: 'account',
				key: 'status',
				assert: val => val === Status.READY,
				onFail: <Fragment/>
			}
		]}
		assertions={[
			{
				assert: () => !!poolData,
				onFail: () => <Panel><Refresh className='rotate'/></Panel>
			}
		]}
		dependencies={[account.state.address, poolData]}
		>
		<Panel.Divider/>
		<Panel.Footer>
			<Grid cols={2}>
				<Stat small animateChange title='Your Share'><LazyBoi.Percent value={poolData?.stake?.share}/></Stat>
				<Stat small animateChange title='Your Assets'><LazyBoi.Currency value={poolData?.stake?.value} fromWei/></Stat>
				<Stat small animateChange title='Total LP Tokens'><LazyBoi value={poolData?.rewards?.available} fromWei dp={4}/></Stat>
				<Stat small animateChange title='Total Tokens Locked'><LazyBoi.Wei value={poolData?.rewards?.locked} dp={4}/></Stat>
			</Grid>
		</Panel.Footer>
	</GateKeeper>
}