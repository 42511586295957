import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components'
import { Panel, Stat, LazyBoi, Tag, GateKeeper, Grid } from '@components'
import { Asset } from '@archetypes'
import { fromWei } from '@util/helpers'
import appController, { Status } from '@app/App.Controller'
import { ReactComponent as IconCheck } from '@assets/Icon_Check.svg';
import { ReactComponent as IconCancel } from '@assets/Icon_Cancel.svg';
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';

const SafeIcon = styled(props => <IconCheck {...props} />)`
	color: var(--color-primary);
	margin-left: 0.5rem;
`

const UnsafeIcon = styled(props => <IconCancel {...props} />)`
	color: var(--color-status-danger);
	margin-left: 0.5rem;
`


const PositionIcon = ({ safe }) => safe === undefined ? null : safe === true ? <SafeIcon /> : <UnsafeIcon />

const PositionTag = ({ safe = false, isWarning = false, isExpired = false }) => {
	if (isExpired === true) {
		return <Tag.Expired>Expired</Tag.Expired>
	}
	if (isWarning === true) {
		return <Tag.Orange>Warning</Tag.Orange>
	}
	return safe===true ? <Tag.Success>Safe</Tag.Success> : <Tag.Danger>Unsafe</Tag.Danger>
}


const PositionExpired = () => <Tag.Expired>Expired</Tag.Expired>

const Position = ({ address, ...rest }) => {

	const { synthetics } = appController
	const [position, setPosition] = useState()
	const [emp, setEmp] = useState()

	useEffect(() => {
		const sub1 = synthetics.state.subscribe(`emps.${address}`, ({ myPosition }) => {
			setPosition({ ...myPosition })
		}, true)
		const sub2 = synthetics.state.subscribe(`emps.${address}`, setEmp, true)
		return () => {
			sub1.unsubscribe()
			sub2.unsubscribe()
		}
	}, []) // eslint-disable-line
 
	return <GateKeeper
		controllerAssertions={[
			{
				controller: 'account',
				key: 'status',
				assert: val => val === Status.READY,
			},
		]}
		assertions={[
			{
				assert: () => !!position,
				onFail: () => <Panel fadeIn><IconRefresh className='rotate' />Fetching position</Panel>,
			}
		]}
		dependencies={[position]}
	>
		<Panel fadeIn title={'Your Position'} {...rest}>
			<GateKeeper
				assertions={[
					{
						assert: () => +position?.collateral > 0,
						onFail: () => <>You have no current position</>
					}
				]}
				dependencies={[position?.collateral]}
			>
				<Grid cols={2}>
					<Stat title='Debt Amount'>
						<LazyBoi
							value={emp?.myPosition?.tokensOutstanding}
							suffix={emp?.token1?.symbol}
							dp={2}
							fromWei
						/>
					</Stat>
					<Stat title='Collateralization Ratio' truncate={false}>
						<LazyBoi
							value={position?.actualCurrentRatio * 100}
							dp={2}
							suffix={"%"}
						/>
					</Stat>
					<Stat title='Collateral Size'>
						<LazyBoi
							value={emp?.myPosition?.rawCollateral}
							suffix={emp?.token0?.symbol}
							fromWei
							dp={2}
						/>
					</Stat>
					<Stat title='Status'>
						{ position?.expired 
						?(
							<Asset.Position.Tag isExpired={true}/>
						)
						:(
							<Asset.Position.Tag safe={position?.safe}/>
						)
						}
					</Stat>
				</Grid>
			</GateKeeper>
			<Panel.Divider small light />
			<Stat title='Wallet Balance'>
				<Asset.Value.Synthetic address={address} />
			</Stat>

			{ position?.withdrawalRequestPassTimestamp !== "0" &&
			(
				<>
					<Panel.Divider small light />
					<Stat title='Ongoing withdrawals'>
						<Asset.Value.Withdraw 
							 requestTimestamp={position?.withdrawalRequestPassTimestamp}
							 requestAmount={position?.withdrawalRequestAmount}
							 address={address}
						/>
					</Stat>
				</>
			)
			}

			{/*
			<Asset.Value.Synthetic address={address}/>
			<GateKeeper
				assertions={[
					{
						assert: () => +position?.collateral > 0,
						onFail: () => null
					}
				]}
				dependencies={[position?.collateral]}
				>
				<Panel.Divider small light/>
				<Stat.Inline small title='Collateralization Ratio'>
					<LazyBoi.Percent total={1} value={position?.currentRatio} dp={2}/>
					<PositionIcon safe={position?.safe}/>
				</Stat.Inline>
			</GateKeeper>
			*/}
		</Panel>

	</GateKeeper>
}

Position.Icon = PositionIcon
Position.Tag = PositionTag
Position.Expired = PositionExpired

export default Position