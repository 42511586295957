import React, { useState, useEffect, useCallback, Fragment } from 'react';
import styled from 'styled-components'
import { Button } from '@components'
import { ReactComponent as Contract } from '@assets/Icon_Contract.svg';
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as Check } from '@assets/Icon_Check.svg';
import { ReactComponent as ErrorIcon } from '@assets/Icon_Error.svg';
import assetController from '@app/App.Controller'

const Statuses = {
	NOTAPPROVED: 'NOTAPPROVED',
	APPROVING: 'APPROVING',
	APPROVED: 'APPROVED' 
}

const ApprovalTrigger = ({name, toApprove, onContract, amount, onApproval}) => {

	const { tokens } = assetController

	const [ status, setStatus ] = useState(Statuses.NOTAPPROVED)
	const [ text, setText ] = useState(`${amount === 0 ? 'Unapprove' : 'Approve'} ${name}`)
	
	const handleApproval = async () => {
		setStatus(Statuses.APPROVING)
		setText(<Fragment><Refresh className='rotate'/>&nbsp;&nbsp;{amount === 0 ? 'Unapproving' : 'Approving'} {name}...</Fragment>)
		
		tokens
			.approve(toApprove, onContract, amount)
			.then(() => {
				setStatus(Statuses.APPROVED)
				setText(<Fragment><Check/>&nbsp;&nbsp;{amount === 0 ? 'Unapproved' : 'Approved'} {name}</Fragment>)
				onApproval()
			})
			.catch(msg => {
				setStatus(Statuses.NOTAPPROVED)
				setText(<Fragment><ErrorIcon/>&nbsp;&nbsp;{amount === 0 ? 'Unapprove' : 'Approve'} {name}</Fragment>)
			})
	}

	useEffect(() => {
		setText(`${amount === 0 ? 'Unapprove' : 'Approve'} ${name}`)
	}, [name, amount])

	return <Button 
		disabled={status !== Statuses.NOTAPPROVED} 
		onClick={handleApproval} 
		small
		>
		{text}
	</Button>
}

const useCounter = (initialCount = 0) => {
	const [count, setCount] = useState(initialCount);
	const increment = useCallback(() => setCount(c => c + 1), []);
	const decrement = useCallback(() => setCount(c => c - 1), []);
	return { count, increment, decrement };
}

const Banner = styled(
	({approvals=[], onUpdate=()=>{}, onApproved=()=>{}, className, ...rest}) => {
		const { tokens } = assetController

		const [ displayState, setDisplayState ] = useState('closed')
		const [ itemsForApproval, setItemsForApproval ] = useState()
		const [ title, setTitle ] = useState('Checking token approvals...')
		
		const { 
			count: approvedCount, 
			increment: approveOne 
		} = useCounter(0);
		
		// update approvals when tokens are updated
		useEffect(() => {
			if(approvals.length > 0) defineApprovals(approvals)
		}, [approvals.length])  // eslint-disable-line

		
		// trigger callbacks on approval count changes
		useEffect(() => {
			let timeout = setTimeout(() => {}, 1)

			if(itemsForApproval){
				onUpdate({total: itemsForApproval.length, approved: approvedCount})
				if(approvedCount >= itemsForApproval.length){
					onApproved()
					timeout = setTimeout(() => setDisplayState('closed'), 2000)
				}
			}

			return () => clearTimeout(timeout)
		}, [approvedCount, itemsForApproval]) // eslint-disable-line


		const defineApprovals = async _approvals => {
			onUpdate({total: Object.values(_approvals).length, approved: 0})

			let items = await Promise.all(
				_approvals.map(async approval => {
					let approved = await tokens.isApproved(approval.toApprove, approval.onContract)
					return !approved ? { ...approval } : null

					// hey dev, uncomment to remove approval
					//return { ...approval, amount: 0 }
				})
			)

			items = items.filter(e=>e)
			
			onUpdate({total: items.length, approved: approvedCount})

			if(items.length > 0){
				setDisplayState('awaiting')
				setItemsForApproval(items)
				setTitle('Token approval required to proceed')
			}else{
				onApproved()
				setTitle('No tokens to approve')
				//setTimeout(() => setDisplayState('closed'), 1000)
				//setDisplayState('closed')
			}
		}

		return <span 
			className={`asset-approval ${className}`}
			data-state={displayState}
			{...rest}
			>
			<span className='-title'>
				<Contract/>
				<span>{title}</span>
			</span>
			<span className='-buttons'>
				{itemsForApproval && itemsForApproval.map((item, i) => 
					<ApprovalTrigger 
						key={i} 
						{...item} 
						onApproval={approveOne}
					/>
				)}
			</span>
		</span>
	})`
	
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	max-height: 0;
	overflow: hidden;
	transition: all 0.5s;

	>span{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>.-title{
		color: var(--color-label);
		svg{
			margin-right: 0.4em;
			color: inherit;
		}
	}

	svg.check{
		color: var(--color-status-success, green)
	}

	&[data-state="pending"],
	&[data-state="awaiting"]{
		padding: 1.4rem 0;
		max-height: 5rem;
	}
	`

const ApprovalButton = ({total=-1, approved=-1, children, onClick, ...rest}) => {
	const [ text, setText ] = useState(`Checking approvals`)

	useEffect(() => {
		if(approved < total || approved < total){
			setText(`Awaiting approval${total > 1 ? 's' : ''} (${approved}/${total})`)
		}else{
			setText(children)
		}
	}, [total, approved]) // eslint-disable-line

	return <Button.Primary 
		disabled={approved < total}
		onClick={() => approved < total ? ()=>{} : onClick()}
		{...rest}
		>
		{text}
	</Button.Primary>
}

Banner.Button = ApprovalButton
Banner.Trigger = ApprovalTrigger

export default Banner