import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { truncateEthAddress } from '@util/helpers'
import assetController from '@app/App.Controller'
import { ReactComponent as IconOffsite } from '@assets/Icon_Offsite.svg';

const urlPart = (address, hash) => !!address ? `address/${address}` : `tx/${hash}`

export default styled(
	({text, address, hash, className}) => {

		const { network } = assetController
		const [ networkName, setNetworkName ] = useState('')

		useEffect(() => {
			network.state.subscribe('current', network => {
				if(network.name !== 'Mainnet'){
					setNetworkName(`${network.name.toLowerCase()}.`)
				}
			}, true)
		}, []) // eslint-disable-line

		return <a 
			href={`https://${networkName}etherscan.io/${urlPart(address, hash)}`} 
			target='_blank' 
			rel="noopener noreferrer nofollow" 
			className={`addresslink ${className}`}
			>
			{text || truncateEthAddress(address)}
			<IconOffsite/>
		</a>
	})`
	display: inline-flex;
	align-items: baseline;
	color: var(--color-label);
	opacity: 0.7;
	text-transform: uppercase;
	font-size: var(--font-size-small);

	>svg{
		margin-left: 1em;
		opacity: 0.5;
		font-size: 0.8em;
	}


	&:hover{
		opacity: 1;
		>svg{
			opacity: 0.7
		}
	}
	`