import React, { useState, useEffect } from 'react';
import { Button, Grid, Panel, Field } from '@components'
import { Network } from "@archetypes";
import { formatTimestamp } from '@util/helpers'
import assetController from '@app/App.Controller'

import { 
	CreateEMPController, 
} from './_controller'

export default () => {

	const { emp } = assetController

	// approval params
	const [ controller, setController ] = useState(new CreateEMPController(assetController))
	const [ empParams, setEmpParams ] = useState({})

	useEffect(() => {
		const empController = new CreateEMPController(assetController)
		empController.on('details', setEmpParams)
		setController(empController)
		empController.init()
		return () => empController.cleanup()
	}, []) // eslint-disable-line

	return <Panel>
		<Panel.Title>Create Expiring Multiparty</Panel.Title>
		<Panel.Subtitle>[TODO: Explanation about creating expiring multiparty..]</Panel.Subtitle>
		
		<Panel.Divider/>
		
		<Grid cols={2} width={[30,70]}>
			<span>
				<Panel.Title>Addresses</Panel.Title>
				<Panel.Subtitle>
					<Network.Tag showMainnet/><br/>
					You can find UMA <Network.Tag.Name/> addresses <a href={`https://raw.githubusercontent.com/UMAprotocol/protocol/master/packages/core/networks/${empParams?.networkId}.json`} target='_blank' rel="noreferrer noopener">here</a>.
				</Panel.Subtitle>
			</span>
			<span>
				<Field.Input.Address
					title='Collateral address'
					subtitle={'Your approved collateral address'}
					value={empParams?.collateralAddress}
					onChange={val => controller.setValue('collateralAddress', val)}
				/>
				{/*<Field.Input.Address
					title='UMA finder address'
					subtitle={'The UMA finder address'}
					value={empParams?.finderAddress}
					onChange={val => controller.setValue('finderAddress', val)}
				/>
				<Field.Input.Address
					title='UMA token factory address'
					subtitle={'The UMA token factory address'}
					value={empParams?.tokenFactoryAddress}
					onChange={val => controller.setValue('tokenFactoryAddress', val)}
				/>
				<Field.Input.Address
					title='UMA timer address'
					subtitle={'The UMA timer address'}
					value={empParams?.timerAddress}
					onChange={val => controller.setValue('timerAddress', val)}
				/>*/}
			</span>
		</Grid>
		
		<Panel.Divider/>
		
		<Grid cols={2} width={[30,70]}>
			<span>
				<Panel.Title>Asset Details</Panel.Title>
				<Panel.Subtitle>Details about the synthetic token to create</Panel.Subtitle>
			</span>
			<span>
				<Field.Input
					title='Price Identifier'
					subtitle={'The price feed identifier for the price feed, eg ETH/BTC'}
					placeholder={'ETH/BTC'}
					value={empParams?.priceFeedIdentifier}
					onChange={val => controller.setValue('priceFeedIdentifier', val)}
				/>
				<Field.Input.Number
					title='Expiration timestamp'
					subtitle={`The future expiration timestamp for the contract (${formatTimestamp(empParams?.expirationTimestamp, 'DD MMM YYYY, HH:mm:ss')})`}
					//placeholder={moment().add(1, 'week').unix()}
					value={empParams?.expirationTimestamp}
					//min={moment().unix()}
					onChange={val => controller.setValue('expirationTimestamp', val)}
				/>
				<Field.Input
					title='Synthetic name'
					subtitle={'The name for the asset, including plain language expiry date, eg UMA Synthetic Gold April 2021.'}
					placeholder={'UMA Synthetic Gold April 2021'}
					value={empParams?.syntheticName}
					onChange={val => controller.setValue('syntheticName', val)}
				/>
				<Field.Input
					title='Synthetic symbol'
					subtitle={'The symbol for the asset, including expiry date, eg UMAGold_Apr2021.'}
					placeholder={'UMAGold_Apr2021'}
					value={empParams?.syntheticSymbol}
					onChange={val => controller.setValue('syntheticSymbol', val)}
				/>
				<Field.Input.Address
					title='Token Beneficiary address'
					subtitle={'Your excessTokenBeneficiary address (as required by UMIP-14)'}
					value={empParams?.excessTokenBeneficiary}
					onChange={val => controller.setValue('excessTokenBeneficiary', val)}
				/>
			</span>
		</Grid>

		<Panel.Divider/>
		
		<Grid cols={2} width={[30,70]}>
			<span>
				<Panel.Title>Liveness Details</Panel.Title>
			</span>
			<Grid cols={2}>
				<Field.Input.Number
					title='Withdrawal Liveness'
					subtitle={'In seconds (eg: 2 hours = 7200)'}
					placeholder={'7200'}
					value={empParams?.withdrawalLiveness}
					min={0}
					onChange={val => controller.setValue('withdrawalLiveness', val)}
				/>
				<Field.Input.Number
					title='Liquidation Liveness'
					subtitle={'In seconds (eg: 2 hours = 7200)'}
					placeholder={'7200'}
					value={empParams?.liquidationLiveness}
					min={0}
					onChange={val => controller.setValue('liquidationLiveness', val)}
				/>
			</Grid>
		</Grid>

		<Panel.Divider/>
		
		<Grid cols={2} width={[30,70]}>
			<span>
				<Panel.Title>Collateral Details</Panel.Title>
			</span>
			<Grid cols={2}>
				<Field.Input.Number
					title='Min sponsor tokens'
					subtitle={'The minimum synthetic tokens to mint, eg 1000.'}
					placeholder={'1000'}
					value={empParams?.minSponsorTokens}
					min={0}
					onChange={val => controller.setValue('minSponsorTokens', val)}
				/>
				<Field.Input.Number
					title='Collateral Requirement'
					info={empParams?.collateralRequirement}
					subtitle={'The collateral to provide (GCR * minSponsorTokens), eg 38.'}
					placeholder={'38'}
					value={empParams?.collateralRequirement}
					min={0}
					onChange={val => controller.setValue('collateralRequirement', val)}
				/>
			</Grid>
		</Grid>

		<Panel.Divider/>
		
		<Grid cols={2} width={[30,70]}>
			<span>
				<Panel.Title>Dispute Details</Panel.Title>
			</span>
			<Grid cols={3}>
				<Field.Input.Number
					title='Dispute bond PCT'
					subtitle={'The disputeBondPct - eg 3 = 3%.'}
					placeholder={'3'}
					min={0}
					max={100}
					value={empParams?.disputeBondPct}
					onChange={val => controller.setValue('disputeBondPct', val)}
				/>
				<Field.Input.Number
					title='Sponsor Dispute Reward Pct'
					subtitle={'The sponsorDisputeRewardPct - eg 5 = 5%.'}
					placeholder={'5'}
					min={0}
					max={100}
					value={empParams?.sponsorDisputeRewardPct}
					onChange={val => controller.setValue('sponsorDisputeRewardPct', val)}
				/>
				<Field.Input.Number
					title='Disputer Dispute Reward Pct'
					subtitle={'The disputerDisputeRewardPct - eg 5 = 5%.'}
					placeholder={'5'}
					min={0}
					max={100}
					value={empParams?.disputerDisputeRewardPct}
					onChange={val => controller.setValue('disputerDisputeRewardPct', val)}
				/>
			</Grid>
		</Grid>

		<Panel.Divider/>

		<Panel.Footer>
			<Panel.Footer.Group/>
			<Panel.Footer.Group>
				<Button.Primary
					disabled={Object.values(empParams?.tx||{}).includes(null)}
					onClick={() => emp.create(empParams?.tx)}
					>
					Deploy
				</Button.Primary>
			</Panel.Footer.Group>
		</Panel.Footer>
	</Panel>
}