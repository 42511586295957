import Web3 from 'web3';
import BigNumber from 'bignumber.js'
import moment from 'moment'

export const web3 = new Web3()

export const numberToMaxDp = (value, dp=2) => +parseFloat(value).toFixed( dp||2 )

export const truncateEthAddress = (addr, start=6, end=4) => addr ? `${addr.substring(0, start)}...${addr.substring(addr.length - end)}` : null

export const formatCurrency = value => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(+value||0)

export const formatCommas = (value, dp=2) => new Intl.NumberFormat().format((+value||0).toFixed(dp))

export const formatPercent = n => n <= 0 ? '0%' : n < 0.01 ? '<0.01%' : `${n.toFixed(2)}%`

export const formatTimestamp = (ts, format='DD MMM YYYY') => ts ? moment.unix(ts).format(format) : null

export const strToJson = str => {
    try {return JSON.parse(str)} 
    catch (e) { return str }
}

export const fromWei = (amount=0, unit='ether') => web3.utils.fromWei(amount.toString(), unit)

export const toWei = (amount=0, unit='ether') => {
	return !!amount
		? web3.utils.toWei(amount.toString(), unit)
		: amount

	//web3.utils.toBN(web3.utils.toWei(amounts[1].toString()))
	
	//var num = new BigNumber(amount)
	//var final = num.multipliedBy(10**18)
	//return final
}


export const parseBN = nbr => typeof nbr === 'string' ? fromWei(web3.utils.toBN(nbr)) : nbr

export const isObject = v => Object.prototype.toString.call(v) === '[object Object]'

export const isArray = v => Object.prototype.toString.call(v) === '[object Array]'

export const BN = n => new BigNumber(n)

export const formatBN = (n, dp=0) => BigNumber.isBigNumber(n) ? n.toFixed(dp) : n

export const stringToHex = (string='') => {
	if(!string) return null

	let hex, i;
	let result = "";
	for (i=0; i<string.length; i++) {
		hex = string.charCodeAt(i).toString(16);
		result += hex
	}
	return result
}

export const oneWei = '1000000000000000000'
export const maxWei = '1000000000000000000000000000'

export const roundDown = (number, decimals) => {
    decimals = decimals || 0;
    return ( Math.floor( number * Math.pow(10, decimals) ) / Math.pow(10, decimals) );
}

export const getRate = (baseToken, pairToken) => {
	let rate  = Number(baseToken.price) * (Number(fromWei(pairToken.spotPrice)))
	if (pairToken.symbol.indexOf("pxUSD") !== -1) {
		rate = 1
	}
	return rate
}