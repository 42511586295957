import React, { useState, useEffect, Fragment } from 'react';
import { Button, Table, Panel, GateKeeper, LazyBoi } from '@components'
import { fromWei } from '@util/helpers'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as IconLocked } from '@assets/Icon_Locked.svg';
import { ReactComponent as IconUnlocked } from '@assets/Icon_Unlocked.svg';
import assetController, { Status } from '@app/App.Controller'

const columns = [
	{
		Header: 'LP Token',
		accessor: 'name',
	},
	{
		Header: 'Balance (LPT)',
		accessor: 'available',
	},
	{
		Header: 'Locked (LPT)',
		accessor: 'locked',
	},
	// {
	// 	Header: 'Expiry',
	// 	accessor: 'expiry',
	// },
	{
		Header: '',
		accessor: 'actions',
	}
]

const TableRow = ({address, name, rewards}) => {
	const { perlinx } =assetController

	return <Table.Uncontrolled.Row 
		fields={{
			name: name,
			available: <LazyBoi.Wei value={rewards?.available} dp={4}/>,
			locked: <LazyBoi.Wei value={rewards?.locked} dp={4}/>,
			actions: [
				<Button 
					// disabled={fromWei(rewards?.available) <= 0.01} 
					disabled={true}
					key={1} 
					small 
					icon={<IconLocked/>} 
					to={`/rewards/lock/${address}`}
					>
					Lock
				</Button>,
				<Button 
					disabled={fromWei(rewards?.locked) <= 0} 
					key={2} 
					small 
					icon={<IconUnlocked/>} 
					onClick={() => perlinx.unlock(address) }
					>
					Unlock
				</Button>
			],
		}}
	/>
}

export default () => {
	const { perlinx } = assetController
	const [ pools, setPools ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe(`pools`, pools => setPools({...pools}), true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to view your LP Tokens',
				},
				{
					controller: 'perlinx',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Fragment><IconRefresh className='rotate'/> Fetching Rewards Pools</Fragment>,
				}
			]}
			assertions={[
				{
					assert: () => !!pools,
					onFail: () => <Fragment><IconRefresh className='rotate'/>Fetching pools</Fragment>,
				}
			]}
			dependencies={[pools]}
			>
			<Table.Uncontrolled columns={columns}>
				{pools && Object.values(pools).map(pool => 
					<TableRow 
						key={pool.address} 
						{...pool}
					/>
				)}
			</Table.Uncontrolled>
		</GateKeeper>
	</Panel>
}