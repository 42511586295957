import React from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as ArrowLeft } from '@assets/Icon_Arrow_Left.svg';

const BackButton = styled(
	({to, className}) => {
		let history = useHistory()
		return <ArrowLeft 
			className={className} 
			onClick={() => {
				typeof to === 'string'
					? history.push(to)
					: history.goBack()
			}}/>
	})`
	cursor: pointer;
	`

const Layout = styled(
	({title, back, children, className, ...rest}) => {
		return <section className={`layout ${className}`} {...rest}>
			{(title || !!back) && 
				<header className='-header'>
					{!!back && <BackButton className={'-back'} to={back}/>}
					<h1 className='-title'>{title}</h1>
				</header>
			}	
			<div className="-content">
				{children}
			</div>
		</section>
	})`
	display: block;
	color: inherit;
	width: 100%;

	>.-header{
		padding: 0 1.45vw;
		display: flex;
		align-items: center;
		margin: 0 0 2.8rem 0;

		.-title{
			font-size: var(--layout--header--font-size, 20px);
			margin: 0;
		}

		.-back{
			margin-right: 1rem;
			cursor: pointer;
			width: var(--font-size-xlarge);
			height: var(--font-size-xlarge);

			path{
				transition: fill 0.2s
			}

			&:hover path{
				fill: var(--layout--header--back--color, grey)
			}
		}
	}

	>.-content{
		display: flex;
		flex-direction: ${({reversed=false}) => reversed ? 'row-reverse' : 'row' };
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;

		>.layout-main,
		>.layout-aside{
			justify-content: space-between;
			align-items: center;
			//width: 100%;
			//margin: 0;
			padding: 0 1.45vw ;
		}

		>.layout-main{
			width: ${({children}) => React.Children.count(children) === 1 ? '100%' : '65.8%'};
		}

		>.layout-aside{
			width: ${({children}) => React.Children.count(children) === 1 ? '100%' : '34.2%'};
		}
	}

	
	`

Layout.Main = styled(
	({children, className}) => <article className={`layout-main ${className}`}>{children}</article>
	)`
	color: inherit;
	`

Layout.Aside = styled(
	({children, className, ...rest}) => <aside className={`layout-aside ${className}`} {...rest}>{children}</aside>
	)`
	display: flex;
	flex-wrap: wrap;
	color: inherit;
	`

Layout.Section = styled(
	({title, children, className}) => <div className={`layout-section ${className}`}>
		{title && <h2 className='-title'>{title}</h2>}
		{children}
	</div>
	)`
	display: block;
	width: 100%;
	color: inherit;

	>.-title{
		margin: 0 0 2.8rem 0;
		font-size: var(--layout--header--font-size, 20px);

	}

	& + .layout-section{
		margin-top: 4rem;
	}
	`

export default Layout