import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components'

const Content = styled(
	({title, subtitle, children, className}) => {

		const [open, setOpen] = useState(false)

		useEffect(() => {
			setTimeout(() => setOpen(true), 50)
		}, [])
		
		return <span className={`-content ${className}`} data-open={open}>
			<div className="-inner">
				{children}
			</div>
		</span>
	})`
	position: absolute;
	line-height: 1em;	
	transition: all 0.15s ease-in-out;

	.-inner{
		//padding: 1.6rem 2.4rem;
		overflow: hidden;
		max-height: 100rem;
		transition: inherit;
	}

	&[data-open='false']{
		opacity: 0;
		.-inner{
			max-height: 0;
			padding: 0 1em;
		}
	}
	
	${({position}) => {
		switch (position) {
			case 'top':
				return `
					bottom: calc(100% + 0.3em);
					left: 50%;
					transform: translateX(-50%);
				`
			case 'topleft':
				return `
					bottom: calc(100% + 0.3em);
					right: 0;
				`
			case 'topright':
				return `
					bottom: calc(100% + 0.3em);
					left: 0;
				`
			case 'bottom':
				return `
					top: calc(100% + 0.3em);
					left: 50%;
					transform: translateX(-50%);
				`
			case 'bottomleft':
				return `
					top: calc(100% + 0.3em);
					right: 0;
				`
			case 'bottomright':
				return `
					top: calc(100% + 0.3em);
					left: 0;
				`
			default: break;
		}
	}}


	`

const Popover = styled(
	({trigger, title, subtitle, position='top', children, className}) => {
		
		const [ open, setOpen ] = useState(false)
		const [ timer, setTimer ] = useState(setTimeout(()=>{}, 1))

		const handleOpen = () => {
			clearTimeout(timer)
			setOpen(true)
		}

		const handleClose = () => setTimer(setTimeout(() => setOpen(false), 250))

		return <span 
			className={`popover ${className}`}
			onMouseLeave={handleClose}
			onMouseEnter={handleOpen}
			>
			{open && 
				<Content title={title} subtitle={subtitle} position={position}>
					{children}
				</Content>
			}
			<span className="-trigger" >
				{trigger}
			</span>
		</span>
	})`
	position: relative;
	user-select: none;

	.-trigger{
		cursor: pointer;
		transition: all 0.15s;
		white-space: nowrap;
		display: block;

		>*{
			display: block;
		}
		
		&:hover:after{
			opacity: 1;
		}
	}
	
	.-content{
		box-shadow: 0 0 1rem rgba(0,0,0,0.1);
		background: var(--popover--background-color, white);
		color: var(--popover--color, black);
		border: 1px solid var(--popover--border-color, transparent);
		border-radius: var(--popover--border-radius, 0);
	}
	`

Popover.Item = styled(
	({children, className, ...rest}) => 
		<div 
			className={`popover-item ${className}`}
			{...rest}
			>
			{children}
		</div>
	)`
	font-size: var(--popover--font-size, 14px);
	display: block;
	cursor: pointer;
	text-align: left;
	white-space: nowrap;
	//padding: var(--popover--padding-y, 2rem) var(--popover--padding, 2.1rem);
	border-bottom: 1px solid var(--popover--border-color, transparent);
	min-width: 15.5rem;
	color: var(--popover-item--color, black);

	padding: var(--popover--padding-y, 2rem) var(--popover--padding, 2.4rem);

	>*{
		display: block;
	}

	//&:first-child{ padding-top: 0.4rem}
	&:last-child{ 
		//padding-bottom: 0.4rem;
		border: none;
	}

	>*:hover{
		color: var(--popover-item--color--hover, grey)
	}

	`

Popover.Section = styled(
	({title, maxHeight, children, className, ...rest}) => <div className={`popover-section ${className}`} {...rest}>
		<Popover.Title>{title}</Popover.Title>
		<div className="-inner">
			<div className="children">{children}</div>
		</div>
		<Popover.Divider full/>
	</div>
	)`
	
	display: block;
	
	>.-inner{
		margin: var(--popover--padding-y, 2rem) 0;
		padding: 0 var(--popover--padding, 2.4rem);

		${({maxHeight}) => !!maxHeight && `
			position: relative;
			margin: 0;

			>.children{
				max-height: ${maxHeight};
				overflow: hidden;
				overflow-y: scroll;
				position: relative;
				padding: var(--popover--padding-y, 2rem) 0 ;
				//margin-bottom: var(--popover--padding-y, 2rem)
			}

			&:before,
			&:after{
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: calc(var(--popover--padding-y, 2rem) * 1.2);
				z-index: 1;
				pointer-events: none;
			}

			&:before{
				top: 0;
				background: linear-gradient(to bottom, var(--popover--background-color, white) 10%, transparent 100%);
			}

			&:after{
				bottom: 0;
				background: linear-gradient(to top, var(--popover--background-color, white) 10%, transparent 100%);
			}
		`}
	}

	`

Popover.Divider = styled(
	({children, className, full, ...rest}) => <hr className={`popover-divider ${className}`} {...rest}></hr>
	)`
	
	display: block;
	margin: 0 var(--popover--padding, 2.4rem);
	padding: 0;
	
	${({full}) => full !== undefined && `
		margin-left: 0;
		margin-right: 0;
	`}
	`

Popover.Title = styled(
	({children, className, ...rest}) => <Fragment>
		<div className={`popover-title ${className}`} {...rest}>{children}</div>
		<Popover.Divider full/>
	</Fragment>
	)`
	display: block;
	font-size: var(--popover--title--font-size, 12px);
	color: var(--popover--title--color, white);
	margin: calc(var(--popover--padding-y, 2rem) / 2) 0;
	padding: 0 var(--popover--padding, 2.4rem);

	`

export default Popover