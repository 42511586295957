import { toWei } from '@util/helpers'
import moment from 'moment'
import SubsAndEventsClass from '@util/SubsAndEventsClass.js'

export class DisputeController extends SubsAndEventsClass{
	_ADDRESS
	_SPONSOR
	_SPONSOR_POSITION
	_SYNTHETICS
	_EMP
	_MIN_INPUT_AMOUNT = 0

	INPUT_AMOUNT = 1
	TX = {
		address: null,
		collateralAmount: 0,
		numTokens: 0
	}
	
	constructor(address, sponsor, {synthetics}){
		super()
		this._ADDRESS = address
		this._SPONSOR = sponsor
		this._SYNTHETICS = synthetics
	}

	init(){
		const empSub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._calculateApprovals()

			const posSub = this._SYNTHETICS.getSponsorPosition(this._ADDRESS, this._SPONSOR, position => {
				this._SPONSOR_POSITION = position
				this._TOTAL_TOKENS = this._SPONSOR_POSITION?.tokensOutstanding
				this._TOTAL_COLLATERAL = this._SPONSOR_POSITION?.collateral
				this._update()
				
			})
			this._SUBSCRIPTIONS.push(posSub)
		}, true)
		
		this._SUBSCRIPTIONS.push(empSub)
	}

	updateAmount(amount){
		this.INPUT_AMOUNT = !amount || isNaN(amount) ? 0 : amount
		this._update()
	}

	// private
	_update(){
		if(!this._ADDRESS || !this.INPUT_AMOUNT || !this._EMP) return
		
		const collateralAmount = this._TOTAL_COLLATERAL / 100 * (100 / this._TOTAL_TOKENS * this.INPUT_AMOUNT)

		this.TX = this._formatTx(
			this._ADDRESS, 
			this._SPONSOR, 
			this._EMP.minCollateral, 
			this._EMP.collateralRequirement,
			this.INPUT_AMOUNT,
			this._EMP.expirationTimestamp,
		)

		// update
		this.dispatchEvent('params', {	
			symbol: this._EMP?.token1.symbol,
			amount: this.INPUT_AMOUNT,
			min: this._MIN_INPUT_AMOUNT,
			max: this._TOTAL_TOKENS,
			collateralAmount: collateralAmount,
			collateralSymbol: this._EMP?.token0.symbol,
			tx: this.TX
		});
	}

	_formatTx(luveliness){
		return {
			address: this._ADDRESS,
			sponsor: this._SPONSOR,
			minCollateralPerToken: this._EMP.minCollateral,
			maxCollateralPerToken: this._EMP.collateralRequirement,
			maxTokensToLiquidate: toWei(this.INPUT_AMOUNT),
			deadline: moment().add(this._EMP?.liquidationLiveness, 'seconds').add(60, 'seconds').unix()
		}
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._EMP?.token0?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._EMP?.token0?.address
			},
			{
				name: this._EMP?.token1?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._EMP?.token1?.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}
}
