import { createGlobalStyle } from 'styled-components'

// vars
export const Vars = createGlobalStyle`
	:root {
		// colours
		--color-primary: #19DED1;
		--color-white: #FFFFFF;
		--color-label: #BCD7F6;
		--color-error: #FF7A65;
		--color-card-background: #1D3046;
		--color-page-background: #162536;
		--color-secondary-background: #132A3E;
		--color-tint: rgba(255,255,255,0.1);
		--color-card-hover: #2F4966;

		// shades
		--color-light: var(--color-white);
		--color-dark: var(--color-page-background);

		//status
		--color-status-success: #16514C;
		--color-status-danger: #FF7A65;
		--color-status-warning: rgb(255,251,101);
		--color-status-neutral: #5CD8FF;

		
		// font sizes
		--font-size-xlarge: 2.4rem;	
		--font-size-large: 2rem;
		--font-size-medium: 1.6rem;
		--font-size-normal: 1.4rem;	
		--font-size-small: 1.2rem;
	}
`

// base/reset 
export const Base = createGlobalStyle`	
	*{
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		color: inherit;
	}

	html,body {
		padding: 0;
		margin: 0;
    min-width: 1080px;
		scroll-behavior: smooth;
	}

	h1,h2,h3,h4,h5,p,a,button,blockquote,input,select{
		
		
	}

	strong{
		font-weight: 600
	}

	a{
		text-decoration: none;
		transition: all 0.15s
	}
	
	hr{
		opacity: 0.15;
		height: 0;
		border: none;
		border-bottom: 1px solid currentColor;
	}

	*:focus{
		outline: none;
	}

	svg{
		width: 1em;
		height: 1em;
		fill: currentColor;
	}

	input[type=number]::-webkit-inner-spin-button {}
`

// layout
export const Layout = createGlobalStyle`	
	#root{
		position: relative;
		min-height: 100vh; 
		//background: var(--color-dark);
		//color: var(--color-ultralight);
		
		>.header{
			width: 100%;
			z-index: 9999;
			position: relative;

			.inner{
				margin: 0 auto;
			}
		}

		.global-notifications{
			z-index: 9998;
			top: 7rem;
		}

		.field + .amount{
			margin-top: 2.7rem;
		}
	}
`

// theme
export const Theme = createGlobalStyle`	
	:root{
		// THEMING //

		// global
		--global--border-radius: 0.8rem;


		// layout
		--layout--header--font-size: var(--font-size-large);
		--layout--header--back--color: var(--color-primary);

		// asset
		--asset-value--title--font-size: var(--font-size-medium);
		--asset-value--text--font-size: var(--font-size-normal);
		--asset-value--text--color: var(--color-label);

		// button
		--button--border--color: var(--color-white);
		--button--text--color: var(--color-white);
		--button--background--color: transparent;
		--button-primary--border--color: transparent;
		--button-primary--text--color: var(--color-page-background);
		--button-primary--background--color: var(--color-primary);
		--button-primary--background--color--hover: #78FFDB;

		--button-danger--border--color: rgba(255,122,101,0.3);
		--button-danger--text--color: var(--color-error);
		--button-danger--background--color: transparent;
		--button-danger--background--color--hover: transparent;
		--button-danger--border--color--hover: var(--color-error);

		// status wrapper
		--statuswrapper--message--color: var(--color-label);
		--statuswrapper--message--font-size: var(--font-size-medium);
		--statuswrapper-icon-size: var(--font-size-medium);

		// panel
		--panel--padding: 2.4rem;
		--panel--border-radius: 0.7rem;
		--panel--title--font-size: var(--font-size-large);
		--panel--title--color: var(--color-white);
		--panel--subtitle--font-size: var(--font-size-normal);
		--panel--subtitle--color: var(--color-label);
		--panel--background--color: var(--color-card-background);
		--panel-footer--border--color: var(--color-page-background);
		--panel-button--background--color-hover: var(--color-card-hover);
		--panel-button--font-size: var(--font-size-medium);

		// fields
		--field--font-size: var(--font-size-normal);
		--field--color: var(--color-white);
		--field--info--color: var(--color-label);
		--field--background-color: var(--color-tint);
		--field--header--font-size: var(--font-size-small);
		--field--footer--font-size: var(--font-size-small);
		--field-input--font-size: var(--font-size-large);
		--field-select--font-size: var(--font-size-normal);
		--field--subtitle--font-size: var(--font-size-small);
		--field--subtitle--color: var(--color-white);

		// tags
		--tag--font-size: var(--font-size-small);
		--tag--border-radius: 0.4rem;
		--tag--success--background-color: rgba(29,186,235,0.2);
		--tag--success--color: var(--color-status-neutral);
		--tag--warning--background-color: #343F31;
		--tag--warning--color: var(--color-white);
		--tag--danger--background-color: rgba(255,130,130,0.2);
		--tag--danger--color: var(--color-status-danger);
		--tag--neutral--background-color: lightgrey;
		--tag--neutral--color: var(--color-status-neutral);

		// table
		//---TODO
		// table pagination
		--table--pagination--font-size: var(--font-size-normal);
		--table--pagination--color: rgba(188, 215, 246, 0.6);
		--table--pagination--color-active: var(--color-primary);
		--table--pagination--icon-size: var(--font-size-large);

		// popover
		--popover--background-color: var(--color-card-background);
		--popover--color: var(--color-white);
		--popover--border-color: var(--color-tint); 
		--popover--border-radius: var(--global--border-radius);
		--popover--font-size: var(--font-size-normal);
		--popover-item--color: var(--color-white);
		--popover-item--color--hover: var(--color-primary);
		--popover--title--font-size: var(--font-size-small);
		--popover--title--color: var(--color-label);
		--popover--padding: 2.1rem;
		--popover--padding-y: 2rem ;
		


		// notification
		--notification--title--font-size: var(--font-size-normal);
		--notification--text--font-size: var(--font-size-normal);
		--notification--border-radius: 2.4rem;
		--notification--success--color: var(--color-white);
		--notification--success--background-color: #143C3E;
		--notification--success--border-color: #16514C;
		--notification--warning--color: var(--color-white);
		--notification--warning--background-color: #343F31;
		--notification--warning--border-color: #697039;
		--notification--error--color: var(--color-white);
		--notification--error--background-color: #342730;
		--notification--error--border-color: #4C2F34;
		--notification--default--color: var(--color-white);
		--notification--default--background-color: #2A3848;
		--notification--default--border-color: #3A4B60;

		// grid
		--grid--spacing: 1.2rem;
	}

	html{
		font-size: 10px;
	}

	body{
		color: var(--color-white);
		background: var(--color-page-background);
	}

	body, input, textarea, button, select {
		font-family: 'Cairo', sans-serif;
		font-size: var(--font-size-normal);
		line-height: 1.5em;
		font-weight: 400;
	}

	h1,h2,h3,h4,h5{
		font-weight: 400;
	}

	h1{
		font-size: var(--font-size-xlarge);
		font-weight: 600;
	}

	h2{
		font-size: var(--font-size-large);
		font-weight: 600;
	}
	
	.global-header{
		background: var(--color-card-background);
		font-size: var(--font-size-medium);
	}

	.table{
		thead{
			th{
				font-size: var(--font-size-small);
				color: var(--color-label)
			}
		}
	}


	#root{
		>.content{
			display: block;
			max-width: 124rem;
			margin: 0 auto;
			padding: 3.4em 0;
		}

		
	}
	
	@keyframes rotate {
	    from {transform:rotate(0deg);}
	    to {transform:rotate(360deg);}
	}

	svg.rotate{
		transform-origin: 50% 50%;
		animation-name: rotate;
		animation-duration: 1500ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	svg{
		&[data-theme='primary'] path{
			fill: var(--color-primary)
		}
	}
`
