import React, { Fragment, useState, useEffect } from 'react';
import { Panel, Grid, Stat, GateKeeper, LazyBoi } from '@components'
import assetController from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';

export default () => {
	const { perlinx } = assetController
	const [ data, setData ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe(`overview`, setData, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel fadeIn>
		<GateKeeper
			assertions={[
				{
					assert: () => !!data,
					onFail: () => <Fragment><Refresh className='rotate'/></Fragment>,
				}
			]}
			dependencies={[data]}
			>
			<Panel.Title>Overview</Panel.Title>
			<Grid cols={2}>
				<Stat title='Pools'>
					<LazyBoi value={data?.count}/>
				</Stat>
				<Stat title='Volume'>
					<LazyBoi.Currency value={data?.volume}/>
				</Stat>
				<Stat title='Users'>
					<LazyBoi value={data?.users}/>
				</Stat>
				<Stat title='Depth'>
					<LazyBoi.Currency value={data?.depth} fromWei/>
				</Stat>
			</Grid>
		</GateKeeper>
	</Panel>
}