import React from 'react';
import { useParams, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Layout, Tabbed } from '@components'
import { Reward } from '@archetypes'

const Home = ({className}) => 
	<Layout 
		title='Rewards'
		className={className}
		>
		<Layout.Main>
			<Tabbed>
				<Tabbed.Child title={`Lock / Unlock`}>
					<Reward.Tokens/>
				</Tabbed.Child>
				<Tabbed.Child title={`Claim Rewards`}>
					<Reward.Claim/>
				</Tabbed.Child>
			</Tabbed>
		</Layout.Main>
		<Layout.Aside style={{marginTop: '5.6rem'}}>
			<Reward.LiquidityIncentives/>	
		</Layout.Aside>
	</Layout>


const Lock = ({className}) => {
	let { address } = useParams();
	return <Layout title='Lock your share' back>
		<Layout.Aside>
			<Reward.LockInfo address={address}/>
		</Layout.Aside>
		<Layout.Main>
			<Reward.Lock address={address}/>
		</Layout.Main>
	</Layout>
}

export default () => {
	let { path } = useRouteMatch();
	return <Switch>
		<Route exact path={path} component={Home}/>
		<Route exact path={`${path}/lock/:address`} component={Lock}/>
		{/*<Route exact path={`${path}/unlock/:address`} component={Unlock}/>*/}
		<Redirect to={path}/>
	</Switch>
}