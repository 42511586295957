import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { Button, Layout, Panel, LazyBoi } from "@components";
import { ReactComponent as IconError } from '@assets/Icon_Error.svg';

const UplockLP = styled(
    ({className}) => {
        return (
            <Layout className={`upgrade -banner ${className}`}>
			<Layout.Main>
				<Panel>
					<span className='-title'>
						<IconError/>
						<span>Please UNLOCK your LP tokens (BPT) ASAP to participate in the upgraded rewards scheme.</span>
					</span>
					<span className='-buttons'>
                        <a href="https://medium.com/perlin-network/important-update-to-the-perlinx-rewards-mechanism-42ec77f5a3ee" target="_blank">
                            <Button
                                // onClick={() => wallet.upgradeFromLegacyPerl()} 
                                small
							>
							    Details...
						    </Button>
                        </a>
						
					</span>
				</Panel>
			</Layout.Main>
		</Layout>
        )
    }
)`
    margin-bottom: 3em;
    overflow: hidden;
    transition: all 0.5s;
    max-height: 10rem;

    .panel{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #2F4966;

        >span{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        >.-title{
            color: var(--color-label);
            >svg{
                margin-right: 0.4em;
                color: inherit;
                width: var(--font-size-xlarge);
                height: var(--font-size-xlarge); 
            }
        }

        &[data-state="pending"],
        &[data-state="awaiting"]{
            //padding: 1.4rem 0;
            //max-height: 5rem;
        }
    }

    &[data-visible="false"]{
        padding: 0;
        max-height: 0;
        margin: 0;
    }
`

export default UplockLP