import React from 'react';
import styled from 'styled-components'
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@components";
import { Account, Admin, Network } from "@archetypes";
import { ReactComponent as Logo } from '@assets/PerlinX-Logo.svg';
import { ReactComponent as IconOverflow } from '@assets/Icon_Overflow.svg';
import { ReactComponent as IconExternal } from '@assets/Icon_External.svg';

export default styled(
	({className}) => <header className={`header ${className}`}>
		<div className="inner">
			<div className='nav'>
				<NavLink to='/dashboard'><Logo className='logo'/></NavLink>
				<NavLink exact to='/dashboard'>Dashboard</NavLink>
				<NavLink to='/pools'>Pools</NavLink>
				<NavLink to='/assets'>Assets</NavLink>
				<NavLink to='/rewards'>Rewards</NavLink>
				{/*
				<NavLink to='/liquidations'>Liquidations</NavLink>
				*/}
				
			</div>
			<div className='extra'>
				<Network.Tag prefix='Network:'/>
				<Account.Button/>
				<Popover
					className={'-menu'}
					trigger={<Button.Icon icon={<IconOverflow/>}/>}
					position={'bottomleft'}
				>	
					<Admin.Link wrapper={<Popover.Item/>}/>
					<Popover.Item><a href='https://perlinx.finance/' target='_blank' rel="noreferrer noopener">About <IconExternal/></a></Popover.Item>
					<Popover.Item><a href='https://perlinx.finance/#faq' target='_blank' rel="noreferrer noopener">FAQ <IconExternal/></a></Popover.Item>
					<Popover.Item><a href='https://discord.com/invite/j2gS9fv' target='_blank' rel="noreferrer noopener">Discord <IconExternal/></a></Popover.Item>
				</Popover>
			</div>
		</div>
	</header>
	)`
	display: block;	
	padding: 0.65em 2.5em;

	.inner{
		display: flex;	
		justify-content: space-between;
		align-items: center;
		position: relative;

		>.nav,
		>.extra{
			display: flex;	
			justify-content: space-between;
			align-items: center;
			margin: 0;
		}

		>.nav{
			.logo{
				width: 9.7rem;
				height: 2.4rem;
				margin: 0;
				display: block;
			}

			a{
				margin: 0 3.3rem;
				text-shadow: 0 0 0 rgba(255,255,255,0);
				
				&:hover{
					text-shadow: 0 0 2em rgba(255,255,255,0.4);
				}

				&.active{
					color: var(--color-primary);
				}

				&:first-child{
					margin-left: 0;
					margin-right: 5.1rem
				}
			}
		}

		>.extra{
			>*{
				margin-left: 1.6rem;
			}

			.-menu{
				a[target="_blank"]{
					display: flex;
					align-items: center;
					justify-content: space-between;
					
					svg{
						margin-left: 1rem;
						width: 0.7em;
						height: 0.7em;
						opacity: 0.4;
						
					}
				}
			}
		}
	}

	.wallet-button[data-status="INITIALISED"]:hover{
		padding-left: 1.6em;
		padding-right: 1.6em;
	}
	
	`