import React from 'react';
import { useParams, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Layout, Tabbed } from '@components'
import { Pool } from '@archetypes'

const Liquidity = ({className}) => <Layout title='Liquidity Pools'>
	<Layout.Main>
		<Pool.Liquidity/>
		<Pool.Disclaimer/>
	</Layout.Main>
	<Layout.Aside>
		<Pool.LiquidityOverview/>	
	</Layout.Aside>
</Layout>

const Stake = ({className}) => {
	let { address } = useParams();
	return <Layout title='Manage Liquidity' back='/pools'>
		<Layout.Aside>
			<Pool.StakeInfo address={address}/>
		</Layout.Aside>
		<Layout.Main>
			<Tabbed>
				<Tabbed.Child title={`Stake in Pool`}>
					<Pool.Stake address={address}/>
				</Tabbed.Child>
				<Tabbed.Child title={`Withdraw from Pool`}>
					<Pool.Withdraw address={address}/>
				</Tabbed.Child>
			</Tabbed>
		</Layout.Main>
	</Layout>
}

const Swap = ({className}) => {
	let { address } = useParams();
	return <Layout title='Swap Tokens' back>
		<Layout.Aside>
			<Pool.SwapInfo address={address}/>
		</Layout.Aside>
		<Layout.Main>
			<Pool.Swap address={address}/>
		</Layout.Main>
	</Layout>
}

export default () => {
	let { path } = useRouteMatch();
	return <Switch>
		<Route exact path={path} component={Liquidity}/>
		<Route exact path={`${path}/:address`} component={Stake}/>
		<Route exact path={`${path}/:address/swap`} component={Swap}/>
		<Redirect to={path}/>
	</Switch>
}
