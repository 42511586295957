import React, { useState, useEffect } from 'react';
import { Asset } from '@archetypes'
import { Panel, Stat, LazyBoi, GateKeeper } from '@components'
import { formatTimestamp } from '@util/helpers'
import appController from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';

export default ({address, ...rest}) => {
	const { synthetics } = appController
	const [ emp, setEmp ] = useState(false)

	useEffect(() => {
		const sub = synthetics.state.subscribe(`emps.${address}`, setEmp, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel fadeIn {...rest}>
		<GateKeeper
			assertions={[
				{
					assert: () => !!emp,
					onFail: () => <Panel fadeIn>
						<Refresh className='rotate'/>
						Fetching asset information
					</Panel>,
				}
			]}
			dependencies={[emp]}
			>
			<Panel.Title>Token Stats</Panel.Title>
			<Panel.Subtitle>
				<Asset.Icon token={emp?.token1?.symbol} text={emp?.token1?.name}/>
			</Panel.Subtitle>

			<Panel.Divider light small/>

			<Stat.Inline small title='Token Symbol'>
				<LazyBoi value={emp?.token1?.symbol}/>
			</Stat.Inline>

			<Stat.Inline small title='Liquidation Ratio'>
				<LazyBoi value={`${emp?.collateralRequirement}`} fromWei/>
			</Stat.Inline>

			<Stat.Inline small title='Minimum Sponsor Tokens'>
				<LazyBoi value={emp?.minSponsorTokens} dp={2} fromWei/>
			</Stat.Inline>
			<Stat.Inline small title='Global Collateralization Ratio (USD)'>
				<LazyBoi total={1} value={emp?.actualGCR} dp={2}/>
			</Stat.Inline>
			<Stat.Inline small title='Global Collateralization Ratio (Token)'>
				<LazyBoi total={1} value={Number(emp?.globalCollateralisationRatio)} dp={6}/>
			</Stat.Inline>
			
			{/*<Stat.Inline small title='Minimum Collateral'>
				<LazyBoi value={emp?.minCollateral} dp={2} fromWei/>
			</Stat.Inline>*/}

			<Stat.Inline small title='Expiry'>
				<LazyBoi value={formatTimestamp(emp?.expirationTimestamp)}/>
			</Stat.Inline>
		</GateKeeper>
	</Panel>
}