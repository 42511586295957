import React, { Fragment, useState, useEffect } from 'react';
import { Button, Table, Panel, GateKeeper, LazyBoi } from '@components'
import { Asset } from '@archetypes'
import assetController, { Status } from '@app/App.Controller'
import { ReactComponent as Swap } from '@assets/Icon_Swap.svg';
import { ReactComponent as Stake } from '@assets/Icon_Stake.svg';
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import {  fromWei } from '@util/helpers'


const columns = [
	{
		Header: 'Pool',
		accessor: 'pool',
	},
	{
		Header: 'Price',
		accessor: 'price',
	},
	{
		Header: 'Depth',
		accessor: 'depth',
	},
	{
		Header: 'Volume',
		accessor: 'volume',
	},
	// {
	// 	Header: 'TX Count',
	// 	accessor: 'tx_count',
	// },
	// {
	// 	Header: 'ROI',
	// 	accessor: 'roi',
	// },
	{
		Header :"Weight",
		accessor: 'weight'
	},
	{
		Header: '',
		accessor: 'actions',
	}
]

const PoolRow = ({address, token0, token1, spotPrice, depth, blocklytics, dashboard}) => <Table.Uncontrolled.Row 
	key={address}
	fields={{
		pool: <Asset.Icon token={[token0?.symbol, token1?.symbol]} text={token1?.symbol}/>,
		price: <LazyBoi.Currency value={token1?.dollarPrice}/>,
		depth: <LazyBoi.Currency value={depth} fromWei/>,
		volume: <LazyBoi.Currency value={dashboard?.volume}/>,
		weight: <LazyBoi value={`${(token0?.weight)/(10**16)}/${(token1?.weight)/(10**16)}`}/>,
		// roi: <LazyBoi.Percent total={1} value={blocklytics?.roi}/>,
		actions: [
			<Button key={1} small icon={<Stake/>} to={`/pools/${address}`}>Join</Button>,
			<Button.Primary key={2} small icon={<Swap/>} to={`/pools/${address}/swap`}>Swap</Button.Primary>
		]
	}}
/>

export default () => {
	const { perlinx } = assetController	
	const [ poolItems, setPoolItems ] = useState([])

	useEffect(() => {
		const sub = perlinx.state.subscribe(`pools`, pools => setPoolItems(Object.values(pools)), true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line


	return <Panel fadeIn>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'perlinx',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Fragment><Refresh className='rotate'/> Fetching PerlinX Liquidity Pools</Fragment>,
				}
			]}
			assertions={[
				{
					assert: () => poolItems.length > 0,
					onFail: () => <Fragment><Refresh className='rotate'/> Fetching PerlinX Liquidity Pools</Fragment>,
				},
				{
					assert: () => poolItems && poolItems.length > 0,
					onFail: () => <Fragment>No Pools Available</Fragment>,
				},
			]}
			dependencies={[poolItems]}
			>
			<Table.Uncontrolled columns={columns}>
				{poolItems && poolItems.map(pool => <PoolRow key={pool?.address} {...pool}/>)}
			</Table.Uncontrolled>
		</GateKeeper>
	</Panel>
}