import Tokens from './Tokens'
import Claim from './Claim'
import Lock from './Lock'
import LockInfo from './LockInfo'
import LiquidityIncentives from './LiquidityIncentives'

const Rewards = () => {}

Rewards.Tokens = Tokens
Rewards.Claim = Claim
Rewards.Lock = Lock
Rewards.LockInfo = LockInfo
Rewards.LiquidityIncentives = LiquidityIncentives

export default Rewards