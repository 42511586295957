import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components'
import { Button, Notification } from '@components'
import { ReactComponent as Contract } from '@assets/Icon_Contract.svg';
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as Check } from '@assets/Icon_Check.svg';
import { ReactComponent as ErrorIcon } from '@assets/Icon_Error.svg';
import assetController from '@app/App.Controller'

export default styled(
	({tokens, handleUpdate=()=>{}, handleComplete=()=>{}, className, ...rest}) => {
		const tokenHandler = assetController.tokens

		const [ displayState, setDisplayState ] = useState('closed')
		const [ buttons, setButtons ] = useState([])
		const [ title, setTitle ] = useState('Checking token approvals...')
		const [ icon, setIcon ] = useState(<Refresh className='rotate'/>)

		useEffect(() => {
			//setDisplayState('pending')
			defineButtons(tokens)
		}, [])  // eslint-disable-line

		const defineButtons = async _tokens => {
			_tokens = typeof _tokens === 'string' ? [_tokens] : _tokens
			handleUpdate({total: Object.values(_tokens).length, approved: 0})

			let _buttons =  {}

			for (var i = 0; i < _tokens.length; i++) {
				
				// hey dev, if you want to unapprove for testing, uncomment below
				// sometimes may need to run/refresh a couple of times
				//tokenHandler.approve(_tokens[i], 0)
				
				let approved = await tokenHandler.isApproved(_tokens[i])

				if(!approved){
					_buttons[_tokens[i]] = {
						token: _tokens[i],
						status: 'notapproved',
						text: `Approve ${_tokens[i]}`,
					}
				}
			}

			if(Object.values(_buttons).length > 0){
				setTitle('Token approval required to proceed')
				setIcon()
				setButtons(_buttons)
				setDisplayState('awaiting')
				handleUpdate({total: Object.values(_buttons).length, approved: 0})
			}else{
				setTitle('No tokens to approve')
				handleUpdate({total: 0, approved: 0})
				setIcon(<Check className='check'/>)
				setTimeout(() => setDisplayState('closed'), 1000)
			}
		}

		const handleAproval = async _token => {
			updateButton(_token, {status: 'approving', text: <Fragment><Refresh className='rotate'/>&nbsp;&nbsp;Approving {_token}...</Fragment>})
			tokenHandler
				.approve(_token)
				.then(() => {
					updateButton(_token, {status: 'approved', text: <Fragment><Check/>&nbsp;&nbsp;Approved {_token}</Fragment>})
				})
				.catch(msg => {
					Notification.warning('Approval rejected by user')
					updateButton(_token, {status: 'notapproved', text: <Fragment><ErrorIcon/>&nbsp;&nbsp;Approve {_token}</Fragment>})
				})
		}

		const updateButton = (_token, _props) => {
			let _buttons = { ...buttons }
			_buttons[_token] = { ..._buttons[_token], ..._props }
			setButtons(_buttons)
			updateStatus(_buttons)
		}

		const updateStatus = _buttons => {
			const total = Object.values(_buttons).length
			let approved = 0

			Object.values(_buttons).forEach(button => {
				if(button.status === 'approved') approved++
			})

			handleUpdate({total, approved})
			if(total === approved){
				handleComplete()
				setTimeout(() => setDisplayState('closed'), 2000)
			}
		}
		
		return <span 
			className={`asset-approval ${className}`}
			data-state={displayState}
			{...rest}
			>
			<span className='-title'><Contract/><span>{title}</span></span>
			<span className='-buttons'>
				{
					Object.values(buttons).map(button => 
						<Button 
							key={button.token} 
							disabled={button.status !== 'notapproved'} 
							onClick={() => handleAproval(button.token)} 
							small
							>
							{button.text}
						</Button>
					)
				}
				{icon}
			</span>
		</span>
	})`
	
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	max-height: 0;
	overflow: hidden;
	transition: all 0.5s;

	>span{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>.-title{
		color: var(--color-label);
		svg{
			margin-right: 0.4em;
			color: inherit;
		}
	}

	svg.check{
		color: var(--color-status-success, green)
	}

	&[data-state="pending"],
	&[data-state="awaiting"]{
		padding: 1.4rem 0;
		max-height: 5rem;
	}
	
	`