import React from 'react';
import { Panel } from '@components'
import { Asset } from '@archetypes'

export default ({symbol, className}) => {
	return <Panel fadeIn>
		<Panel.Title>Your Assets</Panel.Title>
		<Asset.Value token='pxOil' title='100 pxOil_Jul31' text='100 PERL'/>
		<Panel.Divider small/>
		<Asset.Value token='pxGold' title='50 pxGold_Aug31' text='100 PERL'/>
		<Panel.Divider small/>
		<Asset.Value token='pxCarbon' title='100 pxCarbon_Jul31' text='100 PERL'/>
	</Panel>
}