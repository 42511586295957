import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components'
import { Amount, Panel, Layout, Stat, LazyBoi, GateKeeper, Field } from '@components'
import { Asset } from '@archetypes'
import { MintController } from './_controller'
import appController, { Status } from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import useInterval from "../../hooks/useInterval"
import { fromWei, BN } from '@util/helpers'

const Main = styled(
	({address, ...rest}) => {

		const { synthetics } = appController

		// approval params
		const [ approvalStatus, setApprovalStatus ] = useState()
		const [ approvalParams, setApprovalParams ] = useState()
		const [ controller, setController ] = useState()
		const [ mintParams, setMintParams ] = useState()
		const [ timestamp, setTimestamp  ] = useState(new Date().valueOf())
		
		useEffect(() => {
			const mintController = new MintController(address, appController)
			mintController.on('approvals', setApprovalParams)
			mintController.on('params', setMintParams)
			setController(mintController)
			mintController.init()

			return () => {
				mintController.cleanup()
			}
		}, [address]) // eslint-disable-line

		// TODO : Make a hook
		useInterval(() => {
			const diff = new Date().valueOf() - timestamp
			if (diff > 2000) {
				// Validate the input amount
				if (controller && mintParams && mintParams.token.amount) {
					if (mintParams.token.min > mintParams.token.amount) {
						controller.updateInputAmount(mintParams.token.min)
					} else if (mintParams.token.amount > mintParams.token.max) {
						controller.updateInputAmount(mintParams.token.max)
					}
				}
			}
		}, 1000);
		
		return <Panel fadeIn {...rest}>
			<GateKeeper
				controllerAssertions={[
					{
						controller: 'account',
						key: 'status',
						assert: val => val === Status.READY,
						onFail: <Panel fadeIn>Connect your account to mint assets</Panel>,
					},
				]}
				assertions={[
					{
						assert: () => !!mintParams,
						onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching asset info</Panel>,
					},
					{
						assert: () => (Number(mintParams?.collateral?.max) >= Number(mintParams?.collateral?.min)),
						onFail: () => <Panel fadeIn>Not Enough PERL to mint. You need at least {mintParams?.collateral?.min} PERL. </Panel>,
					}
				]}
				dependencies={[mintParams]}
				>
				<Amount 
					title='Tokens to mint'
					info={
						<LazyBoi
							prefix={'Max:'} 
							value={mintParams?.token?.max} 
							suffix={mintParams?.token?.symbol}
							dp={2}
						/>
					} 
					value={mintParams?.token?.amount} 
					onChange={val => {
						setTimestamp(new Date().valueOf())
						return controller?.updateInputAmount(val)
					}}
					// min={mintParams?.token?.min}
					max={mintParams?.token?.max}
				/>
				<Panel.Filler>
					+
				</Panel.Filler>

				 { !mintParams?.noPosition && (
					<Field.RadioGroup
						title={'Do you want to add more collateral or use existing?'}
						value={mintParams?.options}
						options={[
							{
								label: 'Add more collateral',
								value: true
							},
							{
								label: 'Use existing collateral',
								value: false
							}
						]}
						onChange={val => controller?.updateOptions(val)}
					/>
				)
				}	
				
				{ (() => {
						const amount = mintParams?.collateral?.amount
						const symbol = mintParams?.collateral?.symbol
						const max = mintParams?.collateral?.max
						return (
							<>
								<Amount 
									title='Collateral to add (PERL)'
									info={
										<LazyBoi
											prefix={'Max:'} 
											value={max} 
											suffix={symbol}
											dp={2}
										/>
									} 
									value={amount}
									min={0}
									disabled
								/>
							</>
						)
					})()
				}

				<Panel.Divider light>
					<Asset.ContractApproval
						approvals={approvalParams}
						onUpdate={setApprovalStatus}
					/>
				</Panel.Divider>
				<Panel.Footer>
					<Panel.Footer.Group>
						<div style={{display : "flex", flexDirection : "row" }}>
							<Stat title='New Collaterization Ratio' small>
								<LazyBoi value={mintParams?.newCRUsd ? mintParams.newCRUsd*100 : 0} suffix={"%"} dp={2}/>
							</Stat>
							{/* <Stat title='Global Collaterization Ratio' small>
								<LazyBoi value={mintParams?.globalCRUsd ? mintParams?.globalCRUsd*100 : 0} suffix={"%"} dp={2}/>
							</Stat> */}
						</div>
					</Panel.Footer.Group>
					<Panel.Footer.Group>
						<Asset.ContractApproval.Button
							{...approvalStatus}
							onClick={() => {
								return synthetics.mint(mintParams?.tx)
							}}
							>
							Mint assets
						</Asset.ContractApproval.Button>
					</Panel.Footer.Group>
				</Panel.Footer>
			</GateKeeper>
		</Panel>
	})`
	
	`

export default ({className}) => {
	const { address } = useParams();

	return <Layout back title='Mint Assets' className={className}>
		<Layout.Aside>
			<Asset.Position address={address}/>
			<Asset.Stats address={address}/>
		</Layout.Aside>
		<Layout.Main>
			<Main address={address}/>
		</Layout.Main>
		
	</Layout>
}
