import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as CrossCircle } from '@assets/Icon_Cancel.svg';

const States = {
	CLOSED: 'CLOSED',
	OPENING: 'OPENING',
	OPEN: 'OPEN',
	CLOSING: 'CLOSING',
}

const animationSpeedMS = 200
const operationOrder_OPENING = [States.CLOSED, States.CLOSING, States.OPENING, States.OPEN]
const operationOrder_CLOSING = [States.OPEN, States.OPENING, States.CLOSING, States.CLOSED]

export default styled(
	({position='left', open, onStateChange=()=>{}, children, className}) => {

		const [state, setState] = useState(States.CLOSED)
		const [animationTimeout, setAnimationTimeout] = useState(setTimeout(()=>{},100))

		// perform the transition in order of operations
		const handleTransition = order => {
			if(state === order[0] || state === order[1]){
				if(state === order[1]) clearTimeout(animationTimeout)
				setState(order[2])
				onStateChange(order[2])
				setAnimationTimeout(setTimeout(() => {
					setState(order[3])
					onStateChange(order[3])
				}, animationSpeedMS))
			}
		}
		
		// handle state updates
		const handleOpen = () => handleTransition(operationOrder_OPENING)
		const handleClose = () => handleTransition(operationOrder_CLOSING)
		
		// trigger open/close on props update
		useEffect(() => !open ? handleClose() : handleOpen(), [open]) // eslint-disable-line
		
		return <section className={`drawer ${className}`} data-state={state}>
			<div className="overlay" onClick={handleClose}/>
			<article className="content" data-position={position}>
				<CrossCircle className='close' onClick={handleClose}/>
				{children}
			</article>
		</section>
	})`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	border-radius: 0;
	z-index: 100;
	overflow: hidden;

	>.overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0,0,0,0.4);
		transition: all ${animationSpeedMS*0.001*1.5}s ease-in-out;
		opacity: 0;
		cursor: ${({position}) => `${['n','e','s','w'][['top','right','bottom','left'].indexOf(position)]}-resize`};
	}

	>.content{
		position: absolute;
		width: auto;
		height: 100vh;
		opacity: 0;
		transition: all ${animationSpeedMS*0.001}s ease-in-out;
		box-shadow: 0 0 4rem rgba(0,0,0,0.3);

		>.close{
			position: absolute;
			top: 3.85em;
			right: 1em;
			font-size: 2em;
			opacity: 0.5;
			cursor: pointer;
			color: var(--color-light);
			&:hover{
				opacity: 0.9;
			}
		}

		&[data-position="right"]{
			top: 0;
			right: 0;
			transform: translateX(10rem);
		}
	}

	&[data-state='CLOSED'],
	&[data-state='CLOSING']{
		>.overlay,
		>.content{
			opacity: 0;
		}
	}

	&[data-state='CLOSED']{
		width: 0;
		height: 0;
		pointer-events: none;
	}

	&[data-state='OPENING'],
	&[data-state='OPEN']{
		pointer-events: all;
		width: 100vw;
		height: 100vh;
		
		>.overlay,
		>.content{
			opacity: 1;
		}

		>.content{
			transform: translate(0,0)
		}
	}

	`