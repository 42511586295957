import React, { useState, useEffect } from 'react';
import { get } from 'lodash'
import styled from 'styled-components'
import { omit } from 'lodash'
import assetController from '@app/App.Controller'

const StatusPanel = styled(
	({children, className}) => <div className={className}>{children}</div>
	)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 7.7% 1rem;
	text-align: center;
	color: var(--statuswrapper--message--color, white);
	font-size: var(--statuswrapper--message--font-size, 16px);
	letter-spacing: 0.016rem;

	svg{
		width: var(--statuswrapper-icon-size, var(--statuswrapper--message--font-size, 2rem));
		height: var(--statuswrapper-icon-size, var(--statuswrapper--message--font-size, 2rem));
	}

	>*{
		margin: 0 0.7rem;
	}
	`

// if we have a react element as a child, remove the children, wrap them in a 
// status panel & wrap it all back in the parent element
const StatusPanelRewrap = ({children, ...rest}) => {
	const child = React.Children.toArray(children)[0]
	return React.createElement(
		child.type,
		omit(child.props, ['children']), // remove children from parent
		<StatusPanel {...rest}>{child.props.children}</StatusPanel> // pass children to status panel
	)
}

export default ({controllerAssertions=[], assertions=[], dependencies=[], children, ...rest}) => {
	const [content, setContent] = useState(null)
	const [ statuses, setStatuses ] = useState(controllerAssertions.map(() => false))

	useEffect(() => {
		let subs = controllerAssertions.map(({controller, key, assert, onFail}, i) => {
			return assetController[controller].state.subscribe(key, val => {
				//console.log(controller, key, val, assert(val))
				statuses[i] = assert(val)
				setStatuses([...statuses])
			}, true)
		})
		return () => subs.map(sub => sub.unsubscribe())
	},[]) // eslint-disable-line
	
	const handleFailure = (onFail) => {
		let onFailResult = null
		let onFailComponent = null

		// is a string or react component - use it
		if(typeof onFail === "string" || React.isValidElement(onFail)){
			onFailResult = onFail
		}
		// if function, file callback
		else if(typeof onFail === "function"){
			onFailResult = onFail()
		}
	
		if(onFailResult && onFail !== null){
			if(React.isValidElement(onFailResult)){
				onFailComponent = <StatusPanelRewrap>{onFailResult}</StatusPanelRewrap>
			}else if(typeof onFailResult === "string"){
				onFailComponent = <StatusPanel>{onFailResult}</StatusPanel>
			}else{
				onFailComponent = <StatusPanel>Access unavailable</StatusPanel>
			}
		}

		return onFailComponent
	}
	
	useEffect(() => {
		let failed = false
		let failureMessage = children
		
		// run an account connection test if required
		if(Object.values(controllerAssertions).length){
			for (let i = 0; i < statuses.length; i++) {
				if(statuses[i] !== true){
					if(!failed){
						failed = true
						failureMessage = handleFailure(get(controllerAssertions[i], 'onFail', null))
					}
				}
			}
		}


		if(!failed){
			assertions.forEach(test => {

				if(!failed){
				
					// no assert callback? silent fail with error
					if(typeof test?.assert !== "function" ) {
						console.error('Assert callback was not provided') // todo?
					}
					// try assert cb for pass
					else  {
						if(test.assert() === true){
							get(test, 'onPass', ()=>{})()
						}else{
							failed = true
							failureMessage =  handleFailure(get(test, 'onFail', ()=>{}))
						}
					}
				}
			})
		}

		// passed all tests, return children
		setContent(failureMessage)
		
	}, [...statuses, ...dependencies, children]) // eslint-disable-line

	return content
}