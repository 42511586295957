import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Panel, Stat, Layout, LazyBoi, Amount, GateKeeper, Field } from '@components'
import { Asset } from '@archetypes'
import { RedeemAllController } from './_controller'
import appController, { Status } from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import { roundDown } from "@util/helpers"

const Main = ({ address, ...rest }) => {

    const { synthetics } = appController
    // approval params
    const [approvalStatus, setApprovalStatus] = useState()
    const [approvals, setApprovals] = useState()
    const [controller, setController] = useState()
    const [params, setParams] = useState()

    useEffect(() => {
        const redeemAllController = new RedeemAllController(address, appController)
        redeemAllController.on('approvals', setApprovals)
        redeemAllController.on('params', setParams)
        setController(redeemAllController)
        redeemAllController.init()
        return () => redeemAllController.cleanup()
    }, [address]) // eslint-disable-line

    return (
        <Panel fadeIn {...rest}>
            <GateKeeper
                controllerAssertions={[
                    {
                        controller: 'account',
                        key: 'status',
                        assert: val => val === Status.READY,
                        onFail: <Panel fadeIn>Connect account to redeem your assets</Panel>,
                    },
                ]}
                assertions={[
                    {
                        assert: () => !!params,
                        onFail: () => <Panel fadeIn><Refresh className='rotate' />Fetching position information</Panel>,
                    },
                    // {
                    //     assert: () => params?.userBalance !== "0",
                    //     onFail: () => <Panel fadeIn>No assets to redeem</Panel>,
                    // }
                ]}
                dependencies={[params]}
            >
                <Panel.Footer>
                    <Panel.Footer.Group>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                            <Stat title='Status' small>
                                {params?.expiryStatus === 0 && "Not Settled"}
                                {params?.expiryStatus === 1 && "Waiting for exit price"}
                                {params?.expiryStatus === 2 && "Ready"}
                            </Stat>
                            <Stat title='Resolved price' small>
                                <LazyBoi value={params?.expiryPrice} dp={2} fromWei />
                            </Stat>
                        </div>
                        <Asset.ContractApproval
                            approvals={approvals}
                            onUpdate={setApprovalStatus}
                        />
                    </Panel.Footer.Group>
                    <Panel.Footer.Group>
                        <Asset.ContractApproval.Button
                            {...approvalStatus}
                            disabled={params?.expiryStatus === 0}
                            onClick={() => synthetics.redeemAll(params?.tx)}
                        >
                            Redeem all Assets
                        </Asset.ContractApproval.Button>
                    </Panel.Footer.Group>
                </Panel.Footer>
            </GateKeeper>
        </Panel>
    )

}


export default ({ className }) => {
    const { address } = useParams();

    return <Layout back title='Redeem all Assets' className={className}>
        <Layout.Aside>
            <Asset.Position address={address} />
            <Asset.Stats address={address} />
        </Layout.Aside>
        <Layout.Main>
            <Main address={address} />
        </Layout.Main>
    </Layout>
}
