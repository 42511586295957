import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { Button, Blockie, Popover } from "@components";
import { Account } from '@archetypes'
import { truncateEthAddress } from '@util/helpers'
import assetController, { Status } from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';

const ButtonDisconnected = ({status}) => {
	const { account } = assetController
	return <Button.Primary 
		icon={status === 'PROCESSING' && <Refresh className='rotate'/>} 
		className={`wallet-button status-${status}`} 
		onClick={() => account.connect()}
		>
		{status === 'PROCESSING'  ? 'Connecting...' : 'Connect Metamask'}
	</Button.Primary>	
}

const ButtonConnected = styled(
	({className}) => {
		const { account, transactions } = assetController

		const [ txCount, setTxCount ] = useState(0)
		const [ address, setAddress ] = useState('')

		useEffect(() => {
			const sub1 = account.state.subscribe('address', setAddress, true)
			const sub2 = transactions.state.subscribe('items', items => setTxCount(Object.values(items).length), true)
			return () => {
				sub1.unsubscribe()
				sub2.unsubscribe()
			}
		}, []) // eslint-disable-line

		return <Popover
			className={`-menu  ${className}`}
			trigger={
				<Button className={`wallet-button`}>
					<div className="blockie">
						<Blockie address={address} diameter={20}/>
					</div>
					{truncateEthAddress(address, 8, 3)}
				</Button>
			}
			position={'bottomleft'}
		>	
			{txCount > 0 &&
				<Popover.Section title={`Transactions (${txCount})`} maxHeight='20rem' className={'txs'}>
					<Account.Transactions/>
				</Popover.Section>
			}
			<Popover.Item onClick={() => account.disconnect()}>Disconnect</Popover.Item>
		</Popover>
	})`

		.wallet-button{
			text-transform: uppercase;
			padding-left: 4.2rem !important;

			>.blockie{
				border: 1px solid white;
				position: absolute;
				top: 50%;
				left: 0.675rem;
				transform: translateY(-50%);
				border-radius: 50%;
				width: 2.6rem;
				height: 2.6rem;
				padding: 0.2rem;
				
				>.paper {
					width: 2rem !important;
					height: 2rem !important;
					> svg{
						width: 2rem;
						height: 2rem;
					}
				}

				>.rotate{
					width: 1.6rem;
					height: 1.6rem;
					position: absolute;
					top: 0.4rem;
					left: 0.4rem;
				}
			}

			&[data-processing="true"]{
				>.blockie{
					>.paper{
						opacity: 0.5
					}
				}

				.-rotate{
					display: block;
				}
			}
		}

		.txs{
			min-width: 30rem;
			>.-inner{
				padding: 0;
				.transactions{
					padding: 0 2rem;
				}
			}
		}
	`

export default ({className, ...rest}) => {
	
	const { account } = assetController
	const [ status, setStatus ] = useState()

	useEffect(() => {
		const sub = account.state.subscribe('status', setStatus, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return status === Status.READY
		? <ButtonConnected/>
		: <ButtonDisconnected status={status}/>
}