import React, { useState, useEffect, Fragment } from 'react';
import { Asset } from '@archetypes'
import { Button, Table, Panel, GateKeeper, LazyBoi, Placeholder } from '@components'
import assetController, { Status } from '@app/App.Controller'

import { ReactComponent as IconGear } from '@assets/Icon_Gear.svg';
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as IconPoolsEmpty } from '@assets/Icon_PoolsEmpty.svg';

const columns = [
	{
		Header: 'Pool',
		accessor: 'pool',
	},
	// {
	// 	Header: 'LP tokens',
	// 	accessor: 'tokens',
	// },
	{
		Header: 'Your Share',
		accessor: 'share',
	},
	{
		Header: 'Your Assets',
		accessor: 'assets',
	},
	// {
	// 	Header: 'Your Earnings',
	// 	accessor: 'earnings',
	// },
	// {
	// 	Header: 'ROI',
	// 	accessor: 'roi',
	// },
	// {
	// 	Header: 'Pool Expiry',
	// 	accessor: 'expiry',
	// },
	{
		Header: '',
		accessor: 'actions',
	}
]

const PoolRow = ({address, token0, token1, stake, blocklytics }) => <Table.Uncontrolled.Row 
	key={address}
	fields={{
		pool: <Asset.Icon token={[token0?.symbol, token1?.symbol]} text={token1?.symbol}/>,
		share: <LazyBoi.Percent value={stake?.share}/>,
		assets: <LazyBoi.Currency value={stake?.value} fromWei/>,
		// roi: <LazyBoi value={blocklytics?.roi}/>,
		actions: <Button to={`/pools/${address}`} small icon={<IconGear/>}>Manage</Button>
	}}
/>

export default () => {
	const {
		account,
		perlinx
	} =  assetController
	
	const [ pools, setPools ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe('pools', pools => {
			if(Object.values(pools).length > 1){
				let filtered = Object.values(pools).filter(pool => pool?.stake?.units > 0)
				setPools(filtered)
			}
		}, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line


	return <Panel fadeIn>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to view your pools',
				},
				{
					controller: 'perlinx',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Fragment><IconRefresh className='rotate'/>Fetching pools</Fragment>,
				},
			]}
			assertions={[
				{
					assert: () => Array.isArray(pools) && !!pools.length,
					onFail: () => <Placeholder 
						icon={<IconPoolsEmpty style={{marginLeft: '0.3em'}}/>} 
						title='No pools joined' 
						text='Join a liquidity pool by staking tokens' 
						links={[
							{
								to: '/pools',
								text: 'View Pools'
							}
						]} 
					/>
				}
			]}
			dependencies={[account.state.address, pools]}
			>
			<Table.Uncontrolled columns={columns}>
				{pools && pools.map(pool => <PoolRow key={pool.address} {...pool}/>)}
			</Table.Uncontrolled>
		</GateKeeper>
	</Panel>
}