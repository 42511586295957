import React, { useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Amount, Grid, Panel, Stat, Layout, LazyBoi, GateKeeper } from '@components'
import { Asset } from '@archetypes'
import { truncateEthAddress, formatTimestamp } from '@util/helpers'
import appController, { Status } from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';

import { 
	DisputeController
} from './_controller'

const Liquidate = ({address, sponsor, ...rest}) => {
	const { synthetics } = appController

	const [ approvalStatus, setApprovalStatus ] = useState()
	const [ approvalParams, setApprovalParams ] = useState()
	const [ controller, setController ] = useState()
	const [ liquidateParams, setLiquidateParams ] = useState()

	useEffect(() => {
		const disputeController = new DisputeController(address, sponsor, appController)
		disputeController.on('approvals', setApprovalParams)
		disputeController.on('params', setLiquidateParams)
		setController(disputeController)
		disputeController.init()
		return () => disputeController.cleanup()
	}, [address]) // eslint-disable-line

	return <Panel fadeIn {...rest}>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Panel fadeIn>Connect your account to liquidate this position</Panel>,
				},
				
			]}
			assertions={[
				{
					assert: () => !!liquidateParams,
					onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching position</Panel>,
				},
				// {
				// 	assert: () => +liquidateParams?.max > 0,
				// 	onFail: () => <Panel fadeIn>No assets to liquidate</Panel>,
				// }
			]}
			dependencies={[liquidateParams, liquidateParams?.max]}
			>
			<Amount 
				title={`Amount to Liquidate (${liquidateParams?.symbol})`}
				info={`Max: ${liquidateParams?.max}`}
				value={liquidateParams?.amount} 
				onChange={val => controller?.updateAmount(val)}
				min={liquidateParams?.min}
				max={liquidateParams?.max}
				withPresets
			/>

			<Panel.Divider>
				<Asset.ContractApproval
					approvals={approvalParams}
					onUpdate={setApprovalStatus}
				/>
			</Panel.Divider>

			<Panel.Footer>
				<Panel.Footer.Group>
					<Stat title={`${liquidateParams?.collateralSymbol} value`} small>
						<LazyBoi value={liquidateParams?.collateralAmount} suffix={liquidateParams?.collateralSymbol} dp={2}/>
					</Stat>
				</Panel.Footer.Group>
				<Panel.Footer.Group>
					<Asset.ContractApproval.Button
						{...approvalStatus}
						onClick={() => synthetics.liquidate(liquidateParams?.tx)}
						>
						Liquidate
					</Asset.ContractApproval.Button>
				</Panel.Footer.Group>
			</Panel.Footer>
		</GateKeeper>
	</Panel>
}

const Info = ({address, sponsor}) => {
	const { synthetics } = appController
	const [ position, setPosition ] = useState()
	const [ emp, setEmp ] = useState()

	useState(async () => {
		const sub1 = synthetics.state.subscribe(`emps.${address}`, setEmp, true)
		const sub2 = synthetics.getLiquidationPosition(address, sponsor, setPosition)
		return () => {
			sub1.unsubscribe()
			sub2.unsubscribe()
		}
	}, [])

	return <Panel fadeIn>
		<GateKeeper
			assertions={[
				{
					assert: () => !!position,
					onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching dispute</Panel>,
				}
			]}
			dependencies={[position]}
			>
			<Panel.Title extra={<Asset.Position.Tag safe={position?.safe}/>}>
				<Asset.Icon token={emp?.token1?.symbol}/>
			</Panel.Title>

			<Grid cols={2}>
				<Stat title='Owner'>
					<LazyBoi value={truncateEthAddress(sponsor)}/>
				</Stat>
				<Stat title='Expiry'>
					<LazyBoi value={formatTimestamp(emp?.expirationTimestamp)}/>
				</Stat>
				<Stat title='Collateral Size'>
					<LazyBoi value={position?.collateral} suffix={emp?.token0?.symbol} dp={2} fromWei/>
				</Stat>
				<Stat title='Amount Owing'>
					<LazyBoi value={position?.tokensOutstanding} suffix={emp?.token1?.symbol} dp={2} fromWei/>
				</Stat>

				<Stat title='Amount Owing'>
					<LazyBoi value={position?.liquidationAmount} suffix={emp?.token1?.symbol} dp={2} fromWei/>
				</Stat>
			</Grid>
		</GateKeeper>
	</Panel>
}

export default () => {
	let { address, sponsor } = useParams();
	return <Layout title='Liquidate' back='/assets'>
		<Layout.Aside>
			<Info address={address} sponsor={sponsor}/>
		</Layout.Aside>
		<Layout.Main>
			<Liquidate address={address} sponsor={sponsor}/>
		</Layout.Main>
	</Layout>
}