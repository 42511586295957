import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { Button, Layout, Panel, LazyBoi } from "@components";
import { Asset } from '@archetypes'
import assetController from '@app/App.Controller'
import { ReactComponent as IconError } from '@assets/Icon_Error.svg';

import { 
	UpgradeController, 
} from './_controller'

const Banner = styled(
	({message, errors={}, className}) => {
		
		const { wallet } = assetController
		const [ approved, setApproved ] = useState(false)
		const [ approvalParams, setApprovalParams ] = useState()
		const [ balance, setBalance ] = useState(0)

		useEffect(() => {
			const upgradeController = new UpgradeController(assetController)
			upgradeController.on('approval', setApprovalParams)
			upgradeController.on('details', details => {
				setApproved(details?.approved)
				setBalance(details?.balance)
			})
			upgradeController.init()
			return () => upgradeController.cleanup()
		}, [])

		return <Layout className={`upgrade -banner ${className}`} data-visible={balance > 0}>
			<Layout.Main>
				<Panel>
					<span className='-title'>
						<IconError/>
						<span><LazyBoi value={balance} dp={2}/> Legacy PERL tokens have been found in your account. Upgrade them now to use Perlinx Rewards.</span>
					</span>
					<span className='-buttons'>
						{!approved && 
							<Asset.ContractApproval.Trigger
								{...approvalParams}
								amount={-1}
								onApproval={() => setApproved(true)}
							/>
						}
						<Button.Primary
							disabled={!approved} 
							onClick={() => wallet.upgradeFromLegacyPerl()} 
							small
							>
							Upgrade
						</Button.Primary>
					</span>
				</Panel>
			</Layout.Main>
		</Layout>
	})`
	margin-bottom: 3em;
	overflow: hidden;
	transition: all 0.5s;
	max-height: 10rem;

	.panel{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #2F4966;

		>span{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		>.-title{
			color: var(--color-label);
			>svg{
				margin-right: 0.4em;
				color: inherit;
				width: var(--font-size-xlarge);
				height: var(--font-size-xlarge); 
			}
		}

		&[data-state="pending"],
		&[data-state="awaiting"]{
			//padding: 1.4rem 0;
			//max-height: 5rem;
		}
	}

	&[data-visible="false"]{
		padding: 0;
		max-height: 0;
		margin: 0;
	}
	
	`

export default {
	Banner
}