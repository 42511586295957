import { get, set, range, unset, find, filter } from 'lodash'
import Web3 from 'web3';
import { v4 as uuidv4 } from 'uuid';
import { PerlinX, Token, Balancer, ExpiringMultiParty, ExpiringMultiPartyCreator } from '@contracts'
import { Notification } from '@components'
import { UmaAssetQuery , UmaLiquidationQuery, DashboardApi } from '@app/App.config'
import { strToJson, fromWei, BN, formatBN, isObject, isArray, maxWei, getRate } from '@util/helpers'
import { ApolloClient, InMemoryCache } from '@apollo/client';

// provide some default statuses for modules
export const Status = {
	INITIALISED: 'INITIALISED',
	PROCESSING: 'PROCESSING',
	FETCHING: 'FETCHING',
	REFETCHING: 'REFETCHING',
	READY: 'READY',
	ERROR: 'ERROR'
}

let ethereum = window.ethereum
let web3 = null



// ----- helpers



class State {
	ID = null

	constructor(id, {initialState, subscriptionService}) {
		this.ID = id;
		return {
			...initialState,
			ID: id,
			status: Status.INITIALISED,
			subscriptions: subscriptionService,
			trigger: this.trigger,
			subscribe: this.subscribe,
			subscribeOnce: this.subscribeOnce,
			set: this.set,
			update: this.update,
			triggerParent: this.triggerParent,
			setStatus: this.setStatus,
			onStatus: this.onStatus,
			clear: this.clear,
			reset: this.reset,
			initialState: {...initialState}
		}
	}

	async reset(){
		return new Promise((resolve, reject) => {
			Object.keys(this.initialState).forEach(key => {
				this.set(key, this.initialState[key])
			})

			this.subscriptions.trigger(`${this.ID}.reset`, this)

			resolve(this)
		});
	}

	trigger(key, val){
		this.subscriptions.trigger(`${this.ID}.${key}`, val)
	}

	subscribe(key, cb, watch=false, fireParentCbs=false){
		const existingval = get(this, key)
		let sub = {unsubscribe:() => {}}

		const handleExistingVal = (val) => {
			//if(val && val !== this.initialState[key]){
			if(val){
				if(isObject(val) && Object.values(val).length){
					cb(val)
				}
				else if(isArray(val) && val.length > 0){
					cb(val)
				}
				else{
					cb(val)
				}
			}
		}
		
		// not watching?
		if(watch !== true){
			// already has val, fire it back
			if(existingval){
				handleExistingVal(existingval)
			}
			// no val? subscribe once
			else{
				sub = this.subscriptions.subscribe(`${this.ID}.${key}`, () => cb(get(this, key)), true, fireParentCbs)
			}
		}
		// watching
		else{
			handleExistingVal(existingval)
			sub = this.subscriptions.subscribe(`${this.ID}.${key}`, () => cb(get(this, key)), false, fireParentCbs)
		}

		return sub
	}

	subscribeOnce(key, cb, fireParentCbs) {
		return this.subscribe(key, cb, false, fireParentCbs)
	}

	set(key, val=null, cb){
		set(this, key, val)
		this.subscriptions.trigger(`${this.ID}.${key}`, val)
		cb && cb(this)
		this.triggerParent(key)
	}

	// update an existing item with new values
	update(key, fields, cb){
		let val = set(this, key, { ...get(this, key), ...fields })
		this.subscriptions.trigger(`${this.ID}.${key}`, val)
		cb && cb(this)
		this.triggerParent(key)
	}

	triggerParent(key){
		const split = key.split('.');
		if(split.length === 2){
			let parentKey = split[0]
			let parentVal = get(this, parentKey)
			this.subscriptions.trigger(`${this.ID}.${parentKey}`, parentVal)
		}
	}

	clear(key, cb){
		unset(this, key)
		this.subscriptions.trigger(`${this.ID}.${key}.clear`)
		//this.triggerParent(key)
		cb && cb(this)
	}

	setStatus(status, cb){
		this.status = status
		this.subscriptions.trigger(`${this.ID}.status`, status)
		this.subscriptions.trigger(`${this.ID}.status.${status}`, this)
		cb && cb(this)
	}

	onStatus(status, cb){
		let sub = {unsubscribe:() => {}}

		if(this.status === status){ 
			cb(this)
		}else{
			sub = this.subscriptions.subscribe(`${this.ID}.status.${status}`, () => cb(this))
		}

		return sub
	}
}

const subscriptions = {
	pool: {}, 
	subscribe: (key, cb=()=>{}, once=false) => {
		const id = uuidv4()
		
		// define the subscription shape/data
		const data = {
			cb: cb,
			once: once,
			unsubscribe: () => unset(subscriptions, `pool.${key}.${id}`)
		}
		
		// add subscription to pool
		set(subscriptions, `pool.${key}.${id}`, data)
		
		return data
	},
	subscribeOnce: (key, cb) => subscriptions.subscribe(key, cb, true),
	trigger: (key, payload) => {
		const _cbs = get(subscriptions, `pool.${key}`, {})

		Object.keys(_cbs).forEach(id => {
			if(_cbs[id]?.cb){
				const { cb, once } = _cbs[id]
				// fire callback
				typeof cb === "function" && cb(payload)
				// remove if one time only
				if(once === true) unset(subscriptions, `pool.${key}.${id}`)
			}
		})
	}
}

let cache = {
	key: null,
	prefix: null,
	init: prefix => cache.prefix = prefix.toLowerCase(),
	formatKey: key => `${cache.prefix ? `${cache.prefix}.` : ''}${cache.key ? `${cache.key}.` : ''}${key}`,
	set: (key, data, on=false) => on === true ? localStorage.setItem(cache.formatKey(key), JSON.stringify(data)) : null,
	get: (key, on=false) => {
		const _key = cache.formatKey(key)
		if(on !== true) cache.remove(_key)
		return strToJson(localStorage.getItem(_key)) 
	},
	remove: key => localStorage.removeItem(cache.formatKey(key))
}



// ----- module archetypes



let _network = {
	state: new State('network', {
		initialState: {
			status: Status.INITIALISED,
			available: null,
			current: null
		},
		subscriptionService: subscriptions,
	}),

	init: async ({available}) => {
		if(!ethereum){
			_network.state.trigger(Status.ERROR, `You need to connect metamask in order to access this app`);
			_network.state.setStatus(Status.ERROR)
			return 
		}
		
		// when we have an available network, connect
		_network.state.set('available', available, () => _network.connect())
		
		// once connected, listen for chain changes & hard reload
		_network.state.onStatus(Status.READY, _network.watchForChanges)
		_network.state.onStatus(Status.ERROR, _network.watchForChanges)
	},
	watchForChanges: async () => {
		ethereum.on("chainChanged", () => window.history.go());
		ethereum.on('disconnect', () => window.history.go());
	},
	connect: async () => {
		_network.state.setStatus(Status.PROCESSING)
		
		const chainId = await web3.eth.getChainId()
		const network = _network.state.available[chainId]

		// no network or unavailable
		if(!network || network?.enabled !== true){
			_network.state.trigger(Status.ERROR, `${_network.state.current?.name||'This network'} is not currently supported`)
			_network.state.setStatus(Status.ERROR)
			_network.state.set('current', {id: chainId, ...network})
		}
		
		// network found
		else{
			// prefix cache with network ID
			cache.init(network.name)
			
			_network.state.set('current', {id: chainId, ...network})
			_network.state.setStatus(Status.READY)
			_network.state.trigger('NETWORK_CONNECTED')
		}
	},
	disconnect: () => {
		console.log('todo')
	},
}

let _account = {
	state: new State('account', {
		initialState: {
			status: Status.INITIALISED,
			address: null,
			balance: null
		},
		subscriptionService: subscriptions,
	}),

	init: () => {
		_account.state.subscribe(`address`, async address => {
			ethereum.on("accountsChanged", _account.change)
		})
	},

	connect: async () => {
		//_network.connect()
		_account.state.setStatus(Status.PROCESSING)
	
		//_network.state.onStatus(`READY`, async ({network}) => {
		_network.state.subscribe(`current`, async network => {
			
			ethereum.enable()
				.then(async () => {
					const accounts = await web3.eth.getAccounts()
					
					if(accounts[0]){
						_account.state.set('address', accounts[0])
						_account.state.setStatus(Status.READY)
						_account.state.trigger('connected', _account.state.address)
						
						Notification.success({
							title: 'Account connected',
							text: 'You are ready to start staking',
						})
					}else{
						_account.disconnect()
					}
				})
				.catch(err => {
					//_network.state.trigger(Status.ERROR, err.message)
					//_network.state.setStatus(Status.ERROR)
				})
		})
	},

	disconnect: () => {
		_account.state.clear('address')
		_account.state.setStatus(Status.INITIALISED)
		_account.state.trigger('disconnected')
		Notification.warning('Account disconnected')
	},

	change: async () => {
		_account.state.clear('address')
		_account.state.setStatus(Status.INITIALISED)
		_account.state.trigger('disconnected')

		await _wallet.state.reset()
		await _perlinx.state.reset()

		_wallet.init()
		_perlinx.init()
		_account.connect()
	}
}

let _balancer = {
	init: () => {},

	contract: address => new web3.eth.Contract(Balancer, address),

	stake: async (address, {amountOut, token0, token1}, cb=()=>{}) => {
		return _balancer
			.__call(
				address, 
				'joinPool', 
				[
					amountOut, 
					[token0,token1]
				], 
				`Staking in pool`,
			)
			.then(() => {
				cb()
				_perlinx.__hydrateOne(address)
			})
	},

	unstake: async (address, {units}, cb=()=>{}) => {
		return _balancer
			.__call(
				address, 
				'exitPool', 
				[
					units, 
					[1,1]
				], 
				`Unstaking from pool`
			)
			.then(() => {
				cb()
				_perlinx.__hydrateOne(address)
			})
	},

	swap: async (address, {sellAddress, buyAddress, amount}, cb=()=>{}) => {
		return _balancer
			.__call(
				address, 
				'swapExactAmountIn', 
				[
					sellAddress,
					amount, 
					buyAddress, 
					1, 
					formatBN(BN('1000000000000000000000000000'))
				], 
				`Swapping Tokens`
			)
			.then(() => {
				cb()
				_perlinx.__hydrateOne(address)
			})
	},

	// call a method with params on the perlinx contract
	__call: (POOL_ADDRESS, ...rest) => {
		let contract = _balancer.contract(POOL_ADDRESS)
		return _transactions.__call(
			contract,
			...rest
		)
	}
}	

let _tokens = {
	init: () => {},

	isApproved: (toApprove, onContract) => new Promise(async (resolve, reject) => {
		_network.state.subscribeOnce(`current`, async network => {
			_account.state.subscribeOnce(`address`, async address => {
				const TokenContract = new web3.eth.Contract(Token.abi, onContract)
				const allowance = await TokenContract.methods.allowance(address, toApprove).call()
				const balance = await TokenContract.methods.balanceOf(address).call() 
				resolve(+allowance > +balance)
			})
		})
	}),

	approve: (toApprove, onContract, amount=-1) => new Promise(async (resolve, reject) => {
		_network.state.subscribeOnce(`current`, async network => {
			_account.state.subscribeOnce(`address`, async address => {
				const TokenContract = new web3.eth.Contract(Token.abi, onContract)
				const approvalAmount = amount < 0 ? maxWei : amount
				const _tx = _transactions.add({title: 'Approval pending'})
				try {
					const transaction = await TokenContract.methods.approve(toApprove, approvalAmount).send({from: address})
					_tx.success(transaction)
					resolve(transaction)
				} catch(e) {
					_tx.error(e)
					reject(e.message)
				}
			})

		})
	}),
}

let _wallet = {
	state: new State('wallet', {
		initialState: {
			status: Status.INITIALISED,
			balances: null,
			conversionrate: null,
			usdrates: {},
			perlLegacyBalance: 0
		},
		subscriptionService: subscriptions,
	}),

	init: () => {
		_wallet.__fetchBaseConversionRate()
		_wallet.__hydrate()
	},

	__fetchBaseConversionRate: () => new Promise(async (resolve, reject) => {
		fetch('https://api.coingecko.com/api/v3/simple/price?ids=perlin&vs_currencies=usd')
			.then(r => r.json())
			.then(data => {
				_wallet.state.set('conversionrate', data.perlin.usd)
			})
			.catch(e => {
				_wallet.state.setStatus(Status.ERROR)
			})
	}),

	//
	__hydrate: () => {
		_wallet.__hydrateEth()
		_wallet.__hydratePerl()
		_wallet.__hydrateTokens()
		_wallet.__hydratePerlLegacy()
	},

	__hydrateTokens: () => new Promise(async resolve => {
		_perlinx.state.onStatus(Status.READY, async ({pools}) => {
			Object.values(pools).forEach(({address}) => _wallet.__hydrateToken(address))
		}, true)
	}),

	__hydrateToken: async POOL_ADDRESS => {
		_account.state.subscribe(`address`, async ACCOUNT_ADDRESS => {
			_perlinx.state.subscribe(`pools.${POOL_ADDRESS}`, async pool => {
				const balance = await pool?.token1?.contract?.methods.balanceOf(ACCOUNT_ADDRESS).call()
				const conversionRate = getRate(pool.token0, pool.token1)
				_wallet.state.set(`balances.${pool.token1.symbol}`, {
					balance: balance,
					// rate: parseFloat(pool?.spotPrice||0)
					rate: (pool.token1 && pool.token0) ? conversionRate : 0
				})
			}, true)
		})
	},

	__hydrateEth: () => {
		_account.state.subscribe(`address`, async address => {
			const balance = await new web3.eth.getBalance(address)
			_wallet.state.set('balances.ETH.balance', balance)

			_wallet.convertViaCoingecko('ethereum').then(rate => {
				_wallet.state.update(`balances.ETH`, {rate: rate})
			})
		}, true)
	},

	__hydratePerl: () => {
		_network.state.subscribe(`current`, async NETWORK => {
			_account.state.subscribe(`address`, async ACCOUNT_ADDRESS => {
				const contract = new web3.eth.Contract(Token.abi, NETWORK.PERL)
				const balance = await contract.methods.balanceOf(ACCOUNT_ADDRESS).call() 
				_wallet.state.set('balances.PERL.balance', balance)

				_wallet.convertViaCoingecko('perlin').then(rate => {
					_wallet.state.update(`balances.PERL`, {rate: rate})
				})
			}, true)
		}, true)
	},

	__hydratePerlLegacy: () => {
		_network.state.subscribe(`current`, async NETWORK => {
			_account.state.subscribe(`address`, async ACCOUNT_ADDRESS => {
				const contract = new web3.eth.Contract(Token.abi, NETWORK.PERL_LEGACY)
				const balance = await contract.methods.balanceOf(ACCOUNT_ADDRESS).call() 
				_wallet.state.set('perlLegacyBalance', balance)
			})
		})
	},

	convertViaCoingecko: name => fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${name}&vs_currencies=usd`).then(r=>r.json()).then(r=>r[name].usd),

	convertFromSymbol: (symbol, amount=0, cb=()=>{}) => {
		_perlinx.state.subscribe(`pools`, pools => {

			const pool = find(pools, ['token1.symbol', symbol])

			// if it's a pool - get spotprice
			if(pool){
				cb(amount * pool?.spotPrice||0)
			}
			
			// no pool? base token or ETH!
			else{
				
				// cached rate?
				// TODO: fire these off to the cache handler with expiry
				// so we can keep the rates updated
				let rate = _wallet.state.usdrates[symbol]
				if(rate){
					cb(amount*rate)
				}
				// fetch rate & cache
				else{
					let name = null
					switch (symbol) {
						case 'ETH':
							name = 'ethereum'
							break;
						case 'PERL':
							name = 'perlin'
							break;
						default: break;
					}

					name && _wallet.convertViaCoingecko(name).then(rate => {
						_wallet.state.set(`usdrates.${symbol}`, rate)
						cb(amount*rate)
					})
				}
			}
		})
	}, 

	// perl1 -> perl2 upgrade function
	upgradeFromLegacyPerl: () => {
		_network.state.subscribe(`current`, async NETWORK => {
			_account.state.subscribe(`address`, async ACCOUNT_ADDRESS => {
				const contract = new web3.eth.Contract(Token.abi, NETWORK.PERL)

				_transactions.__call(
					contract,
					'upgrade',
					[], 
					'Upgrading PERL',
					() => {
						_wallet.__hydratePerl()
						_wallet.__hydratePerlLegacy()
					}
				)
			})
		})
	}
}

let _perlinx = {
	state: new State('perlinx', {
		initialState: {
			contract: null,
			pools: [],
			overview: null,
			rewardsSummary: [],
			incentivesSummary: {},
			isAdmin: false,
			status: null
		},
		subscriptionService: subscriptions,
	}),

	init: () => {
		_network.state.subscribe(`current`, network => {
			const contract = new web3.eth.Contract(PerlinX, network.PERLX)
			_perlinx.state.set('contract', contract)
			
			_perlinx.__hydrate()
			_perlinx.__hydrateRewardsSummary()
			_perlinx.__hydrateIncentivesSummary()
			_perlinx.__updateOverview()

			_account.state.subscribe(
				`address`, 
				async address => {
					let isAdmin = await contract.methods.isAdmin(address).call()
					_perlinx.state.set('isAdmin', isAdmin)
				},
				true
			)
		})
	},


	/*
		hydration
	*/

	__hydrate: async () => new Promise(resolve => {
		_perlinx.state.subscribe('contract', async REWARDS_CONTRACT => {
			_perlinx.state.setStatus(Status.PROCESSING)
			const rewardsPoolCount = await REWARDS_CONTRACT.methods.poolCount().call()

			await Promise.all(
				range(rewardsPoolCount).map(async i => {
					const poolAddress = await REWARDS_CONTRACT.methods.arrayPerlinPools(i).call()
					const listed = await REWARDS_CONTRACT.methods.poolIsListed(poolAddress).call()

					
					if(listed === true){
						return await _perlinx.__hydrateOne(poolAddress)
					}
				})
			)

			_perlinx.state.setStatus(Status.READY)
		});
	}),

	__hydrateOne: (POOL_ADDRESS, cb=()=>{}) => new Promise(async resolve => {
		_perlinx.state.subscribe('contract', async REWARDS_CONTRACT => {
			_network.state.subscribe('current', async network => {
				_wallet.state.subscribe('conversionrate', async conversionrate => {
					const TOKEN0_ADDRESS = network.PERL
					const TOKEN1_ADDRESS = await REWARDS_CONTRACT.methods.mapPool_Asset(POOL_ADDRESS).call()

					const POOL_CONTRACT = new web3.eth.Contract(Balancer, POOL_ADDRESS)

					// catch any errors and don't show pool
					try {
						// calc perl values
						const TOKEN0_CONTRACT = new web3.eth.Contract(Token.abi, TOKEN0_ADDRESS)
						const TOKEN0_NAME = await TOKEN0_CONTRACT.methods.name().call()
						const TOKEN0_SYMBOL = await TOKEN0_CONTRACT.methods.symbol().call()
						const TOKEN0_DECIMALS = await TOKEN0_CONTRACT.methods.decimals().call()
						const TOKEN0_SPOT_PRICE = await POOL_CONTRACT.methods.getSpotPrice(TOKEN1_ADDRESS, TOKEN0_ADDRESS).call()
						const TOKEN0_SPOT_PRICE_SANS_FEE = await POOL_CONTRACT.methods.getSpotPriceSansFee(TOKEN1_ADDRESS, TOKEN0_ADDRESS).call()
						const TOKEN0_BAL = await POOL_CONTRACT.methods.getBalance(TOKEN0_ADDRESS).call()
						const TOKEN0_PRICE = conversionrate
						const TOKEN0_WEIGHT = await POOL_CONTRACT.methods.getNormalizedWeight(TOKEN0_ADDRESS).call()
						const TOKEN0_DEPTH = await TOKEN0_CONTRACT.methods.balanceOf(POOL_ADDRESS).call()

						// calc token values
						const TOKEN1_CONTRACT = new web3.eth.Contract(Token.abi, TOKEN1_ADDRESS)
						const TOKEN1_NAME = await TOKEN1_CONTRACT.methods.name().call()
						const TOKEN1_SYMBOL = await TOKEN1_CONTRACT.methods.symbol().call()
						const TOKEN1_DECIMALS = await TOKEN1_CONTRACT.methods.decimals().call()
						const TOKEN1_SPOT_PRICE = await POOL_CONTRACT.methods.getSpotPrice(TOKEN0_ADDRESS, TOKEN1_ADDRESS).call()
						const TOKEN1_SPOT_PRICE_SANS_FEE = await POOL_CONTRACT.methods.getSpotPriceSansFee(TOKEN0_ADDRESS, TOKEN1_ADDRESS).call()
						const TOKEN1_BAL = await POOL_CONTRACT.methods.getBalance(TOKEN1_ADDRESS).call()
						const TOKEN1_PRICE = fromWei(TOKEN1_SPOT_PRICE) * TOKEN0_PRICE
						const TOKEN1_WEIGHT = await POOL_CONTRACT.methods.getNormalizedWeight(TOKEN1_ADDRESS).call()
						const TOKEN1_DEPTH = await TOKEN1_CONTRACT.methods.balanceOf(POOL_ADDRESS).call()

						// calc pool values
						const POOL_TOTAL_SUPPLY = await POOL_CONTRACT.methods.totalSupply().call()
						const POOL_SWAP_FEE = await POOL_CONTRACT.methods.getSwapFee().call()
						const POOL_DEPTH = BN(TOKEN0_BAL).times(TOKEN0_PRICE).plus(BN(TOKEN1_BAL).times(TOKEN1_PRICE))
						const POOL_VALUE = (fromWei(TOKEN0_BAL) * TOKEN0_PRICE) + (fromWei(TOKEN1_BAL) * TOKEN1_PRICE)
						const SPOT_PRICE = BN(fromWei(TOKEN1_SPOT_PRICE)).times(fromWei(TOKEN0_SPOT_PRICE))

						const pool = {
							name: `PX-${TOKEN0_SYMBOL}-${TOKEN1_SYMBOL}`,
							address: POOL_ADDRESS,
							contract: POOL_CONTRACT,
							totalSupply: POOL_TOTAL_SUPPLY,
							totalUnits: POOL_TOTAL_SUPPLY,
							spotPrice: SPOT_PRICE,
							swapFee: POOL_SWAP_FEE,
							depth: POOL_DEPTH,
							value: POOL_VALUE,

							// token0 = base token
							token0: {
								address: TOKEN0_ADDRESS,
								name: TOKEN0_NAME,
								symbol: TOKEN0_SYMBOL,
								decimals: TOKEN0_DECIMALS,
								spotPrice: TOKEN0_SPOT_PRICE,
								balance: TOKEN0_BAL,
								price: TOKEN0_PRICE,
								priceSansFee: TOKEN0_SPOT_PRICE_SANS_FEE,
								weight: TOKEN0_WEIGHT,
								conversionRate: conversionrate,
								depth: TOKEN0_DEPTH,
								contract: TOKEN0_CONTRACT
							},
							// token1 = pair token
							token1: {
								address: TOKEN1_ADDRESS,
								name: TOKEN1_NAME,
								symbol: TOKEN1_SYMBOL,
								decimals: TOKEN1_DECIMALS,
								spotPrice: TOKEN1_SPOT_PRICE,
								priceSansFee: TOKEN1_SPOT_PRICE_SANS_FEE,
								balance: TOKEN1_BAL,
								price: SPOT_PRICE / TOKEN0_PRICE,
								dollarPrice: TOKEN1_PRICE,
								weight: TOKEN1_WEIGHT,
								conversionRate: 1/conversionrate,
								depth: TOKEN1_DEPTH,
								contract: TOKEN1_CONTRACT
							},
						}

						const POOL_NAME = `${TOKEN0_SYMBOL}/${TOKEN1_SYMBOL}`

						// hydrate the pool specific stuff
						_perlinx.state.set(`pools.${POOL_ADDRESS}`, pool, () => {
							_perlinx.__hydratePoolBlocklytics(POOL_ADDRESS)
							_perlinx.__hydrateMyPoolStake(POOL_ADDRESS)
							_perlinx.__hydratePoolRewards(POOL_ADDRESS)
							_perlinx.__hydratePoolVolumes(POOL_ADDRESS , POOL_NAME)
						})
						
						resolve(pool)
						cb(pool)

						
					} catch(e) {
						resolve()
					}
				})
			})
		})
	}),
	__hydratePoolVolumes: (POOL_ADDRESS, POOL_NAME) => { 
		if (["PERL/pxUSD-OCT2020"].indexOf(POOL_NAME) !== -1) {
			POOL_NAME = "PERL/pxUSD"
		}
		_network.state.subscribe('current', network => {
			if(network.name === 'Mainnet'){
				fetch(`${DashboardApi}/stat`)
					.then(r => r.json())
					.then(data => {
						const poolData = data?.pools.find(item => item.name === POOL_NAME)
						_perlinx.state.update(`pools.${POOL_ADDRESS}`, {
							dashboard: {
								volume: poolData?.volume,
							}
						})
					})
			}
		}, true)
		
	}
	,


	__hydratePoolBlocklytics: POOL_ADDRESS => {
		_network.state.subscribe('current', network => {
			if(network.name === 'Mainnet'){
				const key = process.env.REACT_APP_BLOCKLYTICS_API_KEY
				if(key){
					//const endpoint = `https://api.blocklytics.org/pools/v1/exchange/${POOL_ADDRESS}?key=${key}`
					const endpoint = `https://data-api.defipulse.com/api/v1/blocklytics/pools/v1/exchange/${POOL_ADDRESS}?api-key=${key}`
					_perlinx.state.subscribe(
						`pools.${POOL_ADDRESS}`, 
						pool => {
							fetch(endpoint)
								.then(r => r.json())
								.then(blocklytics => {
									
									if(blocklytics.error){
										console.info(`Can't reach blocklytics API: ${endpoint}`)
										return
									}

									_perlinx.state.update(`pools.${POOL_ADDRESS}`, {
										blocklytics: {
											roi: blocklytics.roi,
											volume: blocklytics.usdVolume,
											liquidity: blocklytics.usdLiquidity,
										}
									})
								})
						}
					)
				}
			}
		}, true)
	},

	__hydrateMyPoolStake: POOL_ADDRESS => _account.state.subscribe('address', 
		ACCOUNT_ADDRESS => _perlinx.state.subscribe(`pools.${POOL_ADDRESS}`, 
			async POOL => {
				const MY_UNITS = await POOL.contract.methods.balanceOf(ACCOUNT_ADDRESS).call()
				const MY_SHARE = BN(100).times(MY_UNITS).dividedBy(Number(POOL.totalUnits) !== 0 ? POOL.totalUnits : 1)
				const MY_VALUE = BN(POOL.depth).times(MY_SHARE).times(0.01)

				const stake = {
					units: MY_UNITS,
					share: MY_SHARE,
					value: MY_VALUE,
				}

				_perlinx.state.update(`pools.${POOL_ADDRESS}`, {stake: stake})
			}
		)
	),

	__hydratePoolRewards: POOL_ADDRESS => _account.state.subscribe('address', 
		ACCOUNT_ADDRESS => _perlinx.state.subscribe('contract', 
			REWARDS_CONTRACT => _perlinx.state.subscribe(`pools.${POOL_ADDRESS}`, 
				async POOL => {
					// rewards tokens
					const AVAILABLE = await POOL.contract.methods.balanceOf(ACCOUNT_ADDRESS).call()
					const LOCKED = await REWARDS_CONTRACT.methods.mapMemberPool_Balance(ACCOUNT_ADDRESS, POOL_ADDRESS).call()

					const rewards = {
						available: AVAILABLE,
						locked: LOCKED,
					}

					_perlinx.state.update(`pools.${POOL_ADDRESS}`, {rewards: rewards})
				}
			)
		)
	),

	__hydrateRewardsSummary: () => _network.state.subscribe(
		`current`, 
		network => _account.state.subscribe(
			`address`, 
			address => _perlinx.state.subscribe(
				`contract`, 
				async contract_PERLINX => {
					//const token = network.PERL
					const currentEra = await contract_PERLINX.methods.currentEra().call()
				
					const rewards = await Promise.all(
						range(currentEra).map(async i => {

							const era = i+1
							const locked = await contract_PERLINX.methods.mapMemberPool_Balance(address, network.PERL).call()
							const claim = await contract_PERLINX.methods.mapMemberEraPool_Claim(address, era, network.PERL).call()
							const hasClaimed = await contract_PERLINX.methods.mapMemberEraAsset_hasClaimed(address, era, network.PERL).call()
							const rewardAvailable = await contract_PERLINX.methods.checkClaim(address, era).call()
							const totalPerl = await contract_PERLINX.methods.mapEra_Total(era).call()
							const totalReward = await contract_PERLINX.methods.mapAsset_Rewards(network.PERL).call()
							const open = await contract_PERLINX.methods.eraIsOpen(era).call()

							return {
								era,
								isCurrent: +era === +currentEra,
								locked,
								claim,
								hasClaimed,
								rewardAvailable,
								totalPerl,
								totalReward,
								open
							}
						})
					);

					_perlinx.state.set('rewardsSummary', rewards)
				}
			)
		)
	),

	__hydrateIncentivesSummary: () => _network.state.subscribe(
		`current`, 
		network => _perlinx.state.subscribe(
			`contract`, 
			async contract_PERLINX => {
				const currentEra = await contract_PERLINX.methods.currentEra().call()
				const memberCount = await contract_PERLINX.methods.memberCount().call()
				const PerlContract = new web3.eth.Contract(Token.abi, network.PERL)
				const rewardsAvailable = await PerlContract.methods.balanceOf(network.PERLX).call()
				const mapEra_Rewards = await contract_PERLINX.methods.mapAsset_Rewards(network.PERL).call()

				const eras = await Promise.all(
					range(currentEra).map(async era => {
						const mapEra_Total = await contract_PERLINX.methods.mapEra_Total(era).call()
						const eraIsOpen = await contract_PERLINX.methods.eraIsOpen(era).call()

						return {
							eraNumber: +era+1,
							total: mapEra_Total,
							open: eraIsOpen,
						}
					})
				)


				const fetchTotalPerlStaked = async () => {
					return new Promise((resolve, reject) => {
						// TODO : Use the permanent endpoint when available
						fetch(`${DashboardApi}/stat`)
							.then(r => r.json())
							.then(data => {
								resolve((BN(parseInt(data?.totalPerlStaked)*(10**18))))
							})
							.catch(e => {
								resolve()
							})
						setTimeout(() => {
							resolve()
						}, 7000)	
					})
				}

				const totalPerlStaked = await fetchTotalPerlStaked()
				
				const incentives = {
					currentEra: currentEra,
					memberCount: memberCount,
					totalRewards: eras.reduce((_acc, {total}) => BN(_acc).plus(total), BN(0)).toFixed(),
					totalEras: eras,
					currentEraRewards: mapEra_Rewards,
					rewardsAvailable: rewardsAvailable,
					totalPerlStaked : totalPerlStaked
				}

				_perlinx.state.set('incentivesSummary', incentives)
			}
		)
	),

	__updateOverview: () => {
		_network.state.subscribe('current', network => {
			_perlinx.state.subscribe('pools', async pools => {
				const poolsValues = Object.values(pools)
				
				if(poolsValues.length > 0){
					const perlinXContract = new web3.eth.Contract(PerlinX, network.PERLX)
					const memberCount = await perlinXContract.methods.memberCount().call()

					const overview = {
						count: poolsValues.length,
						volume: poolsValues.reduce((_acc, _p) => _acc + _p?.dashboard?.volume||0, 0),
						users: +memberCount,
						depth: poolsValues.reduce((_acc, _p) => _acc.plus(_p?.depth||BN(0)), BN(0)),
					}

					_perlinx.state.set('overview', overview)
				}
			}, true)
		});
	},

	
	/*
		helper functions
	*/

	fetchAdmins: (cb=()=>{}) => {
		_perlinx.state.subscribe(`contract`, async contract_PERLINX => {
			let adminCount = await contract_PERLINX.methods.adminCount().call()
			const admins = await Promise.all(
				range(adminCount).map(async i => {
					let admin = await contract_PERLINX.methods.arrayAdmins(i).call()
					let isAdmin = await contract_PERLINX.methods.isAdmin(admin).call()
					return isAdmin ? admin : null
				})
			)

			const active = admins.filter(i=>i)

			cb(active)
		})
	},

	fetchSnapshots: (cb=()=>{}) => {
		_perlinx.state.subscribe(`contract`, async contract_PERLINX => {
			let eraCount = await contract_PERLINX.methods.currentEra().call()
			
			const snapshots = await Promise.all(
				range(eraCount).map(async era => {

					//let balance = await contract_PERLINX.methods.mapEraPool_Balance(era).call()
					//let reward = await contract_PERLINX.methods.mapEra_Reward(era).call()
					let total = await contract_PERLINX.methods.mapEra_Total(era).call()

					return {
						//balance,
						reward: 111,
						total
					}
				})
			)

			cb(snapshots)
		})
	},
	
	
	
	/*
		expose perlinx contract methods
	*/
	

	// user functionality
	lock: (address, { amount }, cb=()=>{}) => {
		return _perlinx
			.__call(
				'lock', 
				[
					address, 
					amount
				],
				`Locking PX Tokens`
			)
			.then(() => {
				cb()
				_perlinx.__hydrateOne(address)
			})
	},
	
	unlock: (address, cb=()=>{}) => {
		return _perlinx
			.__call(
				'unlock', 
				[
					address
				],
				'Unlocking PX tokens'
			)
			.then(() => {
				cb()
				_perlinx.__hydrateOne(address)
			})
	},
	
	claim: (era, cb=()=>{}) => _network.state.subscribe(
		'current', 
		network => _perlinx
			.__call(
				'claim', 
				[era, network.PERL],
				'Claiming rewards'
			)
			.then(() => {
				cb()
				_perlinx.__hydrateRewardsSummary()
			})
	),

	
	// admin functionality
	addAdmin: (newAddress=0, cb) => _perlinx.__call('addAdmin', [newAddress], 'Adding Admin', cb),
	transferAdmin: (newAddress=0, cb) => _perlinx.__call('transferAdmin', [newAddress], 'Transferring Admin', cb),
	snapshot: cb => _network.state.subscribe(
		'current', 
		async network => _perlinx.__call('snapshot', [network.PERL], 'Performing Snapshot', cb)
	),
	addRewards: (amount) => _network.state.subscribe(
		'current', 
		async network => {
			_account.state.subscribe(
				`address`, 
				async address => {
					const PerlContract = new web3.eth.Contract(Token.abi, network.PERL)
					
					const notification = _transactions.add({title: 'Adding Incentives'})
					try {
						const transaction = await PerlContract.methods.transfer(network.PERLX, amount).send({from: address})
						notification.success(transaction)
						_perlinx.__hydrate()
					} catch(e) {
						notification.error(e)
					}
				}
			)
		}
	),
	removeRewards: (era, cb) => _network.state.subscribe(
		'current', 
		async network => _perlinx.__call('removeReward', [era, network.PERL], 'Removing Rewards', cb)
	),
	removeAllRewards: (asset, amount, cb) => _network.state.subscribe(
		'current', 
		async network => _perlinx.__call('sweep', [network.PERL, amount], 'Removing All Rewards', cb)
	),
	listPool: async (poolAddress, tokenAddress, poolWeight, cb=()=>{}) => {
		return _perlinx
			.__call(
				'listPool', 
				[
					poolAddress,
					tokenAddress, 
					+poolWeight*100, 
				], 
				`Listing Pool`
			)
			.then(() => {
				cb()
				_perlinx.__hydrate()
			})
	},

	listSynth: async (poolAddress, synthAddress, empAddress, poolWeight=1, cb=()=>{}) => {
		
		console.log(poolAddress, synthAddress, empAddress, poolWeight)
		return _perlinx
			.__call(
				'listSynth', 
				[
					poolAddress,
					synthAddress,
					empAddress,
					+poolWeight*100, 
				], 
				`Listing Synth`
			)
			.then(() => {
				cb()
				_perlinx.__hydrate()
			})
	},

	delistPool: async (poolAddress, cb=()=>{}) => {
		return _perlinx
			.__call(
				'delistPool', 
				[
					poolAddress,
				], 
				`Delisting Pool`
			)
			.then(() => {
				cb()
				_perlinx.__hydrate()
			})
	},

	
	// transact on the perlinx contract
	__call: (method, params, title, cb=()=>{}) => new Promise(async (resolve, reject) => {
		_perlinx.state.subscribe(
			'contract', 
			contract => {
				_transactions.__call(
					contract,
					method,
					params, 
					title,
					() => {
						cb()
						resolve()
					}
				)
			}
		)
	})
}

let _synthetics = {
	state: new State('synthetics', {
		initialState: {
			contract: null,
			emps: {},
			graphQlClient: null,
			status: null
		},
		subscriptionService: subscriptions,
	}),

	init: async () => {
		_synthetics.__initGraphQLCient()
		_synthetics.__hydrate()
	},

	// get an emp contract from address
	contract: address => new web3.eth.Contract(ExpiringMultiParty, address),

	// configure the graph client 
	__initGraphQLCient: () => {
		// check uri is set
		if(!process.env.REACT_APP_THE_GRAPH_ENDPOINT){
			console.error('REACT_APP_THE_GRAPH_ENDPOINT env var is not defined')
			return
		}

		// configure client
		const graphQlClient = new ApolloClient({
			uri: process.env.REACT_APP_THE_GRAPH_ENDPOINT,
			cache: new InMemoryCache(),
			defaultOptions: {
				watchQuery: { fetchPolicy: 'no-cache' },
				query: { fetchPolicy: 'no-cache' }
			}
		});

		// set client in state
		_synthetics.state.set('graphQlClient', graphQlClient)
	},
	
	// hydrate all EMPs
	__hydrate: async () => new Promise(resolve => {
		_perlinx.state.subscribe('contract', async REWARDS_CONTRACT => {
			_synthetics.state.setStatus(Status.PROCESSING)
			const synthCount = await REWARDS_CONTRACT.methods.synthCount().call()
			await Promise.all(
				range(synthCount).map(async i => {
					const synthAddress = await REWARDS_CONTRACT.methods.arraySynths(i).call()
					const empAddress = await REWARDS_CONTRACT.methods.mapSynth_EMP(synthAddress).call()
					await _synthetics.__hydrateOne(empAddress)
				})
			)
			_synthetics.state.setStatus(Status.READY)
		});
	}),

	// hydrate a single EMP based on EMP address
	__hydrateOne: async EMP_ADDRESS => {

		_wallet.state.subscribe('conversionrate', async conversionrate => {
			const EMP_CONTRACT = _synthetics.contract(EMP_ADDRESS)
			const TOKEN1_ADDRESS = await EMP_CONTRACT.methods.tokenCurrency().call()
			
			// methods
			const expirationTimestamp = await EMP_CONTRACT.methods.expirationTimestamp().call()
			const collateralRequirement = await EMP_CONTRACT.methods.collateralRequirement().call()
			const minSponsorTokens = await EMP_CONTRACT.methods.minSponsorTokens().call()
			const cumulativeFeeMultiplier = await EMP_CONTRACT.methods.cumulativeFeeMultiplier().call()
			const rawTotalPositionCollateral = await EMP_CONTRACT.methods.rawTotalPositionCollateral().call()
			const totalTokensOutstanding = await EMP_CONTRACT.methods.totalTokensOutstanding().call()
			const liquidationLiveness = await EMP_CONTRACT.methods.liquidationLiveness().call()
			const withdrawalLiveness = await EMP_CONTRACT.methods.withdrawalLiveness().call()
			// const priceIdentifier = await EMP_CONTRACT.methods.priceIdentifier().call()
			const expiryPrice = await EMP_CONTRACT.methods.expiryPrice().call()
			const contractState = await EMP_CONTRACT.methods.contractState().call()

			// calculated
			const totalCollateralAmount = BN(rawTotalPositionCollateral).times(fromWei(cumulativeFeeMultiplier)).toFixed(0)
			const globalCollateralisationRatio = +totalTokensOutstanding > 0 ? Number(BN(totalCollateralAmount).dividedBy(totalTokensOutstanding)) : 0
			const globalRawCollateralisationRatio = BN(rawTotalPositionCollateral).dividedBy(totalTokensOutstanding).toFixed(0)
			const minCollateral = BN(minSponsorTokens).times((globalCollateralisationRatio)).toFixed(0)

			// subscribe to the pool so we can finish hydrating
			_perlinx.state.onStatus(Status.READY, ({pools}) => {

				const pool = filter(Object.values(pools), ['token1.address', TOKEN1_ADDRESS])[0]
				
				if(pool){
					const collateralPrice = BN(pool.token0.price).times(100)
					let pairTokenRate = (1/ Number(fromWei(pool.token1.spotPrice))) / Number(fromWei(pool.token0.spotPrice))

					if (pool?.token1?.symbol?.indexOf("pxUSD") !== -1) {
						pairTokenRate = 1
					}
					
					const actualGCR = +totalTokensOutstanding > 0 ? ((BN(totalCollateralAmount).times(BN(conversionrate))).dividedBy(BN(totalTokensOutstanding))).dividedBy(pairTokenRate) : 0
					
					const pricedGlobalCollateralisationRatio = BN(globalCollateralisationRatio).dividedBy(collateralPrice).toFixed(0)
					const idealCollateralisationRatio = BN(pricedGlobalCollateralisationRatio).times(collateralPrice)

					const emp = {
						address: EMP_ADDRESS,
						contract: EMP_CONTRACT,
						expirationTimestamp,
						collateralRequirement,
						minSponsorTokens,
						cumulativeFeeMultiplier,
						rawTotalPositionCollateral,
						totalTokensOutstanding,
						totalCollateralAmount,
						// Raw GCR
						globalCollateralisationRatio,
						// Actual GCR
						actualGCR : Number(actualGCR),
						// No more being an raw GCR
						globalRawCollateralisationRatio,
						pricedGlobalCollateralisationRatio,
						idealCollateralisationRatio,
						minCollateral,
						liquidationLiveness,
						withdrawalLiveness,
						pairTokenRate,
						token0: pool.token0,
						token1: pool.token1,
						expiryPrice,
						contractState,
						calculatePosition: (collateral, tokens) => {
							
							// notes:
							// safe/unsafe is based on token/collateral price
							// if prices move, positions will change
							// nothing to do with the GCR

							const cPrice = pool.token0.price
							const tPrice = pool.token1.price

							const cValue = BN(collateral).times(BN(cPrice)).toFixed()
							const tValue = BN(tokens).times(BN(tPrice)).toFixed()
							const currentRatio = cValue / tValue
							
							let sponsorTokenRate = (1/ (fromWei(pool.token1.spotPrice))) / (fromWei(pool.token0.spotPrice))
							if (tPrice?.symbol?.indexOf("pxUSD") !== -1) {
								sponsorTokenRate = 1
							}
							const actualCurrentRatio = Number((BN(collateral).times(conversionrate)).dividedBy(( BN(tokens).times(sponsorTokenRate) )))
							
							const requiredRatio = fromWei(collateralRequirement)
							const safe = actualCurrentRatio > requiredRatio
	
							const expired = new Date().valueOf() > (Number(expirationTimestamp) * 1000)
							const warning = 1.5 > actualCurrentRatio && actualCurrentRatio >= 1.25
							
							return {
								collateralValue: cValue,
								tokenValue: tValue,
								currentRatio,
								requiredRatio,
								safe,
								actualCurrentRatio,
								expired,
								warning
							}
						}
					}

					_synthetics.state.update(`emps.${EMP_ADDRESS}`, emp)
					_synthetics.__hydratePositions(EMP_ADDRESS)
					_synthetics.__hydrateMyPosition(EMP_ADDRESS)
				}
			})
		})

		
	},

	// hydrate EMP positions/liquidations based on EMP address
	__hydratePositions: EMP_ADDRESS => new Promise((resolve, reject) => {
		_synthetics.state.subscribe(`emps.${EMP_ADDRESS}`, async EMP => {
			_synthetics.state.subscribe('graphQlClient', client => {
				const query = UmaAssetQuery(EMP_ADDRESS)

				client
					.query({ query: query })
					.then(async ({data}) => {

						try {
							// dont want to show positions with 0 collateral
							let positions = await Promise.all(
								data?.financialContracts[0]?.positions.map(async position => {
									if(+position?.collateral > 0){
										const collateralization = await EMP.calculatePosition(position?.collateral, position?.tokensOutstanding)
										return {
											...position,
											...collateralization
										}
									}
								})
							)

							positions = positions.filter(p=>p)

							// get all liquidations
							// NOTE: may need to filter also (cannot test until a liquidation is successsful)
							const liquidations = data?.financialContracts[0]?.liquidations
					
							_synthetics.state.update(`emps.${EMP_ADDRESS}`, {
								positions,
								liquidations
							})
						} catch (e) {

						}


					})
			})
		})
	}),

	// hydrate MY EMP position based on EMP address
	__hydrateMyPosition: EMP_ADDRESS => {
		_account.state.subscribe('address', ACCOUNT_ADDRESS => {
			_wallet.state.subscribe('conversionrate', async conversionrate => {
				_synthetics.state.subscribe(`emps.${EMP_ADDRESS}`, async EMP => {
					const collateral = await EMP.contract.methods.getCollateral(ACCOUNT_ADDRESS).call()
					const position = await EMP.contract.methods.positions(ACCOUNT_ADDRESS).call()

					const collateralization = await EMP.calculatePosition(position?.rawCollateral[0], position?.tokensOutstanding[0])
					const availableToRedeem = BN(position?.tokensOutstanding[0]).minus(EMP?.minSponsorTokens).toFixed()

					const myPosition = {
						rawCollateral: position?.rawCollateral[0],
						tokensOutstanding: position?.tokensOutstanding[0],
						withdrawalRequestPassTimestamp: position?.withdrawalRequestPassTimestamp,
						withdrawalRequestAmount: position?.withdrawalRequestAmount[0],
						transferPositionRequestPassTimestamp: position?.transferPositionRequestPassTimestamp,
						collateral: collateral?.rawValue||"0",
						availableToRedeem: availableToRedeem,
						myAddress : ACCOUNT_ADDRESS ,
						...collateralization
					}
					_synthetics.state.update(`emps.${EMP_ADDRESS}`, {myPosition: myPosition})
				})
			})
		})
	},
	
	// get a single sponsor position based on EMP & sponsor address
	getSponsorPosition: (empAddress, sponsorAddress, cb=()=>{}) => {
		return _synthetics.state.subscribe(`emps.${empAddress}`, emp => {
			const sponsorPosition = filter(emp?.positions||[], position =>  position?.sponsor?.id === sponsorAddress);
			if(sponsorPosition.length > 0) {
				cb(sponsorPosition[0])
			}
		}, true)
	},
	
	// get a single liquidation position based on EMP & sponsor address 
	getLiquidationPosition: (empAddress, liquidationAddress, cb=()=>{}) => {
		return _synthetics.state.subscribe(`emps.${empAddress}`, emp => {
			const liquidationPosition = filter(emp?.liquidations||[], liquidation =>  liquidation?.sponsor === liquidationAddress);
			if(liquidationPosition.length > 0) {
				cb(liquidationPosition[0])
			} 
		}, true)
	},

	// get all current liquidations for all EMPS
	ongoingLiquidations: (cb=()=>{}) => {
		return _synthetics.state.subscribe(`emps`, () => {
			_synthetics.state.onStatus(Status.READY, async ({emps}) => {
				
				const empItems = Object.values(emps)
				const items = []

				// itterate emps
				for (let e = 0; e < empItems.length; e++) {
					let emp = empItems[e]
					let liquidations = emp?.liquidations

					if(liquidations?.length){

						// itterate emp liquidations
						for (let l = 0; l < liquidations.length; l++) {
							let liquidation = liquidations[l]

							const position = await emp.calculatePosition(liquidation?.position?.collateral, liquidation?.position?.tokensOutstanding)

							items.push({
								empAddress: emp?.address,
								id: liquidation?.liquidationId,
								liquidator: liquidation?.liquidator?.id,
								sponsor: liquidation?.sponsor?.id,
								expiry: emp?.expirationTimestamp, //todo
								asset: emp?.token1?.symbol,
								tokensLiquidated: liquidation?.tokensLiquidated,
								lockedCollateral: liquidation?.lockedCollateral,
								liquidatedCollateral: liquidation?.liquidatedCollateral,
								position: position,
								status: liquidation?.status
							})
						}
					}
				}

				if(empItems.length){
					cb(items)
				}
			})
		}, true)
	},

	// get all current liquidations for all EMPS
	getLiquidationFromId: async (id) => {
		return new Promise((resolve, reject) => {
			_synthetics.state.subscribe('graphQlClient', client => {
				const query = UmaLiquidationQuery(id)
				client
					.query({ query: query })
					.then(async ({data}) => {
						resolve(data)
					})

			})
			
		})
	},


	/*
		contract methods
	*/
	mint: ({address, collateralAmount, numTokens}) => {
		return _synthetics
			.__call(
				address,
				'create', 
				[
					[collateralAmount],
					[numTokens]
				],
				`Minting Tokens`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},

	increase: ({address, collateralAmount}) => {
		return _synthetics
			.__call(
				address,
				'deposit', 
				[
					[collateralAmount],
				],
				`Increasing Collateral`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},

	fastWithdraw: ({address, numTokens}) => {
		return _synthetics
			.__call(
				address,
				'withdraw', 
				[
					[numTokens],
				],
				`Withdrawing`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},

	slowWithdrawSubmitRequest : ({address, numTokens}) => {
		return _synthetics
			.__call(
				address,
				'requestWithdrawal', 
				[
					[numTokens],
				],
				`Submitting a request`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},
	slowWithdrawCancelWithdrawal : ({address}) => {
		return _synthetics
			.__call(
				address,
				'cancelWithdrawal', 
				[
				],
				`Canceling a request`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},
	slowWithdrawConfirmWithdrawal : ({address}) => {
		return _synthetics
			.__call(
				address,
				'withdrawPassedRequest', 
				[
				],
				`Confirming`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},
	redeem: ({address, numTokens}) => {
		return _synthetics
			.__call(
				address,
				'redeem', 
				[
					[numTokens],
				],
				`Redeeming Collateral`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},
	redeemAll: ({address}) => {
		return _synthetics
			.__call(
				address,
				'settleExpired', 
				[],
				`Redeeming All Collateral`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},

	liquidate: ({address, sponsor, minCollateralPerToken, maxCollateralPerToken, maxTokensToLiquidate, deadline}) => {
		return _synthetics
			.__call(
				address,
				'createLiquidation', 
				[
					sponsor,
					[minCollateralPerToken],
					[maxCollateralPerToken],
					[maxTokensToLiquidate],
					deadline
				],
				`Liquidating Position`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},

	dispute: ({address, liquidationId, sponsor}) => {

		console.log(address, liquidationId, sponsor)
		

		return _synthetics
			.__call(
				address,
				'dispute', 
				[
					liquidationId,
					sponsor
				],
				`Disputing Liquidation`
			)
			.then(() => {
				_wallet.__hydrate()
				_synthetics.__hydrateOne(address)
			})
	},

	__call: (address, method, params, title) => new Promise(async resolve => {
		const contract = await _synthetics.contract(address)

		_transactions.__call(
			contract,
			method,
			params, 
			title,
			() => {
				resolve()
			}
		)
	})
}

let _emp = {
	state: new State('synthetics', {
		initialState: {
			creatorContract: null
		},
		subscriptionService: subscriptions,
	}),

	init: async () => {
		_network.state.subscribe(`current`, ({EMP_CREATOR}) => {
			const creatorContract = new web3.eth.Contract(ExpiringMultiPartyCreator, EMP_CREATOR)
			_emp.state.set('creatorContract', creatorContract)
		})

		// _account.state.subscribe('address', async ACCOUNT_ADDRESS => {
			
		// 	try {
		// 		const emp = new web3.eth.Contract(ExpiringMultiParty, '0x77276F4CF5Af5fed9b6C8b02724aB6Bb1F3471fd')
		// 		const result = await emp.methods.create(["21000000000000000000"], ["1000000000000000000"]).send({ from: ACCOUNT_ADDRESS , gasLimit: '0x7A120'})
		// 	} catch(e) {
		// 		// statements
		// 		console.log(e);
		// 	}
			
		// })
	},

	create: (params) => {
		return _emp
			.__call(
				'createExpiringMultiParty', 
				params,
				`Creating EMP`
			)
			.then(() => {
				//_synthetics.__hydrateOne(address)
			})
	},

	__call: (method, params, title) => new Promise((resolve, reject) => {
		_account.state.subscribe(`address`, async ACCOUNT_ADDRESS => {
			_emp.state.subscribe(`creatorContract`, async contract => {
				const tx = _transactions.add({title: title})
				try {
					const tx = contract.methods[method](params)
					await tx.call({ from: ACCOUNT_ADDRESS })
					const transaction = await tx.send({ from: ACCOUNT_ADDRESS })
					tx.success(transaction)
					resolve(transaction)
				} catch(e) {
					tx.error(e)
					reject(e.message)
				}
			})
		})
	})
}

let _transactions = {
	state: new State('transactions', {
		initialState: {
			items: {},
		},
		subscriptionService: subscriptions,
	}),

	init: () => {},

	add: (props={}) => {
		const id = uuidv4()
		const title = props?.title ? props.title : 'Your transaction is being processed...'
		const duration = props?.duration ? props.duration : -1

		const notification = Notification.processing({
			title: title,
			duration: duration,
		})

		const tx = {
			status: 'pending',
			title: title,
			notification: notification,
		}

		_transactions.state.set(`items.${id}`, tx)

		return {
			success: (data, props) => _transactions.success(id, data, props),
			error: (data, props) => _transactions.error(id, data, props)
		}
	},

	success: (id, data, props={}) => {
		const title = props?.title||'Your transaction was successful'
		const duration = props?.duration||4000
		const tx = _transactions.state.items[id]

		tx.notification.success({
			title: title,
			links: [
				{
					text: 'View on Etherscan',
					to: `https://etherscan.io/tx/${data.transactionHash}`,
					target: '_blank',
					rel: "noopener noreferrer",
				}
			],
			duration: duration
		})

		_transactions.state.update(`items.${id}`, {
			title: title,
			hash: data.transactionHash,
			status: 'success',
		})
	},

	error: (id, data, props={}) => {
		const tx = _transactions.state.items[id]
		const userRejectionMessage = 'MetaMask Tx Signature: User denied transaction signature.' 
		const isUserRejection = data.message === userRejectionMessage
		const title = props?.title||(isUserRejection ? 'Transaction rejected by user' : 'There was an error with your transaction')
		const status = isUserRejection ? 'warning' : 'error'
		const info = data.message
		const duration = props?.duration||4000

		tx.notification[status]({
			title: title,
			links: data?.transactionHash
				? [
					{
						text: 'View on Etherscan',
						to: `https://etherscan.io/tx/${data.transactionHash}`,
						target: '_blank',
						rel: "noopener noreferrer",
					}
				]
				: null,
			duration: duration
		})
		
		_transactions.state.update(`items.${id}`, {
			title: title,
			text: info,
			status: status,
		})
	},

	__call: (contract, method, params, title, cb=()=>{}) => new Promise(async (resolve, reject) => {
		//console.log(contract, method, ...params)
		_account.state.subscribe(`address`, async ACCOUNT_ADDRESS => {
			const tx = _transactions.add({title: title})
			try {
				const web3Tx = contract.methods[method](...params)
				await web3Tx.call({ from: ACCOUNT_ADDRESS })
				const transaction = await web3Tx.send({ from: ACCOUNT_ADDRESS })
				tx.success(transaction)
				resolve(transaction)
				cb()
			} catch(e) {
				tx.error(e)
				reject(e.message)
			}
		})
	})
}


// // subscribe to new eth blocks
// // each block will trigger all callbacks
// // note: no longer inuse - favoring a pub/sub system 
// let eth = {
// 	watch: false,
// 	initWatching: () => {
// 		eth.watch && web3.eth
// 			.subscribe('newBlockHeaders')
// 			.on("data", data => eth.block.subscriptions.trigger(data))
// 			.on("error", e => subscriptions.trigger('eth.watch.error'));
// 	},
// 	block: {
// 		subscriptions: {
// 			callbacks: {}, 
// 			add: (type, cb=()=>{}, once=false) => {
// 				const id = uuidv4()
// 				const path = `block.subscriptions.callbacks.${id}`
// 				const data = {
// 					cb: cb,
// 					once: once,
// 					unsubscribe: () => unset(eth, path)
// 				}
// 				set(eth, path, data)
// 				return data
// 			},
// 			trigger: (type, payload) => {
// 				const path = `block.subscriptions.callbacks`
// 				const _cbs = get(eth, path, {})
				
// 				Object.keys(_cbs).forEach(id => {
// 					const { cb, once } = _cbs[id]
// 					typeof cb === "function" && cb(payload)
// 					if(once === true) unset(eth, `${path}.${id}`)
// 				})
// 			}
// 		}
// 	},
// }



// ----- init


const _configure = async ({config, onReady=()=>{}, onError=()=>{}, onStatus=()=>{}, ...props}) => {
	// check we have ethereum
	ethereum = window?.ethereum
	if(!ethereum){
		onStatus(
			'network', 
			true, 
			{
				message: `You need to install Metamask.`,
				links: [{
					text: 'Install now',
					to: 'https://metamask.io/',
					target: '_blank', 
					rel: "noreferrer noopener"
				}]
			}
		);
		return;
	}
	
	// no autorefesh 
	ethereum.autoRefreshOnNetworkChange = false 
	
	// set Web3
	web3 = new Web3(ethereum)
	
	// check we have accounts
	// const accounts = await web3.eth.getAccounts()
	// if(!accounts.length){
	// 	onStatus(
	// 		'account', 
	// 		true, 
	// 		{
	// 			message: `You are not signed in to Metamask.`,
	// 			links: [{
	// 				text: 'Sign-in now',
	// 				onClick: () => ethereum.enable(),
	// 			}]
	// 		}
	// 	);
	// 	return
	// }
	
	_account.init(props?.account)
	_wallet.init(props?.wallet)
	_tokens.init(props?.tokens)
	_perlinx.init(props?.perlinx)
	_balancer.init(props?.balancer)
	_synthetics.init(props?.synthetics)
	_emp.init(props?.emp)

	_network.init(props?.network)
	onReady()
}


export default {
	configure: _configure,
	network: _network,
	account: _account,
	subscribe: subscriptions.subscribe,
	tokens: _tokens,
	transactions: _transactions,
	perlinx: _perlinx,
	wallet: _wallet,
	balancer: _balancer,
	synthetics: _synthetics,
	emp: _emp,
}


// notes: this is a POC version with moderate/tight module coupling
// it's recommended that this system is enhanced in future iterations
//
// thoughts about potential future updates are:
// -----
// 1. convert into singleton/module-based architecture ✔✘ (partial)
// 2. abstract out all smart contract interactions into stand alone module ✔✘ (partial)
// 3. allow all data pub/sub based ✔✘ (partial)
// 4. get nested subscriptions working 100% ✔
//		- should be able to watch nested state object/s for changes ✔
// 		- callback should fire child subscription + any parent subscription ✔
// 		- eg: setting 'item.a.b' should trigger 'item.a.b', 'item.a' & 'item' subscriptions ✔ (✘ only parent, not grandparent, by design)
// 5. _wallet/_tokens/_pools are extentions of each other (classes) ✘
// 6. class based architecture would work well in point 5 above? ✘
// 7. remove dependency on promises ✔✘ (partial)