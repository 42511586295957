import React from 'react';
import { Layout } from '@components'
import { /*Asset,*/ Account } from '@archetypes'

export default ({className}) => <Layout>
	<Layout.Main>
		<Layout.Section title='Pools joined'>
			<Account.Pools/>
		</Layout.Section>
		<Layout.Section title='Assets minted'>
			<Account.CDPs/>
		</Layout.Section>
	</Layout.Main>
	<Layout.Aside>
		{/*<Layout.Section title='Manage your assets'>
			<Asset.Button.Group/>
		</Layout.Section>*/}
		<Layout.Section title='Wallet balance'>
			<Account.Balance/>
		</Layout.Section>
	</Layout.Aside>
</Layout>