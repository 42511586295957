import { fromWei, BN } from '@util/helpers'
import SubsAndEventsClass from '@util/SubsAndEventsClass.js'
import { calcOutGivenIn } from '@util/balancerCalcs.ts'

export class SwapController extends SubsAndEventsClass{
	_POOL_ADDRESS
	_ACTION = 'buy'
	_PERLINX
	_WALLET
	
	INPUT_PERCENT = 0
	INPUT_AMOUNT = 0
	POOL = {}
	TOKEN_SELL
	TOKEN_BUY
	BALANCE = 0
	FROM_AMOUNT = 0
	PROVIDER_FEE = 0
	SLIPPAGE = 0
	
	constructor(poolAddress, action, {perlinx, wallet}){
		super()
		this._POOL_ADDRESS = poolAddress
		this._ACTION = action
		this._PERLINX = perlinx
		this._WALLET = wallet
	}

	init(){
		const poolSub = this._PERLINX.state.subscribe(`pools.${this._POOL_ADDRESS}`, async pool => {
			
			this.POOL = pool
			this.TOKEN_SELL = this._ACTION === 'buy' ? pool.token0 : pool.token1
			this.TOKEN_BUY = this._ACTION === 'buy' ? pool.token1 : pool.token0

			// get wallet balance
			const walletSub = this._WALLET.state.subscribe(`balances.${this.TOKEN_SELL.symbol}`, ({balance}) => {
				this.BALANCE = balance
				this._update()
			}, true)
			this._SUBSCRIPTIONS.push(walletSub)

			this._calculateApprovals()

			this.updateInputPercent(0)
		})

		this._SUBSCRIPTIONS.push(poolSub)
	}

	updateInputPercent(percent=0){
		percent = !percent || isNaN(percent) ? 0 : percent
		this.INPUT_PERCENT = percent
		this._update()
	}
	

	// private
	_update(){
		if(!this.INPUT_PERCENT < 0 || !this.TOKEN_SELL || !this.TOKEN_BUY || !this.BALANCE) return

		this.FROM_AMOUNT = BN(this.BALANCE).times(this.INPUT_PERCENT)
		this.TO_AMOUNT = this._calculateToAmount(this.FROM_AMOUNT)
		this.SLIPPAGE = this._calculateSlippage(this.FROM_AMOUNT)
		this.PROVIDER_FEE = this.FROM_AMOUNT.times(fromWei(this.POOL?.swapFee)).toFixed(0)

		this.dispatchEvent('update', {	
			balance: this.BALANCE,
			fromAmount: this.FROM_AMOUNT.toFixed(0),
			toAmount: this.TO_AMOUNT,
			providerFee: this.PROVIDER_FEE,
			slippage: this.SLIPPAGE,
			tokenSell: this.TOKEN_SELL,
			tokenBuy: this.TOKEN_BUY,
			poolAddress: this._POOL_ADDRESS,
			tx: {
				sellAddress: this.TOKEN_SELL.address,
				amount: this.FROM_AMOUNT.toFixed(0),
				buyAddress: this.TOKEN_BUY.address 
			}
		});
	}

	_calculateApprovals(address){
		const approvals = [
			{
				name: this.TOKEN_SELL.symbol,
				toApprove: this.POOL.address,
				onContract: this.TOKEN_SELL.address
			},
			{
				name: this.TOKEN_BUY.symbol,
				toApprove: this.POOL.address,
				onContract: this.TOKEN_BUY.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}

	_calculateToAmount(fromAmount){
		return calcOutGivenIn(
		 	BN(this.TOKEN_SELL.depth),
			BN(this.TOKEN_SELL.weight),
			BN(this.TOKEN_BUY.depth),
			BN(this.TOKEN_BUY.weight),
			fromAmount,
			BN(this.POOL.swapFee)
		).toFixed(0)
	}

	_calculateSlippage(amount){
		// FYI: https://github.com/balancer-labs/balancer-exchange/blob/995187e6bd3a5057d2c0c13fdde4d5df730d80da/src/utils/sorWrapper.ts#L219
		const slippage = 
			(
				BN(amount)
				.minus(fromWei(this.POOL.swapFee))
				.times(
					BN(fromWei(this.TOKEN_SELL.weight))
					.plus(fromWei(this.TOKEN_BUY.weight))
				)
			)
			.dividedBy(
				BN(2)
				.times(fromWei(this.TOKEN_SELL.balance))
				.times(fromWei(this.TOKEN_BUY.weight))
				
			).multipliedBy(100)

	
		return +slippage <= 0 ? '0' : slippage
	}
}

export class StakeController extends SubsAndEventsClass{
	_POOL_ADDRESS
	_PERLINX
	_WALLET

	INPUT_PERCENT = 0

	POOL = {}
	TOKEN0
	TOKEN1
	BALANCE = 0
	FROM_AMOUNT = 0
	TO_AMOUNT = 0
	EST_UNITS = 0
	EST_SHARE = 0
	EST_VALUE = 0
	TX = {}
	
	constructor(poolAddress, {perlinx, wallet}){
		super();
		this._POOL_ADDRESS = poolAddress
		this._PERLINX = perlinx
		this._WALLET = wallet
	}

	init(){
		const poolSub = this._PERLINX.state.subscribe(`pools.${this._POOL_ADDRESS}`, async pool => {
			this.POOL = pool
			this.TOKEN0 = pool.token0
			this.TOKEN1 = pool.token1

			// get wallet balance
			const t0Bal = this._WALLET.state.subscribe(`balances.${this.TOKEN0.symbol}`, ({balance}) => {
				this.TOKEN0_BALANCE = balance
				this._update()
			}, true)
			this._SUBSCRIPTIONS.push(t0Bal)

			const t1Bal = this._WALLET.state.subscribe(`balances.${this.TOKEN1.symbol}`, ({balance}) => {
				this.TOKEN1_BALANCE = balance
				this._update()
			}, true)
			this._SUBSCRIPTIONS.push(t1Bal)

			

			this._calculateApprovals()
			this.updateInputPercent(0)
		})

		this._SUBSCRIPTIONS.push(poolSub)
	}

	updateInputPercent(percent=0){
		percent = !percent || isNaN(percent) ? 0 : percent
		this.INPUT_PERCENT = percent
		this._update()
	}
	
	// private
	_update(){
		if(!this.INPUT_PERCENT < 0 || !this.TOKEN0 || !this.TOKEN1 || !this.TOKEN0_BALANCE || !this.TOKEN1_BALANCE) return

		this.FROM_AMOUNT = BN(this.TOKEN0_BALANCE).times(this.INPUT_PERCENT).toFixed(0)
		this.TO_AMOUNT = 1 / fromWei(this.POOL.token1.priceSansFee) * fromWei(this.FROM_AMOUNT)
		if (this.TOKEN0.weight !== this.TOKEN1.weight) {
			this.TO_AMOUNT = (Number(fromWei(this.TOKEN1.weight)) * this.TO_AMOUNT) /Number(fromWei(this.TOKEN0.weight))
		}
		this.EST = this._calculateEst()
		this.TX = this._calculateTX()

		this.dispatchEvent('update', {	
			poolAddress: this._POOL_ADDRESS,
			token0Balance: this.TOKEN0_BALANCE,
			token1Balance: this.TOKEN1_BALANCE,
			fromAmount: this.FROM_AMOUNT,
			toAmount: this.TO_AMOUNT,
			token0: this.TOKEN0,
			token1: this.TOKEN1,
			est: this.EST,
			tx: this.TX
		});
	}

	_calculateEst(){
		const t1Val = +fromWei(this.FROM_AMOUNT) * this.POOL.token0.price
		const t2Val = this.TO_AMOUNT * this.POOL.token1.dollarPrice
		const swapMultiplier = (1 - +fromWei(this.POOL.swapFee))
		const tokenCost = +fromWei(this.POOL.depth.toFixed(0)) / +fromWei(this.POOL.totalUnits)
		// let estimatedValue = 0
		// if (this.POOL.token0.weight === this.POOL.token1.weight) {
		// 	estimatedValue = (t1Val + t2Val) * swapMultiplier
		// } else {
		// 	// Non 50/50 pool
		// 	// estimatedValue = (BN(t1Val).times(BN(fromWei(this.POOL.token0.weight))).dividedBy(0.5)).plus(BN(t2Val).times(BN(fromWei(this.POOL.token1.weight))).dividedBy(0.5) ).times(swapMultiplier)
		// 	// estimatedValue = Number(estimatedValue.dividedBy(2))
		// 	estimatedValue = (t1Val + t2Val) * swapMultiplier
		// }

		const estimatedValue = (t1Val + t2Val) * swapMultiplier
		const estimatedUnits = estimatedValue / tokenCost
		const estimatedShare = 100 / (+fromWei(this.POOL.depth.toFixed(0)) + estimatedUnits) * estimatedValue

		return {
			units: estimatedUnits,
			share: estimatedShare,
			value: estimatedValue,
		}
	}

	_calculateTX(){
		if (this.POOL.token0.weight === this.POOL.token1.weight) {
			const perlIWantToStake = BN(this.FROM_AMOUNT)
			const totalLPTokens = BN(this.POOL.totalSupply)
			const token0Depth = BN(this.POOL.token0.depth)
			const token1Depth = BN(this.POOL.token1.depth)
			const stakeMultiplier = BN(1.1)
			const stakePercentage = BN(1)
				.dividedBy(fromWei(token0Depth.toFixed(0)))
				.times(fromWei(perlIWantToStake.toFixed(0)))

			return {
				amountOut: BN(stakePercentage).times(totalLPTokens).toFixed(0),
				token0: BN(perlIWantToStake).times(stakeMultiplier).toFixed(0),
				token1: BN(stakePercentage).times(token1Depth).times(stakeMultiplier).toFixed(0),
			}
		} else {
			//  Non 50/50 pool
			const perlIWantToStake = BN(this.FROM_AMOUNT)
			const totalLPTokens = BN(this.POOL.totalSupply)
			const token0Depth = BN(this.POOL.token0.depth)
			const token1Depth = BN(this.POOL.token1.depth)
			// FIXME: Can't set multiplier as x1-x10 by unknown reason, however the tokens will be deducted around x1
			const stakeMultiplier = BN(10)
			const stakePercentage = BN(1)
				.dividedBy(fromWei(token0Depth.toFixed(0)))
				.times(fromWei(perlIWantToStake.toFixed(0)))
			const pairTokenAmount = BN(stakePercentage).times(token1Depth).times(stakeMultiplier)
			return {
				amountOut: BN(stakePercentage).times(totalLPTokens).toFixed(0),
				token0: BN(perlIWantToStake).times(stakeMultiplier).toFixed(0),
				token1: pairTokenAmount.toFixed(0),
			}
		}
		
	}

	_calculateApprovals(address){
		const approvals = [
			{
				name: this.TOKEN0.symbol,
				toApprove: this.POOL.address,
				onContract: this.TOKEN0.address
			},
			{
				name: this.TOKEN1.symbol,
				toApprove: this.POOL.address,
				onContract: this.TOKEN1.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}
}

export class WithdrawController extends SubsAndEventsClass{
	_POOL_ADDRESS
	_PERLINX
	_MY_ADDRESS

	INPUT_PERCENT = 0
	POOL = {}
	TOKEN0
	TOKEN1
	FROM_AMOUNT = 0
	TO_AMOUNT = 0
	EST_UNITS = 0
	EST_SHARE = 0
	EST_VALUE = 0
	TX = {}
	
	constructor(poolAddress, {wallet, account, perlinx}){
		super();
		this._POOL_ADDRESS = poolAddress
		this._PERLINX = perlinx
		account.state.subscribe('address', () => this._MY_ADDRESS = account.state.address)
	}

	init(){
		const poolSub = this._PERLINX.state.subscribe(`pools.${this._POOL_ADDRESS}`, async pool => {

			this.POOL = pool
			this.TOKEN0 = pool.token0
			this.TOKEN1 = pool.token1

			this._calculateApprovals()
			this.updateInputPercent(0)
		}, true)

		this._SUBSCRIPTIONS.push(poolSub)
	}

	updateInputPercent(percent=0){
		percent = !percent || isNaN(percent) ? 0 : percent
		this.WITHDRAW_PERCENT = percent
		this._update()
	}
	

	// private
	async _update(){
		if(!this.WITHDRAW_PERCENT < 0 || !this._MY_ADDRESS || !this.TOKEN0 || !this.TOKEN1 || !this.POOL.stake) return

		this.WITHDRAW_UNITS = BN(this.POOL.stake.units).times(this.WITHDRAW_PERCENT)
		this.WITHDRAW_SHARE = BN(this.POOL.stake.share).times(this.WITHDRAW_PERCENT)
		this.WITHDRAW_VALUE = BN(this.POOL.stake.value).times(this.WITHDRAW_PERCENT)

		this.dispatchEvent('update', {	
			address: this._POOL_ADDRESS,
			totalUnits: this.POOL.totalUnits,
			myUnits: this.POOL.stake.units,
			token0: this.TOKEN0,
			token1: this.TOKEN1,
			withdraw:{
				units: this.WITHDRAW_UNITS,
				share: this.WITHDRAW_SHARE,
				value: this.WITHDRAW_VALUE,
			},
			tx: {
				units: this.WITHDRAW_UNITS.toFixed(0)
			}
		});
	}

	_calculateApprovals(address){
		const approvals = [
			{
				name: this.TOKEN0.symbol,
				toApprove: this.POOL.address,
				onContract: this.TOKEN0.address
			},
			{
				name: this.TOKEN1.symbol,
				toApprove: this.POOL.address,
				onContract: this.TOKEN1.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}
}