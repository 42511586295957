import React from 'react'
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'
import { ReactComponent as IconChevronLeft } from '@assets/Icon_Chevron_Left.svg';
import { ReactComponent as IconChevronRight } from '@assets/Icon_Chevron_Right.svg';

const defaultOptions = {
	pageSize: 10,
	pageIndex: 0,
}

const Pagination = styled(
	({previousPage, nextPage, canPreviousPage, canNextPage, gotoPage, pageIndex, pageOptions, className}) => <div className={`pagination ${className}`}>
		<IconChevronLeft className={'-prev'} onClick={() => previousPage()} disabled={!canPreviousPage}/>
		{pageOptions.map((pageNbr, i) => <span className={'-page'} data-active={pageIndex === i} onClick={() => gotoPage(i)}>{pageNbr + 1}</span> )}
		<IconChevronRight className={'-next'} onClick={() => nextPage()} disabled={!canNextPage}/>
	</div>
	)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 1.4rem 0 -0.8rem 0;
	user-select: none;

	>*{
		cursor: pointer;
		font-size: var(--table--pagination--font-size, 14px);
		transition: all 0.2s;
	}
	
	.-page{
		color: var(--table--pagination--color, white);
		opacity: 0.5;
		line-height: 2em;
		width: 1.7em;
		text-align:center;

		&[data-active="true"]{
			opacity: 1;
			color: var(--table--pagination--color-active, white);
		}
	}

	.-prev,
	.-next{
		width: var(--table--pagination--icon-size, 20px);
		height: var(--table--pagination--icon-size, 20px);
		color: var(--table--pagination--color, white);
		
		path{ fill: currentColor }

		&:hover{ opacity: 1 }

		&[disabled]{
			opacity: 0.5;
			pointer-events: none;
		}
	}
	
	.-prev{ margin-right: 0.8rem }
	.-next{ margin-left: 0.8rem }

	`

const TableWrapper  = styled(
	({ children, className }) => {
		// Render the UI for your table
		return <div className={`table ${className}`}>
			{children}
		</div>
	})`

	>table{
		border-collapse: collapse; 
		border-spacing: 0;
		border: none;
		font-size: var(--font-size-normal);
		width: 100%;
		text-align: left;

		thead{
			font-size: var(--font-size-xxsmall);
			color: var(--color-light);

			th {
				padding: 0 0 1rem 0;
				font-weight: 400;
				line-height: 1.6rem;
				border-bottom: 1px solid rgba(255,255,255,0.1);
				vertical-align: bottom;
			}
		}

		tbody{
			font-size: var(--font-size-normal);
			color: var(--color-ultralight);

			tr[data-disabled='true']{
				opacity: 0.5;
				pointer-events: none;
			}
			
			td {
				padding: 2.4rem 0;
				border-bottom: 1px solid rgba(255,255,255,0.1);

				&:last-child{
					//text-align: right;
					//>*{display: inline-block}
				}
			}
		}

		thead th,
		tbody td{
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			
			&:first-child{ 
				padding-left: 0;
				//width: 10%;
				white-space: nowrap;
			}
			
			& + th:last-child,
			& + td:last-child{
				text-align: right;
				padding-right: 0;
				//width: 10%;
				white-space: nowrap;

				>*{
					//display: inline-block;
				}
			}
		}
	}
	`

const Table = ({columns, data=[], options={}, children, className}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
	    canNextPage,
	    pageOptions,
	    pageCount,
	    gotoPage,
	    nextPage,
	    previousPage,
	    state: { pageIndex }
	} = useTable({ 
		columns, 
		data, 
		initialState: { 
			...defaultOptions,
			...options
		}
	}, usePagination)

	// Render the UI for your table
	return <TableWrapper className={`-controlled ${className}`}>
		<table 
			{...getTableProps()}
			>
			<thead>
				{
					headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)}
						</tr>
					))
				}
			</thead>
			<tbody {...getTableBodyProps()}>
				{children
					? children
					: page.map((row, i) => {
						prepareRow(row)
						return <tr {...row.getRowProps()}>
							{
								row.cells.map(cell => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
							}
						</tr>
					})
				}
			</tbody>
		</table>
		{pageCount > 1 && 
			<Pagination
				previousPage={previousPage}
				nextPage={nextPage}
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				pageIndex={pageIndex}
				pageOptions={pageOptions}
				gotoPage={gotoPage}
			/>
		}
	</TableWrapper>
}

Table.Uncontrolled = ({columns, children, className}) => {
	return <TableWrapper className={`-uncontrolled ${className}`}>
		<table>
			<thead>
				<tr>
					{columns.map((column, i) => <th key={i}>{column.Header}</th>)}
				</tr>
			</thead>
			<tbody>
				{children}
			</tbody>
		</table>
	</TableWrapper>
}

Table.Uncontrolled.Row = ({fields=[], disabled, className}) => <tr 
	className={`table-row ${className}`} 
	data-disabled={disabled}
	>
	{Object.values(fields).map((val, i) => <td key={i}>{val}</td>)}
</tr>


export default Table