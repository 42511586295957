import React, { useState, useEffect, Fragment } from 'react';
import { Amount, Grid, Panel, Stat, GateKeeper, LazyBoi } from '@components'
import { Asset } from '@archetypes'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import assetController, { Status } from '@app/App.Controller'

import { 
	WithdrawController, 
} from './_controller'

export default ({address}) => {
	const { balancer} = assetController

	// approval params
	const [ approvalStatus, setApprovalStatus ] = useState()
	const [ approvalParams, setApprovalParams ] = useState()
	const [ controller, setController ] = useState()
	const [ withdrawParams, setWithdrawParams ] = useState()

	useEffect(() => {
		const withdrawController = new WithdrawController(address, assetController)
		withdrawController.on('approvals', setApprovalParams)
		withdrawController.on('update', setWithdrawParams)
		setController(withdrawController)
		withdrawController.init()
		return () => withdrawController.cleanup()
	}, []) // eslint-disable-line

	return <Panel>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to start staking',
				}
			]}
			assertions={[
				{
					assert: () => !!withdrawParams,
					onFail: () => <Fragment><Refresh className='rotate'/> Fetching account info</Fragment>,
				},
				{
					assert: () => withdrawParams?.myUnits > 0,
					onFail: () => <Fragment>No assets in pool</Fragment>,
				}
			]}
			dependencies={[withdrawParams]}
			>
			
			<Amount.Presets 
				title={`Select Amount`}
				onChange={pc => controller?.updateInputPercent(pc)}
			/>

			<Panel.Divider>
				<Asset.ContractApproval
					approvals={approvalParams}
					onUpdate={setApprovalStatus}
				/>
			</Panel.Divider>

			<Panel.Footer>
				<Panel.Footer.Group>
					<Grid cols={3}>
						<Stat title='Pool Units' small>
							<LazyBoi value={withdrawParams?.withdraw?.units} fromWei/>
						</Stat>
						<Stat title='Pool Share' small>
							<LazyBoi.Percent value={withdrawParams?.withdraw?.share}/>
						</Stat>
						<Stat title='Pool Value' small>
							<LazyBoi.Currency value={withdrawParams?.withdraw?.value} fromWei/>
						</Stat>
					</Grid>
				</Panel.Footer.Group>

				<Panel.Footer.Group>
					<Asset.ContractApproval.Button
						{...approvalStatus}
						onClick={() => {
							balancer.unstake(withdrawParams.address, withdrawParams.tx)
						}}
						>
						Withdraw from pool
					</Asset.ContractApproval.Button>
				</Panel.Footer.Group>
			</Panel.Footer>
		</GateKeeper>
	</Panel>
}