import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { Button, Table, Panel, Tabbed, Layout, Grid, LazyBoi, GateKeeper, Tag } from '@components'
import {InfoCircleFilled} from '@ant-design/icons';
import { Asset } from '@archetypes'
import appController from '@app/App.Controller'
import { ReactComponent as IconCancel } from '@assets/Icon_Cancel.svg';
import { truncateEthAddress, formatTimestamp } from '@util/helpers'
import { ReactComponent as Minus } from '@assets/Icon_Minus.svg';
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import Tooltip from '../../components/common/Tooltip/Tooltip';
const columns = [
	// {
	// 	Header: 'Holder',
	// 	accessor: 'holder',
	// },
	{
		Header: 'Owner',
		accessor: 'owner',
	},
	{
		Header: 'Expiry',
		accessor: 'expiry',
	},
	{
		Header: 'Collateral Size',
		accessor: 'collateral',
	},
	{
		Header: <Tooltip text="Partially liquidated positions are deducted from here">Amount Owing <InfoCircleFilled /></Tooltip>,
		accessor: 'owing',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	// {
	// 	Header: '',
	// 	accessor: 'actions',
	// }
]



const TableRow = (props) => {
	const { address, sponsor, collateral , tokensOutstanding, safe, expiry, expired, warning } = props;
	return <Table.Uncontrolled.Row
		key={sponsor.id}
		fields={{
			owner: truncateEthAddress(sponsor?.id, 8, 3),
			expiry: formatTimestamp(expiry),
			collateral: <LazyBoi value={collateral} dp={2}/>,
			owing: <LazyBoi value={tokensOutstanding} dp={2}/>, 
			status: <Asset.Position.Tag safe={safe} isExpired={expired} isWarning={warning} />,
			// actions: <Button small icon={<Minus/>} to={`/assets/liquidate/${address}/${sponsor.id}`}>Liquidate</Button>
		}}
	/>
}
	


const LiquidationAlertColumns = [
	{
		Header: 'Liquidator',
		accessor: 'liquidator',
	},
	{
		Header: 'Amount to Liquidate',
		accessor: 'amountToLiquidate',
	},
	{
		Header: 'Elapsed time since liquidation',
		accessor: 'elapsedTimeSinceLiquidation',
	},
	{
		Header: "Elapsed time since dispute",
		accessor : "elapsedTimeSinceDispute"
	},
	{
		Header: "Status",
		accessor : "status"
	},
	{
		Header: '',
		accessor: 'actions',
	}
]

const LiquidationAlertTableRow = ({ id, liquidator, tokensLiquidated , status,  elapses, handleDispute, liquidationLiveness}) => {

	const getStatusTag = (status) => {
		switch (status) {
			case "PreDispute":
				return <Tag>ONGOING</Tag>
			case "PendingDispute":
				return <Tag.Orange>DISPUTED</Tag.Orange>
			case "DisputeSucceeded":
				return <Tag.Success>DISPUTE SETTLED</Tag.Success>
			case "DisputeFailed":
				return <Tag.Danger>DISPUTE SETTLED</Tag.Danger>
			case "Completed":
				return <Tag.Neutral>COMPLETED</Tag.Neutral>
			default:
				return <Tag.Neutral>NONE</Tag.Neutral>
		}
	}

	return <Table.Uncontrolled.Row
				key={id}
				fields={{
					liquidator: truncateEthAddress(liquidator.address, 8, 3),
					amountToLiquidate: <LazyBoi value={tokensLiquidated} dp={2}/> ,
					elapsedTimeSinceLiquidation : <LazyBoi value={`${elapses?.LiquidationCreatedEvent || "-"}`} />,
					elapsedTimeSinceDispute :  <LazyBoi value={`${elapses?.LiquidationDisputedEvent || "-"}`} />, 
					status: getStatusTag(status),
					actions: <Button
								disabled={status === 'PendingDispute' || status === "Completed"}
								small 
								icon={<IconCancel/>}
								onClick={() => handleDispute(id)}
								>
									Dispute
							</Button>
				}}
			/>
}
	

const PanelsWrapper = styled.div`
	.alert {
		background-color: rgba(255,122,101,0.1); 
	}
`


const LiquidationAlert = ({emp, synthetics}) => {

	const [ alerts, setAlerts ] = useState([])

	const handleDispute = useCallback((liquidationId) => {
		const submission = (emp.liquidations.find(item => item.id === liquidationId))
		const data = {
			liquidationId : submission?.liquidationId,
			address : emp.address,
			sponsor: submission?.sponsor?.id
		}
		synthetics.dispute(data)
	},[synthetics, emp])

	useEffect(() => {
		if (emp.length !== 0) {
			if (emp?.myPosition?.myAddress) {

				const getAlert = async (id) => {
					let { liquidation } = await synthetics.getLiquidationFromId(id)
					let elapses = {}
					for (let event of liquidation.events) {
						const timestamp = Number(event.timestamp) * 1000
						const startTime = new Date(timestamp)

						const endTime = Date.now();
						const totalSeconds = (endTime -startTime)/1000
						const days = Math.floor(totalSeconds/86400)
						const hours = Math.floor((totalSeconds%86400)/3600)
						const minutes = Math.floor((totalSeconds%3600)/60)
						// const seconds = Math.floor((totalSeconds%3600)%60)
						const dayUnit = days > 1 ? "days" : "day"
						const hourUnit = hours > 1 ? "hrs" : "hr"
						const minuteUnit = minutes > 1 ? "mins" : "min"
						elapses[event["__typename"]] = `${days > 0 ? days : ""} ${days > 0 ? dayUnit : ""}  ${hours > 0 ? hours : ""} ${hours > 0 ? hourUnit : ""} ${minutes} ${minuteUnit}`
						
						if (event["__typename"] === "LiquidationCreatedEvent") {
							if (totalSeconds > emp.liquidationLiveness) {
								// Overide the status if it's been created for more than defines in liquidationLiveness
								liquidation.status = "Completed"
							}
						}	

					}
					return {
						elapses : elapses,
						...liquidation
					}
				}

				(async () => {
					setAlerts([])
					try {
						const myAddress = emp.myPosition.myAddress
						// sponsor? liquidator? should be sponsor as they positions are being liquidated 
						const liquidationIds = emp.liquidations.filter(item => item.sponsor.id.toLowerCase() === myAddress.toLowerCase()).map(item => item.id)
						const alerts = await Promise.all(liquidationIds.map(id => getAlert(id)))
						setAlerts(alerts)
					} catch (e) {

					}
				})()


			}
		}
	},[emp])

	if (alerts.length === 0) return null

	return (
		<Panel title={'Liquidation Alert'} className="alert">
			<Table.Uncontrolled columns={LiquidationAlertColumns}>
				{alerts.map((item, i) => <LiquidationAlertTableRow key={i}  handleDispute={handleDispute} liquidationLiveness={emp.liquidationLiveness} {...item}/>)}
			</Table.Uncontrolled>
		</Panel>
	)
}


const Index = styled(
	({address, token0, token1, myPosition, className, ...rest}) => {
		const { synthetics } = appController
		const [ emp, setEmp ] = useState([])

		useEffect(() => {
			const sub = synthetics.state.subscribe(`emps.${address}`, setEmp, true)
			return () => sub.unsubscribe()
		}, []) // eslint-disable-line

		return <Grid cols={2} width={[70, 30]} className={className}>
			<PanelsWrapper>
				<LiquidationAlert
					emp={emp}
					synthetics={synthetics}
				/>
				<Panel title={'All positions'}>
					<Table.Uncontrolled columns={columns}>
						{emp?.positions && emp?.positions.map((position, i) => <TableRow key={i} {...position} address={address} expiry={emp?.expirationTimestamp}/>)}
					</Table.Uncontrolled>
				</Panel>
			</PanelsWrapper>
			
			<div className={'sidebar'}>
				<Asset.Position address={address}/>
				<Asset.Button.Mint address={address}/>
				<Asset.Button.Add address={address}/>
				<Asset.Button.Withdraw address={address}/>
				{/*<Asset.Button.Increase address={address}/>*/}
				{/*<Asset.Button.Decrease address={address}/>*/}
				<Asset.Button.Redeem address={address}/>
				<Asset.Button.RedeemAll address={address}/>
				<Asset.Stats address={address}/>
			</div>
		</Grid>
	})`
	.sidebar{
		>*{
			margin-bottom: var(--grid--spacing, 1.2rem);
		}
	}
	`

export default ({className}) => {
	const { synthetics } = appController
	const [ emps, setEmps ] = useState({})

	useEffect(() => {
		const sub = synthetics.state.subscribe(`emps`, emps => setEmps({...emps}), true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Layout title='Synthetic Assets' className={className}>
		<Layout.Main>
			<GateKeeper
				assertions={[
					{
						assert: () => Object.values(emps).length > 0,
						onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching Synthetic Assets</Panel>,
					}
				]}
				dependencies={[emps]}
				>
				<Tabbed>
					{Object.values(emps).map((emp, i) => 
						<Tabbed.Child key={i} title={emp?.token1?.symbol}>
							<Index {...emp}/>
						</Tabbed.Child>
					)}
				</Tabbed>
			</GateKeeper>
		</Layout.Main>
	</Layout>
}