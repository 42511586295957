import React, { useState, useEffect, Fragment } from 'react';
import { Button, Table, Panel, GateKeeper, LazyBoi, Tag } from '@components'
import assetController, { Status } from '@app/App.Controller'
import { ReactComponent as IconSwap } from '@assets/Icon_Swap.svg';
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as IconCheck } from '@assets/Icon_Check.svg';
import { ReactComponent as IconCancel } from '@assets/Icon_Cancel.svg';

const claimColumns = [
	{
		Header: 'Era',
		accessor: 'era',
	},
	{
		Header: 'Rewards',
		accessor: 'rewards',
	},
	{
		Header: 'Claim Available',
		accessor: 'claimed',
	},
	{
		Header: '',
		accessor: 'actions',
	}
]

const ClaimRow = ({era, isCurrent, rewardAvailable, hasClaimed}) => {

	const { perlinx } = assetController

	const [ rewardsText, setRewardsText ] = useState(<LazyBoi.Wei value={rewardAvailable} suffix={'PERL'} dp={4}/>)
	const [ claimedIcon, setClaimedIcon ] = useState()
	const [ actionButton, setActionButton ] = useState()
	const [ rowDisabled, setRowDisabled ] = useState(false)

	useEffect(() => {
		// ugh... 
		if(isCurrent){
			setRewardsText(<Tag.Success>Current Era</Tag.Success>)
			setClaimedIcon('')
			setActionButton(<Button disabled small icon={<IconRefresh/>}>Awaiting Snapshot</Button>)
		}
		else if(hasClaimed){
			setClaimedIcon(<IconCancel/>)
			setActionButton(<Tag.Success>Claimed</Tag.Success>)
			setRowDisabled(true)
		}
		else if(!parseInt(rewardAvailable)){
			setClaimedIcon(<IconCancel/>)
			setActionButton(<Tag.Warning>No Rewards</Tag.Warning>)
			setRowDisabled(true)
		}
		else{
			setClaimedIcon(<IconCheck data-theme='primary'/>)
			setActionButton(<Button.Primary small icon={<IconSwap/>} onClick={() => perlinx.claim(era)}>Claim</Button.Primary>)
		}
		
	}, [rewardAvailable, hasClaimed])  // eslint-disable-line

	return <Table.Uncontrolled.Row 
		fields={{
			era: era,
			rewards: rewardsText,
			claimed: claimedIcon,
			actions: actionButton,
		}}
		disabled={rowDisabled}
	/>
}

export default  () => {
	const { perlinx } = assetController
	const [ rewards, setRewards ] = useState([])

	useEffect(() => {
		const sub = perlinx.state.subscribe('rewardsSummary', summary => {
			const reversed = summary.reverse()
			setRewards(reversed)
		}, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to view your rewards',
				},
			]}
			assertions={[
				{
					assert: () => !!rewards.length,
					onFail: () => <Fragment><IconRefresh className='rotate'/>Fetching rewards</Fragment>,
				}
			]}
			dependencies={[rewards.length]}
			>
			<Table.Uncontrolled columns={claimColumns}>
				{rewards.reverse().map(reward => 
					<ClaimRow 
						key={reward.era} 
						{...reward}
					/>
				)}
			</Table.Uncontrolled>
		</GateKeeper>
	</Panel>
}