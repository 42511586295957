import React, { useState, useEffect, Fragment } from 'react';
import { Asset } from '@archetypes'
import { Button, Table, Panel, LazyBoi, GateKeeper, Placeholder } from '@components'
import { formatTimestamp, fromWei } from '@util/helpers'
import appController, { Status } from '@app/App.Controller'
import { ReactComponent as IconGear } from '@assets/Icon_Gear.svg';
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as IconPoolsEmpty } from '@assets/Icon_PoolsEmpty.svg';

const columns = [
	{
		Header: 'Token',
		accessor: 'token',
	},
	{
		Header: 'Collateralization Ratio (USD)',
		accessor: 'cr_current',
	},
	{
		Header: 'Global Collateralization Ratio (USD)',
		accessor: 'cr_global',
	},
	// {
	// 	Header: 'RAW GCR',
	// 	accessor: 'cr_minimum',
	// },
	{
		Header: 'Token Expiry',
		accessor: 'expiry',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: '',
		accessor: 'actions',
	}
]


const TableRow = ({address, symbol, cr_current, cr_global, cr_minimum, expiry, safe }) => <Table.Uncontrolled.Row 
	key={address}
	fields={{
		token: <Asset.Icon token={[symbol]}/>,
		cr_current: <LazyBoi  value={cr_current} dp={2} />,
		cr_global: <LazyBoi value={cr_global} dp={2}/>,
		// cr_minimum: <LazyBoi value={cr_minimum}  dp={2} fromWei />,
		expiry: <LazyBoi value={formatTimestamp(expiry)}/>,
		status: <Asset.Position.Tag safe={safe}/>,
		actions: <Button small icon={<IconGear/>} to={`/assets/${address}`}>Manage</Button>,
	}}
/>

export default () => {
	const { synthetics } = appController
	const [ positions, setPositions ] = useState([])

	useEffect(() => {
		const sub = synthetics.state.subscribe('emps', emps => {
			const empItems = Object.values(emps)
			if(empItems.length > 0){
				let myPositions = empItems.map(emp => {
					return +emp?.myPosition?.collateral > 0
						? {
							address: emp?.address,
							symbol: emp?.token1?.symbol,
							cr_current: emp?.myPosition?.actualCurrentRatio ,
							cr_global: emp?.actualGCR,
							cr_minimum : emp?.globalCollateralisationRatio ,
							expiry: emp?.expirationTimestamp,
							safe: emp?.myPosition?.safe,
						}
						:false
				}).filter(o=>o)

				setPositions(myPositions)
			}
		}, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel fadeIn>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to view your asset CDPs',
				},
				{
					controller: 'perlinx',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Fragment><IconRefresh className='rotate'/>Fetching assets</Fragment>,
				},
			]}
			assertions={[
				{
					assert: () => Array.isArray(positions) && !!positions.length,
					onFail: () => <Placeholder 
						icon={<IconPoolsEmpty style={{marginLeft: '0.3em'}}/>} 
						title='No positions ' 
						text='Mint assets to create a CDP' 
						links={[
							{
								to: '/assets',
								text: 'View Assets'
							}
						]} 
					/>
				}
			]}
			dependencies={[positions]}
			>
			<Table.Uncontrolled columns={columns}>
				{positions.map((position, i) => <TableRow key={i} {...position}/>)}
			</Table.Uncontrolled>
		</GateKeeper>
	</Panel>
}