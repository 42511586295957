import React, { useState } from 'react';
import { Button, Grid, Panel, Field } from '@components'
import assetController from '@app/App.Controller'

export default () => {

	const { perlinx } = assetController
	const [ poolAddress, setPoolAddress ] = useState()
	const [ tokenAddress, setTokenAddress ] = useState()
	const [ weight, setWeight ] = useState(0.5)

	return <Panel>
		<Grid cols={2} width={[30,70]}>
			<Panel.Title>List a TOKEN pool</Panel.Title>
			<span>
				<Field.Input.Address title='Balancer exchange address' onChange={setPoolAddress}/>
				<Field.Input.Address title='Corresponding non-PERL token address' onChange={setTokenAddress}/>
				<Field.Input.Number 
					title='Pool weight (Between 0.1 and 10.0, where 1 == normal)' 
					placeholder={'1'} 
					value={1}
					min={0.1}
					max={10}
					dp={1}
					onChange={setWeight}
				/>
			</span>
		</Grid>
		<Panel.Footer>
			<Panel.Footer.Group/>
			<Button.Primary 
				onClick={() => perlinx.listPool(poolAddress, tokenAddress, weight)}
				>
				List TOKEN Pool
			</Button.Primary>
		</Panel.Footer>
	</Panel>
}