import React from 'react';
import { Button, Panel } from '@components'
import assetController from '@app/App.Controller'

export default () => {
	const { perlinx } = assetController
	return <Panel title={'Snapshot PERL Pools'}>
		<Button.Primary onClick={() => perlinx.snapshot()}>Snapshot</Button.Primary>
	</Panel>
}


/*
	reinstate below for complete snapshot data
*/

// import React, { useState, useEffect, Fragment } from 'react';
// import styled from 'styled-components'
// import { Button, Field, Panel, GateKeeper, Table } from '@components'
// import AppStore from '@app/App.store'
// import { toWei } from '@util/helpers'
// import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';

// const columns = [
// 	{Header: 'Pools', accessor: 'address'},
// 	{Header: 'Pool Weight', accessor: 'weight'},
// 	{Header: 'Pool Depth', accessor: 'depth'},
// 	{Header: 'Weighted Depth', accessor: 'weighteddepth'},
// ]

// const data = [
// 	{
// 		address: '0x002164e9C8425D2F0c7dcde98E63317D305302C5',
// 		weight: '1.5',
// 		depth: '$2,400,001.12',
// 		weighteddepth: '$2,400,001.12',
// 	},
// 	{
// 		address: '0x002164e9C8425D2F0c7dcde98E63317D305302C5',
// 		weight: '1.5',
// 		depth: '$2,400,001.12',
// 		weighteddepth: '$2,400,001.12',
// 	},
// 	{
// 		address: '0x002164e9C8425D2F0c7dcde98E63317D305302C5',
// 		weight: '1.5',
// 		depth: '$2,400,001.12',
// 		weighteddepth: '$2,400,001.12',
// 	},
// ]

// const SnapShot = styled(
// 	({className}) => {
// 		const { perlinx } = AppStore().state.assets.controller
// 		const [ rewards, setRewards ] = useState(0)

// 		return <span className={className}>
// 			<Field.Input.Number
// 				info={
// 					<Fragment>
// 						[NEEDS CLARIFICATION]<br/>
// 						Enter weekly PERL rewards:
// 					</Fragment>
// 				} 
// 				placeholder='12500'
// 				onChange={setRewards}
// 			/>
// 			<Button.Primary
// 				onClick={() => perlinx.snapShot(toWei(rewards))}
// 				>
// 				Snapshot
// 			</Button.Primary>
// 		</span>
// 	})`
	
// 	display: flex;
// 	align-items: center;

// 	>.-title{
// 		color: var(--color-primary);
// 	}

// 	.field{
// 		margin: 0 1em;
// 		display: flex;
// 		align-items: center;
		
// 		.-header{
// 			margin: 0 1rem 0 0;
// 			white-space: nowrap;
// 			line-height: 1.4em;
// 			font-size: var(--font-size-normal, 14px)
// 		}
// 	}
// 	`

// const Pools = styled(
// 	({className}) => {
// 		const { perlinx } = AppStore().state.assets.controller
// 		const [ snapshots, setSnapshots ] = useState([])

// 		useEffect(() => {
// 			perlinx.fetchSnapshots(setSnapshots, true)
// 		}, []) // eslint-disable-line

// 		return <Panel className={className}>
// 			<GateKeeper
// 				assertions={[
// 					{
// 						assert: () => snapshots?.length > 0,
// 						onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching snapshots</Panel>,
// 					}
// 				]}
// 				dependencies={[snapshots?.length]}
// 				>
// 				<Panel.Title extra={<SnapShot/>}>Snapshot PERL Pools</Panel.Title>
// 				<Panel.Divider full/>
// 				<Table columns={columns} data={data}/>
// 			</GateKeeper>
// 		</Panel>
// 	})`
// 	>.panel-title{ margin-bottom: 0 }
// 	>.panel-divider{ margin-top: 2rem }
// 	`