import { BN } from '@util/helpers'
import SubsAndEventsClass from '@util/SubsAndEventsClass.js'

export class LockController extends SubsAndEventsClass{
	_ADDRESS
	_PERLINX
	_NETWORK
	
	INPUT_PERCENT = 0
	POOL

	
	constructor(address, {perlinx, network}){
		super()
		this._ADDRESS = address
		this._PERLINX = perlinx
		this._NETWORK = network
	}

	init(){
		const sub = this._PERLINX.state.subscribe(
			`pools.${this._ADDRESS}`, 
			pool => {
				this.POOL = pool
				this._calculateApprovals()
				this.updateInputPercent()
			}, 
			true
		)

		this._SUBSCRIPTIONS.push(sub)
	}

	updateInputPercent(percent=0){
		percent = !percent || isNaN(percent) ? 0 : percent
		this.INPUT_PERCENT = percent
		this._update()
	}
	

	// private
	_update(){
		if(!this.INPUT_PERCENT < 0 || !this.POOL || !this.POOL?.stake) return

		this.AMOUNT = BN(this.POOL?.stake?.units).times(this.INPUT_PERCENT).toFixed(0)

		this.dispatchEvent('update', {	
			balance: this.POOL?.stake?.units,
			tx: {
				amount: this.AMOUNT
			}
		});
	}


	_calculateApprovals(){
		const sub = this._NETWORK.state.subscribe('current', ({PERLX}) => {
			const approvals = [
				{
					name: 'PerlinX',
					toApprove: PERLX,
					onContract: this._ADDRESS
				}
			]

			this.dispatchEvent('approvals', approvals);
		})

		this._SUBSCRIPTIONS.push(sub)
	}
}