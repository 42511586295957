import { gql } from 'apollo-boost'

const notEnabledConfig = {
	name: "Default",
	enabled: false,
	PERLX: '0x0000000000000000000000000000000000000000',
	PERL: '0x0000000000000000000000000000000000000000',
	PERL_LEGACY: '0x0000000000000000000000000000000000000000',
	PERL_LEGACY_UNIT: 'ether',
	EMP_CREATOR: '0x0000000000000000000000000000000000000000'
}

// https://chainid.network/chains.json
export const Networks = {
	1: {
		name: "Mainnet",
		enabled: true,
		PERLX: '0x5Fa19F612dfd39e6754Bb2E8300E681d1C589Dd4',
		PERL: '0xeca82185adCE47f39c684352B0439f030f860318',
		PERL_LEGACY: '0xb5A73f5Fc8BbdbcE59bfD01CA8d35062e0dad801',
		PERL_LEGACY_UNIT: 'gwei',
		EMP_CREATOR: '0x9A077D4fCf7B26a0514Baa4cff0B481e9c35CE87'
	},
	3: { ...notEnabledConfig, name: 'Ropsten' },
	4: { ...notEnabledConfig, name: 'Rinkeby' },
	5: { ...notEnabledConfig, name: 'Goerli' },
	42: {
		name: 'Kovan',
		enabled: true,
		PERLX: '0x6a7D98Ff6D47175A75525a4193a8661493b828c5',
		PERL: '0xf2A75dcEC46265c79D52Cf28E5863A2106F2e14C',
		PERL_LEGACY: '0xB7b9568073C9e745acD84eEb30F1c32F74Ba4946',
		PERL_LEGACY_UNIT: 'ether',
		EMP_CREATOR: '0xF763D367E1302A16716b6c40783A17c1aC754F2E'
	},
}

// fecth total PERL staked
export const DashboardApi = "https://dbu9ftcsij.execute-api.ap-southeast-1.amazonaws.com/stage"

export const UmaAssetQuery = address => gql`
	{
		financialContracts(
			where: {
				collateralRequirement_not: null,
				address: "${address}"
			}
		)
		{
			positions {
				sponsor {
					id
				}
				collateral
				tokensOutstanding
			}
			liquidations {
				id
				status
				sponsor {
					id
				}
				position {
					id
					collateral
					tokensOutstanding
				}
				liquidationId
				liquidator {
					address
				}
				disputer {
					address
				}
				tokensLiquidated
				lockedCollateral
				liquidatedCollateral
				disputeBondAmount
			}
		}
	}
`


export const UmaLiquidationQuery = (id) => gql`
	{
		liquidation(id : "${id}")
		{
			id
			liquidationId
			status
			tokensLiquidated
			liquidator {
				address
			}
			events {
				id
				timestamp
				tx_hash
			}
		}
	}
`
