import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Field } from '@components'
import { numberToMaxDp, isArray } from '@util/helpers'

const Amount = styled(
	({ value=0, withPresets, presets, asPercent, footer, disabled, dp, onChange=()=>{}, className, ...rest }) => {

		const [_value, setValue] = useState(value)
		//const min = rest.min ? parseFloat(rest.min) : 0
		const max = parseFloat(rest?.max||1)

		const handleChange = val => {
			// val = parseFloat(val)

			// // max/min
			// if(min !== undefined && val < min){ val = min }
			// if(max && val > max){ val = max }

			// console.log(val)

			// if(val !== _value){
				setValue(val)
				onChange(asPercent === true ? val/max : val||0)
			//}
		}

		useEffect(() => {
			value !== undefined && handleChange(numberToMaxDp(value, 5))
		}, [value])  // eslint-disable-line

		return <div className={`amount ${className}`}>
			<Field.Input.Number
				//type="number" 
				placeholder={rest.min}
				value={!!dp ? numberToMaxDp(_value, dp) : _value}
				onChange={handleChange}
				disabled={!!disabled}
				footer={
					<Fragment>
						{!!withPresets && 
							<Amount.Presets
								value={(100/max)*_value*0.01}
								presets={presets} 
								onChange={pc => handleChange(max * pc)}
							/>
						}
						{footer && <div className="-footer-extra">{footer}</div>}
					</Fragment>
				}
				{...rest}
			/>
			
		</div>
	})`
	
	>*{
		display: block;
		width: 100%;
	}

	.field{
		font-size: var(--font-size-medium);
		pointer-events: ${({disabled}) => !!disabled ? 'none' : 'all'};
		cursor: ${({disabled}) => !!disabled ? 'not-allowed' : 'default'};

		
		.-header{
			opacity: ${({disabled}) => !!disabled ? '0.5' : '1'};
		}

		.-footer{
			margin-top: 1.6rem;
			opacity: ${({disabled}) => !!disabled ? '0' : '1'};

			.-footer-extra{
				margin-top: 3.4rem;
			}
		}
	}

	
	`

Amount.Select = styled(
	props => {
		return <Field.Select {...props}/>
	}
	)`
	
	`

Amount.Group = styled(
	({ separator, children, className, ...rest }) => {
		return <div 
			className={`amount-group ${className}`}
			{...rest}
			>
			{React.Children.toArray(children)[0] && <span className="child -first">{React.Children.toArray(children)[0]}</span>}
			<span className="seperator"/>
			{React.Children.toArray(children)[1] && <span className="child -last">{React.Children.toArray(children)[1]}</span>}
		</div>
	})`
	width: 100%;
	display: flex;
	align-items: flex-start;
	
	>.child{
		width: 45%;
		//background: blue;	

		&.-first .-container:after{
			content: '${({separator}) => separator||'='}';
			position: absolute;
			top: 50%;
			left: calc(100% + 3.6rem);
			transform: translate(-50%, -50%);
			font-size: var(--font-size-xlarge);
		}
	}

	>.seperator{
		width: 10%;
		text-align: center;
		//background: red;
	}

	`

Amount.Presets = styled(
	({title, info, value, presets=[0.25,0.5,0.75,1], onChange=()=>{}, className}) => {

		const [internalPercent, setInternalPercent] = useState(0)
		
		useEffect(() => {
			value && setInternalPercent(parseFloat(value.toFixed(7)))
		}, [value])

		const handleChange = percent => {
			if(percent !== internalPercent){
				setInternalPercent(percent)
				onChange(percent)
			}
		}

		return <div className={`amount-presets ${className}`}>

			{(title || info) && 
				<span className="-titlebar">
					{title && <span className="-title">{title}</span>}
					{info && <span className="-info">{info}</span>}
				</span>
			}
			{presets.map(val => {

				let label = 0
				let multiplier = 0
				
				if(isArray(val)){
					label = val[0]
					multiplier = val[1]
				}else{
					label = `${parseInt(val * 100)}%`
					multiplier = val
				}

				const El = multiplier === internalPercent ? Button.Primary : Button
				return <El
					key={multiplier}
					small
					onClick={() => handleChange(multiplier)}
					>
					{multiplier === 1 ? 'MAX' : label}
				</El>
			})}
		</div>
	}
	)`
	
	display: inline-block;

	.-titlebar{
		display: flex;
		justify-content: space-between;
		align-items: flex-end
		font-size: var(--field--header--font-size, 12px);
		margin-bottom: 1.2rem;
		font-weight: 600;

		.-title{
			text-transform: uppercase;
			color: var(--field--color, black);
			font-size: var(--field--header--font-size, 12px);
		}
		
		.-info{
			color: var(--color-label, black);
			font-size: var(--field--header--font-size, 12px);
		}
	}
	`

export default Amount