import React from 'react';
import styled from 'styled-components'
import { NavLink } from "react-router-dom";

const ButtonTemplate = styled(
	({icon, small, children, className, ...rest}) => {
		let NodeType = 'button'

		if(!!rest.to && !rest.disabled ){
			if(rest.to.substr(0,4) === 'http'){
				NodeType = 'a'
				rest.href = rest.to
				delete rest.to
			}else{
				NodeType = NavLink
			}
		}else{
			NodeType = 'button'

			if(!!rest.disabled){
				delete rest.to
			}
		}
	
		// make sure all children are wrapped in a node
		const warppedChildren = React.Children.map(children, child => typeof child === 'string' ? <span>{child}</span> : child)

		return <NodeType 
			className={`button ${className}`}
			//to={!!rest.disabled ? null : to} 
			{...rest}
			>
			{icon}
			{warppedChildren}
		</NodeType>
	})`
	cursor: pointer;
	position: relative;
	display: flex;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.15s ease-in-out;
	border-radius: 2rem;
	padding: 1.2rem 1.75rem;
	letter-spacing: 0.16px;
	line-height: 1.4rem;
	

	>svg{
		//width: 1.6rem;
		//height: 1.6rem;
		fill: currentColor;
	}

	${({icon}) => (
		icon && `
			padding: 1.2rem 1.6rem;
			>svg{
				margin-right: 0.6rem;
			}
		`
	)}

	${({icon, small}) => (
		small && `
			padding: 0.7rem 1.75rem;
			//min-width: 5rem;


			${icon && `
				padding: 0.7rem 1.35rem;
			`}
		`
	)}

	>*{
		color: inherit;
		z-index: 1;
		//line-height: inherit;
	}

	&[disabled]{
		pointer-events: none;
		opacity: 0.5;
		cursor: not-allowed;
	}

	& + .button{
		margin-left: 0.8rem;
	}
	`

const Button = styled(
	({children, ...props}) => <ButtonTemplate {...props}>{children}</ButtonTemplate>)
	`
	border: 1px solid rgba(255,255,255,0.3);
	color: var(--button--text--color, white);
	background: var(--button--background--color, transparent);

	&:hover{
		border: 1px solid var(--button--border--color, white);
	}
	` 

Button.Primary = styled(
	({children, ...props}) => <ButtonTemplate {...props}>{children}</ButtonTemplate>)
	`
	border: 1px solid var(--button-primary--border--color, transparent);
	color: var(--button-primary--text--color, black);
	background: var(--button-primary--background--color, green);

	&:not([disabled]):hover{
		background: var(--button-primary--background--color--hover, white);
		border: 1px solid var(--button-primary--border--color--hover, transparent);
	}
	` 

Button.Danger = styled(
	({children, ...props}) => <ButtonTemplate {...props}>{children}</ButtonTemplate>)
	`
	border: 1px solid var(--button-danger--border--color, red);
	color: var(--button-danger--text--color, red);
	background: var(--button-danger--background--color, transparent);

	&:not([disabled]):hover{
		background: var(--button-danger--background--color--hover, white);
		border-color: var(--button-danger--border--color--hover, red);
	}
	` 

Button.Icon = styled(
	({icon, className, ...rest}) => {
		return <Button {...rest} className={className}>{icon}</Button>
	})`
	padding: 0em;
	border: 1px solid rgba(255,255,255,0.3);
	width: 4rem;
	height: 4rem;
	border-radius: 50%;;
	min-width: 0;

	>svg{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 65%;
		height: 65%;
	}

	&:hover{
		border: 1px solid var(--color-white);
	}
	` 

export default Button