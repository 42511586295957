import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatCurrency, fromWei, formatPercent, parseBN, formatCommas, formatBN } from '@util/helpers'
import { ReactComponent as Circle } from '@assets/Icon_Dot.svg';

const Loader = styled(
	({icon, className}) => {
		return <span className={`lazyboi ${className}`}>{icon || <Circle data-animation='pulse'/>}</span>
	})`
	@keyframes pulse {
	    0% {opacity:0.05}
	    50% {opacity:0.15}
	    100% {opacity:0.05}
	}

	>svg{
		opacity: 0.1;
		width: 0.4rem;
		height: 0.4rem;
		animation-name: pulse;
		animation-duration: 500ms;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in;
	}
	`

const Value = ({suffix, prefix, tight=false, value, dp=-1, processFunc=v=>v, ...props}) => {

	const [val, setVal ] = useState()

	useEffect(() => {
		if(value || !isNaN(+value)){
			let _val = value

			// format value
			_val = formatBN(_val) // make sure we're not dealing with a BN
			_val = !!props.fromBn ? +parseBN(_val) : _val
			_val = !!props.fromWei ? +fromWei(_val) : _val
			_val = processFunc(_val, props)
			_val = dp > -1 && !!_val && !isNaN(+_val) ? +parseFloat(_val).toFixed(dp) : _val
			_val = typeof _val === 'number' && _val > 0 && _val < 0.001 ? '<0.001' : _val
			_val = typeof _val === 'number' ? formatCommas(_val, dp > -1 ? dp : 2) : _val

			setVal(_val)
		}
	}, [value]) // eslint-disable-line

	return <Fragment>{prefix}{!tight && ' '}{val}{!tight && ' '}{suffix}</Fragment>
}

const LazyBoi = props => (props.value !== null && props.value !== undefined) ? <Value {...props}/> : <Loader icon={props.loadingIcon}/>


const Wei = props => <LazyBoi {...props} fromWei/>
const Percent = ({total=100, value, ...props}) => {
	const [percent, setPercent] = useState()

	useEffect(() => {
		let _total = +total
		let _value = +value

		_value = _value && !!props.fromWei ? +fromWei(_value.toFixed(0)) : _value

		if(_total <= 0){
			_total = 1
		}

		if(isNaN(_value)){
			setPercent(null)
		}
		// else if(_total <= _value || !value){
		// 	setPercent(null)
		// }
		else{
			setPercent(formatPercent((100/_total)*_value))
		}
		
	}, [total, value]) // eslint-disable-line

	return <LazyBoi {...props} value={percent} fromWei={false}/>
}
const Currency = props => <LazyBoi {...props} processFunc={v => formatCurrency(v)}/>


LazyBoi.Wei = Wei
LazyBoi.Percent = Percent
LazyBoi.Currency = Currency

export default LazyBoi