import React, { useState, useEffect } from 'react';
import styled from 'styled-components'

const Field = styled(
	({title, subtitle, info, value='', onChange, hasError=false, footer, children, className, ...rest}) => {
		const [ errors, setErrors ] = useState([])
		const [ touched, setTouched ] = useState(false)
		const [ currentValue, setCurrentValue ] = useState(value)
		
		const validate = val => {
			if(val !== undefined) setTouched(true)

			let err = []
			setErrors(err)

			if(!err.length){
				setCurrentValue(val)
			}
		}

		useEffect(() => {
			validate(value)
		}, [value])  // eslint-disable-line

		return <span 
			className={`field ${className}`}
			data-has-error={(errors.length > 0 && touched) || hasError}
			>
			{(title || info) &&
				<span className="-header">
					{title && <span className="-title">{title}</span>}
					{info && <span className="-info">{info}</span>}
				</span>
			}
			{subtitle && <span className="-subtitle">{subtitle}</span>}
			<div className='-container'>
				{
					React.Children.map(children, child => 
						React.cloneElement(
							child,
							{
								value: currentValue||'',
								onChange: e => {
									if(onChange) onChange(e.target.value)
									validate(e.target.value)
								},
								...rest
							}
						)
					)
				}
			</div>
			{footer && 
				<div className='-footer'>{footer}</div>
			}
			{errors.length > 0 && touched &&
				<span 
					className="-error">
					{errors[0]}
				</span>
			}
		</span>
	})`
	position: relative;
	display: block;

	>.-header{
		display: flex;
		justify-content: space-between;
		font-size: var(--field--header--font-size, 12px);
		margin-bottom: 0.6rem;
		font-weight: 600;

		.-title{
			text-transform: uppercase;
			color: var(--field--color, black);
		}

		.-info{
			color: var(--field--info--color, grey);
		}
	}

	>.-subtitle{
		display: block;
		margin-bottom: 0.6rem;
		font-size: var(--field--subtitle--font-size, 12px);
		color: var(--field--subtitle--color, grey);
		margin: 0 0 1.2rem 0;
		line-height: 1em;
		opacity: 0.7;
	}

	>.-container{
		position: relative;
		border: none;
		border-radius: 0.2em;
		padding: 0.9rem 1.6rem;
		width: 100%;
		display: block;
		transition: all 0.2s;
		font-size: var(--field--font-size, inherit);
		background: var(--field--background-color, white);
		color: inherit;

		>*{
			&[disabled]{
				opacity: 0.5;
				cursor: not-allowed;
			}

			::placeholder {
				color: var(--color-ultralight);
				opacity: 0.3;
			}
		}

		input,
		select{
			border: none;
			width: 100%;
			display: block;
			transition: all 0.2s;
			color: inherit;
			background: none;
			padding: 0;
			line-height: 1rem;
		}

		input{
			font-size: var(--field-input--font-size, inherit);
		}

		select{
			font-size: var(--field-select--font-size, inherit);
		}
	}
	
	>.-footer{
		font-size: var(--font-size-small, 12px);
		margin-top: 1rem;
	}
	
	
	.-error{
		position: absolute;
		bottom: 0.4em;
		right: 0.4em;
		font-size: var(--field--footer--font-size, 12px);
		line-height: 1em;
		color: var(--color-status-error, red);;
	}

	&[data-has-error='true']{
		input{
			border-color: var(--color-status-error, red);
		}
	}

	& + .field{
		margin-top: 2.7rem;
	}
	`

const Input = ({className, ...props}) => <Field 
	{...props}
	className={`-input ${className}`}
	>
	<input/>
</Field>

const InputNumber = ({value=0, dp=10, onChange=()=>{}, ...props}) => {

	const [ val, setVal ] = useState(0)

	useEffect(() => {
		updateVal(value)
	}, [value, props?.min, props?.max]) // eslint-disable-line

	const updateVal = _val => {

		_val = parseFloat(_val)
		_val = (props?.min || props?.min === 0) && _val < props?.min ? props?.min : _val
		_val = props?.max && _val > props?.max ? props?.max : _val


		_val = +parseFloat(_val).toFixed(dp)

		if(_val !== val){
			setVal(_val)
		}else{
			// small hack to make sure we can't go below/above min/max
			setVal(_val.toString())
			//setTimeout(() => setVal(_val), 1)
		}

		//setVal(_val)
		onChange(_val)
	}

	return <Input 
		{...props}
		type='number'
		value={val}
		onChange={updateVal}
	/> 
}

const InputAddress = ({value='', ...props}) => {
	return <Input 
		placeholder='0x001C5 ... 02D1' 
		value={typeof value === 'string' && value.substring(0, 2) !== '0x' ? `0x${value}` : value } 
		{...props}
	/> 
}


Input.Number = InputNumber
Input.Address = InputAddress

const Select = styled(
	({options, className, ...props}) => <Field 
		{...props} 
		className={`-select ${className}`}
		//onChange={onChange}
		>
		<select>
			{options.map(option => 
				<option 
					key={option.key} 
					className={'option'} 
					value={option.key}
					>
					{option.value}
				</option>
			)}
		</select>
	</Field>
	)`
	
	>.-container{
		padding-top: 1.35rem;
		padding-bottom: 1.35rem;
	}

	select{
		font-size: var(--field-select--font-size, inherit);
		cusror: pointer;

		.option{
			font-size: 12px !important;
		}
	}

	`

const Info = styled(
	({className, children,  ...props}) => {
		//const [ errors, setErrors ] = useState([])
		return <Field {...props} className={`-info ${className}`}>
			{children}
		</Field>
	})`
	
	>.-container{
		background: rgba(0,0,0,0.1);
	}
	
	`

const TextArea = ({onChange, className,  ...props}) => <Field 
	{...props}
	className={`-input ${className}`}
	>
	<textarea onChange={e => onChange(e.target.value)}/>
</Field>

const RadioGroup = styled(
	({title, value, options, className, onChange, ...props}) => <span 
		{...props} 
		className={`field -radiogroup ${className}`}
		>
		{title && <span className="-title">{title}</span>}
		<div className={'-options'}>
			{options.map((option, i) => 
				<span key={i} className='-option' onClick={() => onChange(option.value)} data-selected={value === option.value} disabled={option.disabled}>
					<div className='-indicator'/>
					<div>
						<div className='-label'>{option.label}</div>
						<div className='-sublabel'>{option.sublabel}</div>
					</div>
				</span>
			)}
		</div>
	</span>
	)`

	display: block;

	.-title{
		display: block;
		font-size: var(--field--header--font-size, 12px);
		margin-bottom: 0.6rem;
		font-weight: 600;
		text-transform: uppercase;
		color: var(--field--color, black);
		//opacity: 0.5;
	}

	.-options{
		display: flex;
	}

	.-option{
		display: flex;
		width: auto;
		cursor: pointer;

		.-indicator{
			width: 2rem;
			height: 2rem;
			margin-right: 1rem;
			position: relative;

			&:before,
			&:after{
				content: '';
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:before{
				width: 100%;
				height: 100%;
				border: 1px solid var(--color-primary);
			}

			&:after{
				width: 70%;
				height: 70%;
				background: var(--color-primary);
				opacity: 0;
			}
		}

		.-label{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&[data-selected='true']{
			.-indicator:after{
				opacity: 1
			}
		}

		& + .-option{
			margin-left: 2rem;
		}

		&[disabled]{
			opacity: 0.5;
			cursor: not-allowed;
			pointer-events: none;
		}
	}

	`


const RadioGroupVertical = styled(RadioGroup)`
	.-options{
		flex-direction: column;
		margin-top: 20px;

	}
	.-label {
		
	}
	.-sublabel { 
		max-width: 400px;
		font-size: 12px;
		color: #BCD7F6;
	}
	.-title{
		margin-left: 2rem;
		color: #BCD7F6;
	}
	.-option{ 
		height: 80px;
		margin-left: 2rem;

	}
`


export default {
	Input,
	TextArea,
	Select,
	Info,
	RadioGroup,
	RadioGroupVertical
}