import React, { useState, useEffect } from 'react';
import {Panel, Grid, Stat, GateKeeper, LazyBoi } from '@components'
import assetController from '@app/App.Controller'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';

export default () => {
	const { perlinx } = assetController
	const [ incentives, setIncentives ] = useState({})

	useEffect(() => {
		const sub =  perlinx.state.subscribe('incentivesSummary', setIncentives, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel 
		title='PERL Liquidity Incentives' 
		>
		<GateKeeper
			assertions={[
				{
					assert: () => !!Object.keys(incentives).length,
					onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching data</Panel>,
				}
			]}
			dependencies={[incentives]}
			>

			<Grid cols={5}>
				<Stat title='Current Era'><LazyBoi value={incentives?.currentEra}/></Stat>
				<Stat title='Rewards Left'><LazyBoi.Wei value={incentives?.totalRewards} suffix={'PERL'} dp={4}/></Stat>
				<Stat title='Era Rewards'>{<LazyBoi.Wei value={incentives?.currentEraRewards} suffix={'PERL'} dp={4}/>}</Stat>
				<Stat title='Member Count'><LazyBoi value={incentives?.memberCount}/></Stat>
			</Grid>
		</GateKeeper>
	</Panel>
}