import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Panel, Stat, GateKeeper, LazyBoi } from '@components'
import { Asset, Pool } from '@archetypes'
import { formatCurrency } from '@util/helpers'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import assetController from '@app/App.Controller'

export default ({address}) => {
	const { perlinx } = assetController
	const [ data, setData ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe(`pools.${address}`, setData, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel 
		fadeIn 
		title={<Asset.Icon token={[data?.token0.symbol, data?.token1.symbol]}/>}
		>
		<GateKeeper
			assertions={[
				{
					assert: () => !!data,
					onFail: () => <Fragment><Refresh className='rotate'/></Fragment>,
				}
			]}
			dependencies={[data]}
			>
			<Grid cols={2}>
				<Stat title='Price'>{formatCurrency(data?.token1?.dollarPrice)}</Stat>
				<Stat title='Depth'><LazyBoi.Currency value={data?.depth} fromWei/></Stat>
				<Stat title='Volume'><LazyBoi.Currency value={data?.dashboard?.volume}/></Stat>
				<Stat title='Weight'><LazyBoi value={`${(data?.token0?.weight)/(10**16)}/${(data?.token1?.weight)/(10**16)}`}/></Stat>
				{/* <Stat title='ROI'><LazyBoi.Percent total={1} value={data?.blocklytics?.roi}/></Stat> */}
			</Grid>
		</GateKeeper>

		<Pool.MyStake address={address}/>
	</Panel>
}