import React, { useState, useEffect } from 'react';
import { GateKeeper, Grid, Panel, Stat, LazyBoi } from '@components'
import assetController, { Status } from '@app/App.Controller'

export default ({address, className}) => {
	const { perlinx } = assetController
	const [ pool, setPool ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe(`pools.${address}`, setPool, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line
	
	return <Panel fadeIn>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Panel fadeIn>Connect your wallet</Panel>,
				}
			]}
			//dependencies={[pool]}
			>
			<Grid cols={2}>
				<Stat title='LP Token'>
					<LazyBoi 
						value={pool?.name}
					/>
				</Stat>
				<Stat title='Share' animateChange>
					<LazyBoi.Percent 
						total={pool?.totalUnits} 
						value={pool?.rewards?.locked}
					/>
				</Stat>
				<Stat title='Balance' animateChange>
					<LazyBoi 
						value={pool?.rewards?.available} 
						dp={4} 
						fromWei
					/>
				</Stat>
				<Stat title='Locked' animateChange>
					<LazyBoi 
						value={pool?.rewards?.locked} 
						dp={4} 
						fromWei
					/>
				</Stat>
			</Grid>
		</GateKeeper>
	</Panel>
}