import moment from 'moment'
import { toWei, stringToHex } from '@util/helpers'
import SubsAndEventsClass from '@util/SubsAndEventsClass.js'

export class CreateEMPController extends SubsAndEventsClass{
	_NETWORK
	_FIELDS = {
		"collateralAddress": null,
		//"finderAddress": null,
		//"tokenFactoryAddress": null,
		//"timerAddress": "0x0000000000000000000000000000000000000000",
		"priceFeedIdentifier": '',
		"expirationTimestamp": null,
		"syntheticName": null,
		"syntheticSymbol": null,
		"withdrawalLiveness": 7200,
		"liquidationLiveness": 7200,
		"minSponsorTokens": 1000,
		"collateralRequirement": 38,
		"disputeBondPct": 3,
		"sponsorDisputeRewardPct": 5,
		"disputerDisputeRewardPct": 5,
		"excessTokenBeneficiary" : null
	}

	NETWORK_ID
	
	constructor({network}){
		super()
		this._NETWORK = network
		this._FIELDS.expirationTimestamp = moment().add(1, 'week').unix()
	}

	init(){
		this._NETWORK.state.subscribe('current', ({id}) => {
			this.NETWORK_ID = id
			this._update()
		}, true)
	}


	// private
	_update(){
		//if(!)

		this.dispatchEvent('details', {	
			...this._FIELDS,
			networkId: this.NETWORK_ID,
			tx: this.calculateTxValues()
		});
	}

	calculateTxValues(){
		let priceFeedIdentifier = stringToHex(this._FIELDS.priceFeedIdentifier)
		if(priceFeedIdentifier){
			priceFeedIdentifier = `0x${priceFeedIdentifier.padEnd(64, '0')}`
		}
		
		return {
			expirationTimestamp: this._FIELDS.expirationTimestamp.toString(),
			withdrawalLiveness: this._FIELDS.withdrawalLiveness,
			collateralAddress: this._FIELDS.collateralAddress,
			excessTokenBeneficiary: this._FIELDS.excessTokenBeneficiary,
			priceFeedIdentifier: priceFeedIdentifier,
			syntheticName: this._FIELDS.syntheticName,
			syntheticSymbol: this._FIELDS.syntheticSymbol,
			minSponsorTokens: [toWei(this._FIELDS.minSponsorTokens)],
			liquidationLiveness: this._FIELDS.liquidationLiveness,
			collateralRequirement: [toWei(this._FIELDS.collateralRequirement)],
			disputeBondPct: [toWei(this._FIELDS.disputeBondPct*0.01)],
			sponsorDisputeRewardPct: [toWei(this._FIELDS.sponsorDisputeRewardPct*0.01)],
			disputerDisputeRewardPct: [toWei(this._FIELDS.disputerDisputeRewardPct*0.01)],
		}
	}

	setValue(key, val){
		if(!!val){
			this._FIELDS[key] = val
			this._update()
		}
	}
}