import React, { useState, useEffect} from 'react';
import styled from 'styled-components'
import { Asset } from '@archetypes'
import { Panel, GateKeeper } from '@components'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import assetController, { Status } from '@app/App.Controller'

const Placeholder = styled(
	({className}) => <Panel fadeIn className={className}>
		<Asset.Value/>
	</Panel>
	)`
	&[data-visible="true"]{
		opacity: 0.4 !important;
	}
	`

export default () => {

	const { wallet } = assetController
	const [ balances, setBalances ] = useState()

	useEffect(() => {
		let sub = wallet.state.subscribe('balances', balances => setBalances({...balances}), true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line


	return <GateKeeper
		controllerAssertions={[
			{
				controller: 'account',
				key: 'status',
				assert: val => val === Status.READY,
				onFail: <Panel fadeIn>Connect your wallet to view your balances</Panel>,
			},
			
		]}
		assertions={[
			{
				assert: () => !!balances,
				onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching wallet balances</Panel>,
			}
		]}
		dependencies={[Object.keys(balances||{}).length]}
		>
		{balances && Object.keys(balances).map(token => 
			<Panel fadeIn key={token}>
				<Asset.Value token={token} {...balances[token]}/>
			</Panel>
		)}
		{balances && Object.keys(balances).length <= 2 && <Placeholder/>}
	</GateKeeper>
}