import React from 'react';
import { Link } from "react-router-dom";
import { Admin } from '@archetypes'

export default ({wrapper, className}) => <Admin.GateKeeper>
	{!!wrapper
		? React.cloneElement(wrapper, {}, <Link to='/admin'>Admin</Link> )
		: <Link to='/admin'>Admin</Link> 
	}
</Admin.GateKeeper>
	