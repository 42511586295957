import React, { useState, useEffect } from 'react';
import styled from 'styled-components'

import { ReactComponent as PERL } from '@assets/Icon_Token_PERL.svg';
import { ReactComponent as ETH } from '@assets/Icon_Token_ETH.svg';
import { ReactComponent as pxCarbon } from '@assets/Icon_Token_pxCarbon.svg';
import { ReactComponent as pxGold } from '@assets/Icon_Token_pxGold.svg';
import { ReactComponent as pxOil } from '@assets/Icon_Token_pxOil.svg';
import { ReactComponent as UNKNOWN } from '@assets/Icon_Dot.svg';
import { ReactComponent as pxUsd } from "@assets/Icon_Token_pxUsd.svg"

const iconMappings = {
	UNKNOWN: UNKNOWN,
	perl: PERL,
	eth: ETH,
	pxcarbon: pxCarbon,
	umacarbon_sep2020: pxCarbon,
	pxgold: pxGold,
	umagold_aug2020: pxGold,
	pxoil: pxOil,
	usdt: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
	tusd: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0000000000085d4780B73119b644AE5ecd22b376/logo.png',
	usdc: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
	bal: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xba100000625a3754423978a60c9317c58a424e3D/logo.png',
	busd: 'binanceusd_32.png',
	dai: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x00000000001876eB1444c986fD502e618c587430/logo.png',
	weth: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
	pxusd: pxUsd
}

const Token = styled(
	({symbol, className}) => {
		let Icon = iconMappings[symbol?.toLowerCase()]||null

		if(!Icon){
			// test gold/carbon/oil - add more later
			if(symbol?.toLowerCase().includes('gold')){
				Icon = iconMappings.pxgold
			}else if(symbol?.toLowerCase().includes('carbon')){
				Icon = iconMappings.pxcarbon
			}else if(symbol?.toLowerCase().includes('oil')){
				Icon = iconMappings.pxoil
			} else if (symbol?.toLowerCase().includes('pxusd')) {
				Icon = iconMappings.pxusd
			}
			else{
				Icon = iconMappings.UNKNOWN
			}			
			return <Icon className={`token -svg ${className}`}/>
		}else if(typeof Icon === 'string'){
			if(Icon.substring(0,4) === 'http'){
				return <img src={Icon} alt="" className={`token -img ${className}`}/>
			}else{
				return <img src={`https://etherscan.io/token/images/${Icon}`} alt="" className={`token -img ${className}`}/>
			}
		}else{ 
			return <Icon className={`token -svg ${className}`}/>
		}
	})`

	display: inline-block;
	fill: rgba(255,255,255,0.3);
	
	&.-svg{
		width: 2.4rem;
		height: 2.4rem;
		fill: rgba(255,255,255,0.3);
	}

	&.-img{
		width: calc(2.4rem);
		height: calc(2.4rem);
		background: rgba(0,0,0,1);
		border-radius: 50%;
		border: 1px solid rgba(216, 216, 216, 0.6);
	}

	`

export default styled(
	({token, text, className, ...rest}) => {
		
		const [ tokenSymbols, setTokenSymbols ] = useState([])

		const defineTokens = async _token => {
			if(!!_token){
				let _tokens = typeof _token === 'string' ? [_token] : _token
				setTokenSymbols(_tokens)
			}
		}
		
		useEffect(() => {
			defineTokens(token)
		}, [token]) // eslint-disable-line

		return <span 
			className={`asset-icon ${className}`}
			{...rest}
			>
			{tokenSymbols.map((sym, i) => <Token key={i} symbol={sym}/>)}
			<span className='text'>{text || tokenSymbols.join(' / ')}</span>
		</span>
	})`
	
	display: flex;
	align-items: center;
	font-size: inherit;

	.token + .token{
		margin-left: -0.8rem;
	}

	>span{
		margin-left: 1.3rem;
	}
	
	`