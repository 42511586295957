import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as ArrowRight } from '@assets/Icon_Arrow_Right.svg';

const Panel = styled(
	({title, subtitle, onClick, fadeIn=false, children, className, ...rest}) => {
		
		const [visible, setVisible] = useState(fadeIn === true ? false : true)
		const visibleTimeout = fadeIn === true ? Math.floor(Math.random() * Math.floor(500)) : 0

		useEffect(() => {
			const fadeTimer = setTimeout(() => {
				//window.click()
				setVisible(true)
			}, visibleTimeout)
	
			return () => clearTimeout(fadeTimer)
		}, []) // eslint-disable-line

		return <article 
			className={`panel ${className}`} 
			onClick={onClick}
			data-visible={visible}
			{...rest}
			>
				<Panel.Title>{title}</Panel.Title>
				<Panel.Subtitle>{subtitle}</Panel.Subtitle>
				{children}
		</article>
	})`
	width: 100%;
	padding: var(--panel--padding, 2.4rem);
	border-radius: var(--panel--border-radius, 0.7rem);
	cursor: ${({onClick}) => onClick ? 'pointer' : 'cursor' };
	opacity: 1;
	transition: all 0.2s ease-out;
	background: var(--panel--background--color, grey);

	& + .panel{ margin-top: 1.6rem; }

	&[data-visible="false"]{
		opacity: 0;
		transform: translateY(1.5%);
	}
	`

Panel.Title = styled(
	({extra, children, className, ...rest}) => {
		return !!children || !!extra
			? <div className={`panel-title ${className}`}>
				{children && <h1 className={`-title`}>{children}</h1>}
				{extra && <span className="-extra">{extra}</span>}
			</div>
			: null
	})`

	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3rem;
	
	.-title{
		font-size: var(--panel--title--font-size, 20px);
		color: var(--panel--title--color, white);
		margin: 0;
		line-height: 1em;
	}
	
	& + hr{
		margin-top: 3rem
	}

	& + .-subtitle{
		margin-top: -1.5rem
	}
	`

Panel.Subtitle = styled(
	({extra, children, className, ...rest}) => !!children && <h2 className={`-subtitle ${className}`}>{children}</h2>)`
	display: block;
	margin-bottom: 2.2rem;
	font-size: var(--panel--subtitle--font-size, 16px);
	color: var(--panel--subtitle--color, lightgrey);
	line-height: 1.4em;
	font-weight: 200;

	a{
		color: var(--color-primary);
	}

	& + .panel-divider{
		margin-top: 2rem
	}
	`

Panel.Divider = styled(
	({className, ...rest}) => <hr className={`panel-divider ${className}`}/>)`
	margin: ${({small}) => !!small ? '2rem' : '4rem'} 0;
	
	${({full}) => full && 
		`
		margin-left: calc(0rem - var(--panel--padding, 2.4rem));
		margin-right: calc(0rem - var(--panel--padding, 2.4rem));
		`
	}
	`



Panel.Footer = styled(
	({nav, children, className, ...rest}) => <div 
		className={`layout-panel-footer ${className}`} 
		{...rest}
		>
		{children}
		</div>
	)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin-top: 2.6rem;
	
	.panel-footer-group{
		width: 100%;
		text-align: center;
		&:first-child{ text-align: left }
		&:last-child{ text-align: right }
	}
	
	`

Panel.Footer.Group = styled(
	({children, className, ...rest}) => <div 
		className={`panel-footer-group ${className}`} 
		{...rest}
		>
		{children}
	</div>
	)`
	
	`

Panel.Divider = styled(
	({children, className, ...rest}) => <div 
		className={`panel-divider ${className}`} 
		{...rest}
		>
		{children && <span className="children">{children}</span>}
	</div>
	)`
	position: relative;
	display: block;
	margin: ${({small}) => !!small ? '3rem 0 2rem' : '4rem 0 3rem'} 0;
	margin-left: calc(0rem - var(--panel--padding, 2.4rem));
	margin-right: calc(0rem - var(--panel--padding, 2.4rem));

	${({children}) => {
		return React.Children.count(children) > 0 
			// children
			? `
				background: #192a3e;
			`
			// no children
			: `
				height: 1px;
				background: none;

			`
	}}

	.children{
		display: block;
		padding-left: var(--panel--padding, 2.4rem);
		padding-right: var(--panel--padding, 2.4rem);
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: var(--panel--padding, 2.4rem);
		width: calc(100% -  var(--panel--padding, 2.4rem)*2);
		border-top: 1px solid #192a3e;

		${({light}) => (
			light && `
				border-top-color: rgba(255,255,255,0.1)
			`
		)}
	}
	`

Panel.Button = styled(
	({icon, to, children, horizontal, className, ...rest}) => <Link to={to} style={{display: 'block', width: '100%'}} className={`layout-panelbutton ${className}`}>
		<Panel 
			
			{...rest}
			>
			{icon}
			<div className='button'>
				<span>{children}</span>
				<ArrowRight className='arrow'/>
			</div>
		</Panel>
	</Link>
	)`
	user-select: none;
	cursor: pointer;
	transition: all 0.3s ease-in, background 0.15s ease-in-out;

	>.panel{
		>svg{
			width: 6rem;
			height: 6rem;
		}
		
		>.button{
			display: flex;
			font-size: var(--panel-button--font-size, 16px);
			justify-content: space-between;
			margin-top: 2rem;
			margin-bottom: 0;

			>span{
				white-space: wrap;
				line-height: 1.5em;
			}

			>.arrow{
				width: 2.4rem;
				height: 2.4rem;
				transition: all 0.15s ease-in-out;
				margin-left: 1rem;
			}
		}

		&:hover{
			background: var(--panel-button--background--color-hover, grey);
			> .button > .arrow{
				transform: translateX(0.2em);
			}
		}

		${({horizontal}) => (
			horizontal && `
				display: flex;
				justify-content: space-between;
				align-items: center;

				>svg{
					width: 3rem;
					height: 3rem;
				}

				>.button {
					margin-top: 0rem;
				}
			`
		)}

		
	}
	

	
	
	`

Panel.Filler = styled.div`
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	height: 45px;
`

export default Panel