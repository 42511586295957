import React, { useState, useEffect, Fragment } from 'react';
import { Panel, Grid, Stat, GateKeeper, LazyBoi } from '@components'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import assetController from '@app/App.Controller'

export default  () => {

	const { perlinx } = assetController
	const [ incentives, setIncentives ] = useState({})

	useEffect(() => {
		const sub = perlinx.state.subscribe('incentivesSummary', setIncentives, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line
	
	return <Panel 
		title='PERL Liquidity Incentives' 
		fadeIn
		>
		<GateKeeper
			assertions={[
				{
					assert: () => !!Object.keys(incentives).length,
					onFail: () => <Fragment>
						<IconRefresh className='rotate'/>
						Fetching incentives summary
					</Fragment>,
				}
			]}
			dependencies={[incentives]}
			>
			<Grid cols={2}>
				<Stat title='Current Era'>
					<LazyBoi 
						value={incentives?.currentEra}
					/>
				</Stat>
				<Stat title='Member Count'>
					<LazyBoi 
						value={incentives?.memberCount}
					/>
				</Stat>
				<Stat title='Current Rewards'>
					<LazyBoi.Wei
						value={incentives?.rewardsAvailable} 
						suffix={'PERL'} 
						dp={0}
					/>
				</Stat>
				<Stat title='Total Perl Staked'>
					<LazyBoi.Wei
						value={incentives?.totalPerlStaked} 
						suffix={'PERL'}
						dp={0}
					/>
				</Stat>
			</Grid>
		</GateKeeper>
	</Panel>
}