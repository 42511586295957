import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Asset } from '@archetypes'

export default () => {
	let { path } = useRouteMatch();
	return <Switch>
		<Route exact path={path} component={Asset.Index}/>
		<Route path={`${path}/liquidate/:address/:sponsor`} component={Asset.Liquidate}/>
		<Route path={`${path}/mint/:address`} component={Asset.Mint}/>
		<Route path={`${path}/add/:address`} component={Asset.Increase}/>
		<Route path={`${path}/remove/:address`} component={Asset.Decrease}/>
		<Route path={`${path}/withdraw/:address`} component={Asset.Withdraw}/>
		<Route path={`${path}/redeem/:address`} component={Asset.Redeem}/>
		<Route path={`${path}/redeemAll/:address`} component={Asset.RedeemAll}/>
		<Redirect to={path}/>
	</Switch>
}