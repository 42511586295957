import React from 'react'
import styled from 'styled-components'

const Tag = styled(
	({children, className}) => <span className={`tag ${className}`}>{children}</span>
	)`
	display: inline-block;
	padding: 0.6rem 1.4rem;
	text-transform: uppercase;
	font-size: var(--tag--font-size, 12px);
	text-align: center;
	line-height: 1em;
	border-radius: var(--tag--border-radius, 0.2rem);
	user-select: none;

	background: var(--tag--success--background-color, lightgrey);
	color: var(--tag--success--color, grey);
	`

Tag.Success = styled(Tag)`
	background: var(--tag--success--background-color, lightgreen);
	color: var(--tag--success--color, green);
`

Tag.Warning = styled(Tag)`
	background: var(--tag--warning--background-color, yellow);
	color: var(--tag--warning--color, white);
`

Tag.Danger = styled(Tag)`
	background: var(--tag--danger--background-color, pink);
	color: var(--tag--danger--color, red);
`

Tag.Neutral = styled(Tag)`
	background: #979797;
	color: white;
`

Tag.Info = styled(Tag)`
	background: #5bc0de;
	color: white;
`

Tag.Orange = styled(Tag)`
	background: #f0ad4e;
	color: white;
`

Tag.Expired = styled(Tag)`
	background: #465b4b;
	color : white;
`


export default Tag