import React, { useState, useEffect } from 'react'
import { Panel, Grid } from '@components'
import appController from '@app/App.Controller'
import { ReactComponent as Mint } from '@assets/Icon_Mint.svg'
import { ReactComponent as Increase } from '@assets/Icon_Manage.svg'
import { ReactComponent as Withdraw } from '@assets/Icon_Withdraw.svg'
import { ReactComponent as Redeem } from '@assets/Icon_Redeem.svg'
import { fromWei } from "@util/helpers"

const Button = ({address, icon, to, children, callback=()=>false, className}) => {

	const { synthetics, wallet } = appController
	const [ visible, setVisible ] = useState(false)

	useEffect(() => {
		let walletSub
		const sub = synthetics.state.subscribe(`emps.${address}`, asset => {
			walletSub = wallet.state.subscribe(`balances.${asset?.token1?.symbol}`, balance => {
				const visible = callback({
					balance,
					...asset
				})
				setVisible(visible)
			}, true)
		}, true)
		return () => {
			sub.unsubscribe()
			if (walletSub) {
				walletSub.unsubscribe()
			}
		}
	}, []) // eslint-disable-line

	return visible === true
		? <Panel.Button 
			fadeIn 
			className={`asset-button ${className}`} 
			icon={icon} 
			to={to}
			horizontal
			>
			{children}
		</Panel.Button>
		:null
}

const ButtonMint = ({address}) => <Button address={address} icon={<Mint/>} to={`/assets/mint/${address}`} callback={({myPosition}) => !!myPosition && !myPosition.expired}>Mint Assets</Button>
const ButtonAdd = ({address}) => <Button address={address} icon={<Increase/>} to={`/assets/add/${address}`} callback={({myPosition}) => +myPosition?.collateral > 0 && !myPosition.expired}>Add Collateral</Button>
const ButtonWithdraw = ({address}) => <Button address={address} icon={<Withdraw/>} to={`/assets/withdraw/${address}`} callback={({myPosition}) => +myPosition?.collateral > 0 && !myPosition.expired}>Withdraw Collateral</Button>
const ButtonIncrease = ({address}) => <Button address={address} icon={<Increase/>} to={`/assets/increase/${address}`} callback={({myPosition}) => +myPosition?.collateral > 0 && !myPosition.expired}>Add Tokens</Button>
const ButtonDecrease = ({address}) => <Button address={address} icon={<Withdraw/>} to={`/assets/decrease/${address}`} callback={({myPosition}) => +myPosition?.collateral > 0 && !myPosition.expired}>Burn Tokens</Button>

// requires position to have more tokens than minSponsorTokens
const ButtonRedeem = ({address}) => <Button 
	address={address} 
	icon={<Redeem/>} 
	to={`/assets/redeem/${address}`} 
	callback={({myPosition}) => +myPosition?.collateral > 0 && !myPosition?.expired }
	>Redeem Assets
</Button>

const ButtonRedeemAll = ({address}) => <Button 
	address={address} 
	icon={<Redeem/>} 
	to={`/assets/redeemAll/${address}`} 
	callback={(props) => {
		const { myPosition, balance } = props
		return ((+myPosition?.collateral > 0 || (balance?.balance !== "0")) && myPosition?.expired)
	} }
	>Redeem all Assets
</Button>

const ButtonGroup = ({address, cols=2}) => {
	return (
		<Grid cols={cols}>
			<Button.Mint address={address}/>
			<Button.Increase address={address}/>
			<Button.Decrease address={address}/>
			<Button.Withdraw address={address}/>
			<Button.Redeem address={address}/>
			<Button.RedeemAll address={address}/>
		</Grid>
	)
}

Button.Mint = ButtonMint
Button.Add = ButtonAdd
Button.Increase = ButtonIncrease
Button.Decrease = ButtonDecrease
Button.Redeem = ButtonRedeem
Button.Group = ButtonGroup
Button.Withdraw = ButtonWithdraw
Button.RedeemAll = ButtonRedeemAll
	
export default Button