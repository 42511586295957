import React, { useState } from 'react';
import { Button, Field, Panel } from '@components'
import assetController from '@app/App.Controller'

export default  () => {
	
	const { perlinx } = assetController
	const [ amount, setAmount ] = useState(0)

	return <Panel title='Remove PERL Rewards from Incentives'>
		<Field.Input 
			title='PERL'
			placeholder='100000'
			type='number'
			value={amount}
			onChange={setAmount}
		/>
		<Panel.Footer>
			<Button.Danger onClick={() => perlinx.removeAllRewards()}>Remove All Rewards</Button.Danger>
			<Button.Primary onClick={() => perlinx.removeRewards(amount)}>Remove Reward</Button.Primary>
		</Panel.Footer>
	</Panel>
}