import React from 'react';
import './Tooltip.css'

const Tooltip = ({children, text}) => {
    return (
        <div className="tooltip">
            {children}
            <span className="tooltipText"> {text}</span>
        </div>
    );
};

export default React.memo(Tooltip);
