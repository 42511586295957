import React, { useState, useEffect, Fragment } from 'react';
import { Amount, Grid, Panel, Stat, GateKeeper, LazyBoi, Notification } from '@components'
import { Asset } from '@archetypes'
import { fromWei } from '@util/helpers'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import assetController, { Status } from '@app/App.Controller'

import { 
	StakeController, 
} from './_controller'

export default ({address}) => {
	const { balancer } = assetController

	// approval params
	const [ approvalStatus, setApprovalStatus ] = useState()
	const [ approvalParams, setApprovalParams ] = useState()
	const [ controller, setController ] = useState()
	const [ stakeParams, setStakeParams ] = useState()

	useEffect(() => {
		const stakeController = new StakeController(address, assetController)
		stakeController.on('approvals', setApprovalParams)
		stakeController.on('update', setStakeParams)
		setController(stakeController)
		stakeController.init()
		return () => stakeController.cleanup()
	}, []) // eslint-disable-line
	
	return <Panel>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to start staking',
				}
			]}
			assertions={[
				{
					assert: () => !!stakeParams?.token0?.balance,
					onFail: () => <Fragment><Refresh className='rotate'/> Fetching account info</Fragment>,
				},
				{
					assert: () => stakeParams?.token0Balance > 0,
					onFail: () => <Fragment>You have no {stakeParams?.token0?.symbol||'tokens'} to stake</Fragment>,
				}
			]}
			dependencies={[stakeParams]}
			>

			<Amount.Group separator='+'>
				<Amount 
					title={stakeParams?.token0?.symbol} 
					info={
						<LazyBoi
							prefix={'Balance:'} 
							value={stakeParams?.token0Balance} 
							suffix={stakeParams?.token0?.symbol}
							fromWei
						/>
					} 
					min={0.1}
					max={+fromWei(stakeParams?.token0Balance)}
					onChange={pc => controller?.updateInputPercent(pc)}
					withPresets
					asPercent
				/>
				<Amount 
					title={
						<LazyBoi
							value={stakeParams?.token1?.symbol||''} 
							suffix={!stakeParams?.toAmount && <Refresh className='rotate'/>}
						/>
					}
					info={
						<LazyBoi
							prefix={'Balance:'} 
							value={stakeParams?.token1Balance} 
							suffix={stakeParams?.token1?.symbol}
							fromWei
						/>
					} 
					value={stakeParams?.toAmount}
					min={0}
					disabled
				/>
			</Amount.Group>
			
			<Panel.Divider>
				<Asset.ContractApproval
					approvals={approvalParams}
					onUpdate={setApprovalStatus}
				/>
			</Panel.Divider>
			
			<Panel.Footer>
				<Panel.Footer.Group>
					<Grid cols={3}>
						<Stat title='Est. Tokens' small>
							<LazyBoi value={stakeParams?.est?.units} dp={4}/>
						</Stat>
						<Stat title='Est. Share' small>
						 	<LazyBoi.Percent value={stakeParams?.est?.share}/>
						 </Stat>
						<Stat title='Est. Value' small>
							<LazyBoi.Currency value={stakeParams?.est?.value}/>
						</Stat>
					</Grid>
				</Panel.Footer.Group>

				<Panel.Footer.Group>
					<Asset.ContractApproval.Button
						{...approvalStatus}
						onClick={() => {
							if(+stakeParams.toAmount > +stakeParams.token1Balance){
								Notification.warning({
									title: 'Insufficent funds', 
									text: `You have an insuficient ${stakeParams.token1.symbol} balance (${stakeParams.token1Balance}) to make this transaction`
								})
							}else{
								balancer.stake(stakeParams.poolAddress, stakeParams.tx)
							}
						}}
						>
						Stake in pool
					</Asset.ContractApproval.Button>
				</Panel.Footer.Group>
			</Panel.Footer>
		</GateKeeper>
	</Panel>
}