import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components'
import { Field, Panel, Layout, LazyBoi, GateKeeper,Grid, Stat } from '@components'
import { Asset } from '@archetypes'
import { IncreaseController } from './_controller'
import appController, { Status } from '@app/App.Controller'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import { fromWei , BN, toWei } from '@util/helpers'


const Main = styled(
	({address, ...rest}) => {

		const { synthetics } = appController

		// approval params
		const [ approvalStatus, setApprovalStatus ] = useState()
		const [ approvals, setApprovals ] = useState()
		const [ controller, setController ] = useState()
		const [ params, setParams ] = useState()

		useEffect(() => {
			const increaseController = new IncreaseController(address, appController)
			increaseController.on('approvals', setApprovals)
			increaseController.on('params', setParams)
			setController(increaseController)
			increaseController.init()
			return () => increaseController.cleanup()
		}, [address]) // eslint-disable-line

		const estimatedCR = useMemo(() => {
			try {
				const collateral = (params.myPosition.collateral)
				const ratio = toWei(params.myPosition.actualCurrentRatio)
				const newRatio = BN(collateral).plus(BN(toWei(params.amount))).times(ratio).dividedBy(collateral)
				return fromWei(newRatio.toFixed(0))
				
			} catch (e) {
			}
			return 0
		},[params])

		return <Panel fadeIn {...rest}>
			<GateKeeper
				controllerAssertions={[
					{
						controller: 'account',
						key: 'status',
						assert: val => val === Status.READY,
						onFail: <Panel fadeIn>Connect account to increase your collateral</Panel>,
					},
					
				]}
				assertions={[
					{
						assert: () => !!params,
						onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching asset info</Panel>,
					}
				]}
				dependencies={[params]}
				>
				<Field.Input.Number
					title='Perl to add'
					info={<LazyBoi prefix={'MAX: '} value={params?.balance} suffix={params?.symbol} dp={2}/>}
					value={params?.amount} 
					className={'amount'}
					onChange={val => controller?.updateInputAmount(val)}
					min={params?.min}
					max={params?.balance}
				/>

				<Panel.Divider light>
					<Asset.ContractApproval
						approvals={approvals}
						onUpdate={setApprovalStatus}
					/>
				</Panel.Divider>

				<Panel.Footer>
					<Panel.Footer.Group>
						{/*<Grid cols={3}>
							<Stat title='New CR' small>
								<LazyBoi value={params?.cr} dp={2}/>
							</Stat>
							<Stat title='New GCR' small>
								<LazyBoi value={params?.gcr} dp={2}/>
							</Stat>
						</Grid>*/}
							<Stat title='New Collateralization Ratio' small>
								<LazyBoi value={estimatedCR * 100} dp={2} suffix={"%"}/>
							</Stat>
					</Panel.Footer.Group>
					<Panel.Footer.Group>
						<Asset.ContractApproval.Button
							{...approvalStatus}
							onClick={() => synthetics.increase(params?.tx)}
							>
							Add Collateral
						</Asset.ContractApproval.Button>
					</Panel.Footer.Group>
				</Panel.Footer>
			</GateKeeper>
		</Panel>
	})`
	
	`

export default ({className}) => {
	const { address } = useParams();

	return <Layout back title='Add Collateral' className={className}>
		<Layout.Aside>
			<Asset.Position address={address}/>
			<Asset.Stats address={address}/>
		</Layout.Aside>
		<Layout.Main>
			<Main address={address}/>
		</Layout.Main>
		
	</Layout>
}