import SubsAndEventsClass from '@util/SubsAndEventsClass.js'
import { fromWei } from '@util/helpers'

export class UpgradeController extends SubsAndEventsClass{
	_NETWORK
	_WALLET
	_BALANCE
	_TOKENS
	_PERL_LEGACY_UNIT = 'gwei'
	
	constructor({network, wallet, tokens}){
		super()
		this._NETWORK = network
		this._WALLET = wallet
		this._TOKENS  =tokens
	}

	init(){
		const walletSub = this._WALLET.state.subscribe(`perlLegacyBalance`, async balance => {
			this._BALANCE = balance
			
			const networkSub = this._NETWORK.state.subscribe(`current`, async ({PERL_LEGACY, PERL, PERL_LEGACY_UNIT='gwei'}) => {
				// check the legacy units
				this._PERL_LEGACY_UNIT = PERL_LEGACY_UNIT

				// check approved to transfer
				let approved = await this._TOKENS.isApproved(PERL, PERL_LEGACY)
				this._update(approved)
				
				if(+this._BALANCE > 0 && !approved) {
					this._calculateApprovals()
				}
			}, true)
			this._SUBSCRIPTIONS.push(networkSub)

		}, true)
		this._SUBSCRIPTIONS.push(walletSub)
	}


	// private
	_update(approved){
		this.dispatchEvent('details', {	
			balance: fromWei(this._BALANCE, this._PERL_LEGACY_UNIT),
			approved: approved
		});

	}

	_calculateApprovals(){
		const sub = this._NETWORK.state.subscribe(`current`, async ({PERL_LEGACY, PERL}) => {
			const approval = {
				name: 'PERL',
				toApprove: PERL,
				onContract: PERL_LEGACY
			}

			this.dispatchEvent('approval', approval);
		})

		this._SUBSCRIPTIONS.push(sub)
	}
}