import React, { useState, useEffect, Fragment } from 'react';
import { GateKeeper, Amount, Panel, LazyBoi } from '@components'
import { Asset } from '@archetypes'
import { fromWei } from '@util/helpers'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import assetController, { Status } from '@app/App.Controller'

import { 
	LockController, 
} from './_controller'

export default ({address}) => {
	const { 
		perlinx, 
	} = assetController

	// approval params
	const [ approvalStatus, setApprovalStatus ] = useState()
	const [ approvalParams, setApprovalParams ] = useState()
	const [ controller, setController ] = useState()
	const [ lockParams, setLockParams ] = useState()

	useEffect(() => {
		const lockController = new LockController(address, assetController)
		lockController.on('approvals', setApprovalParams)
		lockController.on('update', setLockParams)
		setController(lockController)
		lockController.init()
		return () => lockController.cleanup()
	}, []) // eslint-disable-line

	return <Panel>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet lock tokens',
				}
			]}
			assertions={[
				{
					assert: () => !!lockParams,
					onFail: () => <Fragment><IconRefresh className='rotate'/>Fetching pool data</Fragment>,
				}
			]}
			dependencies={[lockParams]}
			>

			<Amount 
				title={'Amount to lock'} 
				info={<LazyBoi prefix="Balance:" value={lockParams?.balance} dp={4} fromWei/>} 
				min={0}
				max={fromWei(lockParams?.balance)}
				onChange={pc => controller?.updateInputPercent(pc)}
				withPresets
				asPercent
			/>
			
			<Panel.Divider>
				<Asset.ContractApproval
					approvals={approvalParams}
					onUpdate={setApprovalStatus}
				/>
			</Panel.Divider>

			<Panel.Footer>
				<Panel.Footer.Group/>
				<Panel.Footer.Group>
					<Asset.ContractApproval.Button
						{...approvalStatus}
						onClick={() => perlinx.lock(address, lockParams.tx)}
						>
						Lock Share
					</Asset.ContractApproval.Button>
				</Panel.Footer.Group>
			</Panel.Footer>
		</GateKeeper>
	</Panel>
}