import React, { useState } from 'react';
import { Button, Field, Panel } from '@components'
import { toWei } from '@util/helpers'
import assetController from '@app/App.Controller'

export default () => {
	
	const { perlinx } = assetController
	const [ amount, setAmount ] = useState(0)

	return <Panel title='Add PERL Rewards to Incentives'>
		<Field.Input 
			title='PERL'
			placeholder='100000'
			type='number'
			value={amount}
			onChange={setAmount}
		/>
		<Panel.Footer>
			<Panel.Footer.Group/>
			<Button.Primary onClick={() => perlinx.addRewards(toWei(amount))}>Add Rewards</Button.Primary>
		</Panel.Footer>
	</Panel>
}