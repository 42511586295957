import React, { useState, useEffect } from 'react';
import {Panel, GateKeeper, Table, AddressLink } from '@components'
import assetController from '@app/App.Controller'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';

export default () => {
	const { perlinx } = assetController
	const [ items, setItems ] = useState()

	useEffect(() => {
		const sub = perlinx.state.subscribe('pools', pools => {
			let items = Object.values(pools).map(({name, address}) => ({
				name: name, 
				address: address,
				link: <AddressLink address={address} text='View on etherscan'/>
			}))
			setItems(items)
		}, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel 
		title='Approved Pools' 
		>
		<GateKeeper
			assertions={[
				{
					assert: () => !!items,
					onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching data</Panel>,
				},
			]}
			dependencies={[items]}
			>
			<Table 
				columns={[
					{Header: 'Name', accessor: 'name'}, 
					{Header: 'Address', accessor: 'address'},
					{Header: '', accessor: 'link'}
				]} 
				data={items}/>
		</GateKeeper>
	</Panel>
}