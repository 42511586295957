import React, { useState, useEffect, useMemo } from 'react';
import {useParams} from 'react-router-dom';
import {Panel, Stat, Layout, LazyBoi, Amount, GateKeeper, Field} from '@components'
import {Asset} from '@archetypes'
import {RedeemController} from './_controller'
import appController, {Status} from '@app/App.Controller'
import {ReactComponent as Refresh} from '@assets/Icon_Refresh.svg';
import { roundDown } from "@util/helpers"

const Main = ({address, ...rest}) => {

    const {synthetics} = appController

    // approval params
    const [approvalStatus, setApprovalStatus] = useState()
    const [approvals, setApprovals] = useState()
    const [controller, setController] = useState()
    const [params, setParams] = useState()

    useEffect(() => {
        const redeemController = new RedeemController(address, appController)
        redeemController.on('approvals', setApprovals)
        redeemController.on('params', setParams)
        setController(redeemController)
        redeemController.init()
        return () => redeemController.cleanup()
    }, [address]) // eslint-disable-line

    const maxAmount = useMemo(() => {
        if (params && params.max) {
            return `${roundDown(params.max, 2)}`
        } else {
            return "0"
        }
    },[params]) // eslint-disable-line


    return <Panel fadeIn {...rest}>
        <GateKeeper
            controllerAssertions={[
                {
                    controller: 'account',
                    key: 'status',
                    assert: val => val === Status.READY,
                    onFail: <Panel fadeIn>Connect account to redeem your assets</Panel>,
                },
            ]}
            assertions={[
                {
                    assert: () => !!params,
                    onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching position information</Panel>,
                },
                {
                    assert: () => params?.userBalance !== "0",
                    onFail: () => <Panel fadeIn>No assets to redeem</Panel>,
                }
            ]}
            dependencies={[params]}
            >

            <Field.RadioGroup
                title={'Select Type'}
                value={params?.full||false}
                options={[
                    {
                        label: 'Redeem Partially',
                        value: false,
                        disabled: params?.canRedeemPartial === false
                    },
                    {
                        label: 'Redeem All (Exit Position)',
                        value: true,
                        disabled: params?.canRedeemFull === false
                    }
                ]}
                onChange={val => controller?.updateRedeemFull(val)}
            />

            <Amount
                title='Tokens to redeem'
                info={<LazyBoi prefix={!!params?.full ? 'TOTAL: ' : 'MAX: '} value={maxAmount}/>}
                value={params?.amount}
                onChange={val => controller?.updateInputAmount(val)}
                min={params?.min}
                max={params?.max}
                disabled={!!params?.full}
            />

            <Panel.Divider light>
                <Asset.ContractApproval
                    approvals={approvals}
                    onUpdate={setApprovalStatus}
                />
            </Panel.Divider>

            <Panel.Footer>
                <Panel.Footer.Group>
                    <Stat title='Collateral Amount' small>
                        <LazyBoi value={params?.value} suffix={params?.symbol} dp={2}/>
                    </Stat>
                </Panel.Footer.Group>
                <Panel.Footer.Group>
                    <Asset.ContractApproval.Button
                        {...approvalStatus}
                        disabled={!params?.canRedeemFull && !params?.canRedeemPartial}
                        onClick={() => synthetics.redeem(params?.tx)}
                    >
                        Redeem Assets
                    </Asset.ContractApproval.Button>
                </Panel.Footer.Group>
            </Panel.Footer>
        </GateKeeper>
    </Panel>
}


export default ({className}) => {
    const {address} = useParams();

    return <Layout back title='Redeem Assets' className={className}>
        <Layout.Aside>
            <Asset.Position address={address}/>
            <Asset.Stats address={address}/>
        </Layout.Aside>
        <Layout.Main>
            <Main address={address}/>
        </Layout.Main>
    </Layout>
}
