import React from 'react';
import { Redirect } from "react-router-dom";
import { Layout, Tabbed, Grid } from '@components'
import { Admin } from '@archetypes'

export default () => <Admin.GateKeeper 
	onFail={() => <Redirect to={'/'}/>}
	>
	<Layout>
		<Layout.Main>
			<Tabbed>
				<Tabbed.Child title={`Overview`}>
					<Admin.LiquidityIncentives/>
					<Admin.Addresses/>
					<Admin.ApprovedPools/>
				</Tabbed.Child>
				<Tabbed.Child title={`Manage Rewards`}>
					<Grid cols={2}>
						<Admin.AddRewards/>
						<Admin.SnapshotPools/>
					</Grid>
				</Tabbed.Child>
				<Tabbed.Child title={`Curate Pools`}>
					<Admin.ListTokenPool/>
					<Admin.ListSynthPool/>
					<Admin.DelistPool/>
				</Tabbed.Child>
				<Tabbed.Child title={`Synthetic Token`}>
					<Admin.CreateEMP/>
				</Tabbed.Child>
				<Tabbed.Child title={`Config`}>
					<Admin.Config/>
				</Tabbed.Child>
			</Tabbed>
		</Layout.Main>
	</Layout>
</Admin.GateKeeper>