import React, { useState } from 'react';
import { Button, Grid, Panel, Field } from '@components'
import assetController from '@app/App.Controller'

export default () => {

	const { perlinx } = assetController
	const [ poolAddress, setPoolAddress ] = useState()
	const [ synthAddress, setSynthAddress ] = useState()
	const [ empAddress, setEmpAddress ] = useState()
	const [ poolWeight, setPoolWeight ] = useState()

	return <Panel>
		<Grid cols={2} width={[30,70]}>
			<Panel.Title>List a SYNTH pool</Panel.Title>
			<span>
				<Field.Input.Address title='Pool address' onChange={val => setPoolAddress(val)}/>
				<Field.Input.Address title='Synth Address' onChange={val => setSynthAddress(val)}/>
				<Field.Input.Address title='EMP address' onChange={val => setEmpAddress(val)}/>
				<Field.Input.Number 
					title='Pool weight (Between 0.1 and 10.0, where 1 == normal)' 
					placeholder={'1'} 
					value={1} 
					min={0.1} 
					max={10}
					dp={1}
					onChange={setPoolWeight}/>
			</span>
		</Grid>
		<Panel.Footer>
			<Panel.Footer.Group/>
			<Panel.Footer.Group>
				<Button.Primary 
					onClick={() => perlinx.listSynth(poolAddress, synthAddress, empAddress, poolWeight)}
					>
					List SYNTH Pool
				</Button.Primary>
			</Panel.Footer.Group>
		</Panel.Footer>
	</Panel>
}