import React, { useEffect, useState } from 'react';
import { Tag } from "@components";
import assetController from '@app/App.Controller'

const NetworkTag = ({prefix, showMainnet}) => {

	const { network } = assetController
	const [ networkTag, setNetworkTag ] = useState()
	
	useEffect(() => {
		network.state.subscribe('current', ({name, enabled, ...rest}) => {
			if(!enabled){
				setNetworkTag(<Tag.Danger>{prefix} {name} (not supported)</Tag.Danger>)
			}else{
				if(name === 'Mainnet'){
					if(!!showMainnet){
						setNetworkTag(<Tag.Success>{prefix} {name}</Tag.Success>)
					}
				}else{
					setNetworkTag(<Tag.Warning>{prefix} {name}</Tag.Warning>)
				}
			}
		}, true)
	}, []) // eslint-disable-line

	return networkTag||null
}

const Name = () => {

	const { network } = assetController
	const [ name, setName ] = useState()
	
	useEffect(() => {
		network.state.subscribe('current', ({name, enabled, ...rest}) => {
			setName(name)
		}, true)
	}, []) // eslint-disable-line

	return <span>{name}</span>
}

NetworkTag.Name = Name


export default NetworkTag