import React , { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components'
import { Amount, Stat, Button, Panel, Layout,LazyBoi,  GateKeeper, Field } from '@components'
import { Asset } from '@archetypes'
import appController, {Status} from '@app/App.Controller'
import{ fromWei, BN  } from "@util/helpers"
import {ReactComponent as Refresh} from '@assets/Icon_Refresh.svg';
import {WithdrawController} from './_controller'

const Main = styled(
	({ address, ...rest }) => {

		const {synthetics} = appController

		// approval params
		const [approvalStatus, setApprovalStatus] = useState()
		const [approvals, setApprovals] = useState()
		const [controller, setController] = useState()
		const [params, setParams] = useState()

		useEffect(() => {
			const withdrawController = new WithdrawController(address, appController)
			withdrawController.on('approvals', setApprovals)
			withdrawController.on('params', setParams)
			setController(withdrawController)
			withdrawController.init()

			return () => withdrawController.cleanup()
		},[]) // eslint-disable-line

		const waitingHours = (Number(params?.withdrawalLiveness) / 3600).toFixed(0)
		const max = params?.fast ? Number(fromWei(params?.max)) : Number(fromWei(params?.myCollateral?.collateral))

		return <Panel fadeIn {...rest}>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: <Panel fadeIn>Connect account to withdraw your assets</Panel>,
				},
			]}
			assertions={[
                {
                    assert: () => !!params,
                    onFail: () => <Panel fadeIn><Refresh className='rotate'/>Fetching position information</Panel>,
                },
            ]}
            dependencies={[params]}
		>
			<Field.RadioGroupVertical
				title={'Select type of withdrawal'}
				value={params?.fast}
				options={[
					{
						label: 'Fast Withdrawal',
						sublabel: "Withdraw excess collateral but remain above the Global Collateral Ratio (GCR)",
						value: true,
					},
					{
						label: 'Slow Withdrawal (>'+waitingHours+' hrs.)' ,
						sublabel : "Withdraw collateral below Global Collateral Ratio (GCR). This. could take longer and you are at risk of liquidation if your Collateral Ratio <= Liquidation Ratio.",
						value: false,
						disabled : true
					}
				]}
				onChange={val => controller?.updateFastWithdraw(val)}
			/>

			<Amount
				title='Amount to withdraw (PERL)'
				info={<LazyBoi prefix={'MAX: '} suffix={params?.symbol} value={max}/>}
				className={'ratio'}
				onChange={val => controller?.updateInputAmount(val)}
				min={0}
				max={Number(max)}
				value={params?.amount}
				dp={2}
			/>

			<Panel.Divider light>
				<Asset.ContractApproval
					approvals={approvals}
					onUpdate={setApprovalStatus}
				/>
			</Panel.Divider>

			<Panel.Footer>
				<Panel.Footer.Group style={{flexDirection: "row"}}>
					<Stat title='New Collaterization Ratio' small>
                        <LazyBoi value={params?.newCRUsd ? params?.newCRUsd*100 : 0 } suffix={"%"} dp={2}/>
                    </Stat>
				</Panel.Footer.Group>
				<Panel.Footer.Group>
					{ params?.fast ?
					(
						<Button.Primary
							onClick={() => synthetics.fastWithdraw(params?.tx)}
							disabled={(params?.amount === 0)}
						>
							Withdraw Collateral
						</Button.Primary>
					)
					:
					(
						<>
							<Button.Primary
								onClick={() => {
									return synthetics.slowWithdrawSubmitRequest(params?.tx)
								}}
								disabled={!params?.isOngoingWithdraw }
							>
								Submit Requests
							</Button.Primary>

							<Button.Primary
								onClick={() => {
									return synthetics.slowWithdrawCancelWithdrawal(params?.tx)
								}}
								disabled={params?.isOngoingWithdraw }
							>
								Cancel Requests
							</Button.Primary>
						</>
					)}
					
				</Panel.Footer.Group>
			</Panel.Footer>
		</GateKeeper>
	</Panel>
		
	})`
	
	`

export default ({ className }) => {
	const { address } = useParams();

	return <Layout back title='Withdraw Collateral' className={className}>
		<Layout.Aside>
			<Asset.Position address={address} />
			<Asset.Stats address={address}/>
		</Layout.Aside>
		<Layout.Main>
			<Main address={address} />
		</Layout.Main>

	</Layout>
}