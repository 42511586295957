import Icon from './Icon'
import Value from './Value'
import Button from './Button'
import Index from './Items'
import Liquidate from './Liquidate'
import Redeem from './Redeem'
import RedeemAll from "./RedeemAll"
import Mint from './Mint'
import Increase from './Increase'
import Decrease from './Decrease'
import Withdraw from './Withdraw'
import Approval from './Approval'
import Position from './Position'
import ContractApproval from './ContractApproval'
import Stats from './Stats'

const Asset = () => {}

Asset.Icon = Icon
Asset.Value = Value
Asset.Button = Button
Asset.Index = Index
Asset.Liquidate = Liquidate
Asset.Redeem = Redeem
Asset.Mint = Mint
Asset.Increase = Increase
Asset.Decrease = Decrease
Asset.Withdraw = Withdraw
Asset.Approval = Approval
Asset.Position = Position
Asset.ContractApproval = ContractApproval
Asset.Stats = Stats
Asset.RedeemAll = RedeemAll

export default Asset