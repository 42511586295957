import React from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    padding-top: 20px;
    color: #d3d3d3;
    font-size: 12px; 
`

export default () => 
<Wrapper>
    PerlinX is new technology and in beta. Security audits do not completely eliminate risks.<br/>
    Using PerlinX is entirely at your own risk and Perlin is never liable for any losses that happen using PerlinX and our UI.
</Wrapper>
