import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components'
import Asset from './index.js'
import { LazyBoi, Button } from '@components'
import { BN } from '@util/helpers'
import appController from '@app/App.Controller'
import { fromWei, getRate } from "@util/helpers"
import useInterval from "../../hooks/useInterval"

const Value = styled(
	({ token = '', balance, rate, className, ...rest }) => <div
		className={className}
		{...rest}
	>
		<span className='-title'>
			<Asset.Icon
				token={token}
				text={
					<LazyBoi
						value={balance}
						suffix={token}
						dp={2}
						fromWei
					/>
				}
			/>
		</span>
		<span className='-text'>
			<LazyBoi prefix={"$"} value={BN(balance || 0).times(rate || 0)} fromWei dp={2} />
		</span>
	</div>
)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.3em 0;


	.-title{
		font-size: var(--asset-value--title--font-size, 14px);
	}

	.-text{
		font-size: var(--asset-value--text--font-size, 12px);
		color: var(--asset-value--text--color, white);
	}
	
	`

const ValueSynthetic = styled(
	({ address, className, ...rest }) => {

		const { synthetics, wallet } = appController
		const [emp, setEmp] = useState(false)
		const [balance, setBalance] = useState()

		useEffect(() => {
			let walletSub
			const empSub = synthetics.state.subscribe(`emps.${address}`, (emp) => {
				const pairTokenSymbol = emp?.token1?.symbol
				walletSub = wallet.state.subscribe(`balances.${pairTokenSymbol}`, setBalance, true)
				setEmp(emp)
			}, true)

			return () => {
				empSub.unsubscribe()
				if (walletSub) {
					walletSub.unsubscribe()
				}
			}
		}, []) // eslint-disable-line

		// Convert to string here so it won't crash LazyBoi
		const walletBalance = useMemo(() => {
			if (emp.token1 && balance) {
				const rate = getRate(emp.token0, emp.token1)
				return `${rate * fromWei(balance.balance)}`
			}
			return "0.0"
		}, [emp, balance])

		return <div
			className={className}
			{...rest}
		>
			<span className='-title'>
				<Asset.Icon
					token={emp?.token1?.symbol}
					text={
						<LazyBoi
							value={balance?.balance}
							suffix={emp?.token1?.symbol}
							dp={2}
							fromWei
						/>
					}
				/>
			</span>
			<span className='-text'>
				<LazyBoi
					value={walletBalance}
					prefix={"$"}
					dp={2}
				/>
			</span>
		</div>
	})`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.3em 0;


	.-title{
		font-size: var(--asset-value--title--font-size, 14px);
		white-space: nowrap;
		max-width: 65%;
		.text{
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
		}
	}

	.-text{
		font-size: var(--asset-value--text--font-size, 12px);
		color: var(--asset-value--text--color, white);
		max-width: 35%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: left;
	}
	
	`


const ValueWithdraw = styled(
	({ address, requestAmount, requestTimestamp, className, ...rest }) => {

		const { synthetics } = appController

		const [ ready, setReady] = useState(false)
		const [timeleft, setTimeleft] = useState("00:00:00")

		useInterval(() => {
			const diff = (Number(requestTimestamp)*1000) - new Date().valueOf()
			if (diff > 0) {
				const totalSeconds = (diff)/ 1000
				const hours = Math.floor(totalSeconds/3600)
				const minutes = Math.floor((totalSeconds%3600)/60)
				const seconds = Math.floor((totalSeconds%3600)%60)
				setTimeleft(`${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`)
			} else {
				setReady(true)
			}

		}, 1000);

		return <div
			className={className}
			{...rest}
		>
			<span className='-title'>
				<LazyBoi
					value={requestAmount}
					suffix={"PERL"}
					dp={2}
					fromWei
				/>
			</span>
			 <span className='-text'>
				 { ready 
				 ?
				 <Button.Primary
					onClick={() => synthetics.slowWithdrawConfirmWithdrawal({
						address : address
					})}
				>
					Withdraw
				</Button.Primary>
				 :
					<>
						Time left: {timeleft}
					</>
				 }
			</span> 

		</div>
	})`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.3em 0;


	.-title{
		font-size: var(--asset-value--title--font-size, 14px);
		white-space: nowrap;
		max-width: 50%;
		.text{
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
		}
	}

	.-text{
		font-size: var(--asset-value--text--font-size, 12px);
		color: var(--asset-value--text--color, white);
		max-width: 50%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: left;
	}
	
	`
	
Value.Synthetic = ValueSynthetic
Value.Withdraw = ValueWithdraw


export default Value