import Button from './Button'
import Pools from './Pools'
import Balance from './Balance'
import CDPs from './CDPs'
import Assets from './Assets'
import Transactions from './Transactions'

const Account = () => {}

Account.Button = Button
Account.Pools = Pools
Account.Balance = Balance
Account.CDPs = CDPs
Account.Assets = Assets
Account.Transactions = Transactions


export default Account