export default class {
	_SUBSCRIPTIONS = []
	_EVENTS = []

	// events
	dispatchEvent(type, payload){
		const events = this._EVENTS[type]||[]
		events.forEach(cb => cb(payload))
	}

	on(type, cb=()=>{}){
		if(!this._EVENTS[type]){
			this._EVENTS[type] = []
		}
		this._EVENTS[type].push(cb);
	}

	cleanup(){
		this._SUBSCRIPTIONS.forEach(sub => {
			sub.unsubscribe()
		})
	}
}