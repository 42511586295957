import React, { useState } from 'react';
import { Button, Field, Panel, Grid } from '@components'
import assetController from '@app/App.Controller'

const Add = () => {
	
	const { perlinx } = assetController
	const [ address, setAddress ] = useState('')
	
	return <Panel title='Add Admin'>
		<Field.Input.Address 
			title='Enter Address'
			value={address}
			onChange={setAddress}
		/>
		<Panel.Divider/>
		<Panel.Footer>
			<Panel.Footer.Group/>
			<Panel.Footer.Group>
				<Button.Primary
					onClick={() => perlinx.addAdmin(address)}
					>
					Add Admin
				</Button.Primary>
			</Panel.Footer.Group>
		</Panel.Footer>
	</Panel>
}

const Transfer = () => {
	
	const { perlinx } = assetController
	const [ address, setAddress ] = useState('')
	
	return <Panel title='Transfer Admin Rights'>
		<Field.Input.Address 
			title='Enter Address'
			value={address}
			onChange={setAddress}
		/>
		<Panel.Divider/>
		<Panel.Footer>
			<Panel.Footer.Group/>
			<Panel.Footer.Group>
				<Button.Danger
					onClick={() => perlinx.transferAdmin(address)}
					>
					Transfer Admin
				</Button.Danger>
			</Panel.Footer.Group>
		</Panel.Footer>
	</Panel>
}

export default () => <Grid cols={2}>
	<Add/>
	<Transfer/>
</Grid>

