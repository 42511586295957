import LiquidityIncentives from './LiquidityIncentives'
import Addresses from './Addresses'
import ApprovedPools from './ApprovedPools'
import AddRewards from './AddRewards'
import RemoveRewards from './RemoveRewards'
import SnapshotPools from './SnapshotPools'
import ListTokenPool from './ListTokenPool'
import ListSynthPool from './ListSynthPool'
import DelistPool from './DelistPool'
import CreateEMP from './CreateEMP'
import Config from './Config'
import Link from './Link'
import GateKeeper from './GateKeeper'

const Admin = () => {}

Admin.LiquidityIncentives = LiquidityIncentives
Admin.Addresses = Addresses
Admin.ApprovedPools = ApprovedPools
Admin.AddRewards = AddRewards
Admin.RemoveRewards = RemoveRewards
Admin.SnapshotPools = SnapshotPools
Admin.ListTokenPool = ListTokenPool
Admin.ListSynthPool = ListSynthPool
Admin.DelistPool = DelistPool
Admin.CreateEMP = CreateEMP
Admin.Config = Config
Admin.Link = Link
Admin.GateKeeper = GateKeeper

export default Admin