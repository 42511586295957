import React from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components'
import { Amount, Button, Field, Panel, Stat, Layout } from '@components'
import { Asset } from '@archetypes'
//import appController from '@app/App.Controller'

const Main = styled(
	({address, ...rest}) => {

		// const { synthetics } = appController
		// const [ item, setItem ] = useState(false)
		// const min = 102
		// const max = 200

		// useEffect(() => {
		// 	const sub = synthetics.state.subscribe(`assets.${address}`, setItem, true)
		// 	return () => sub.unsubscribe()
		// }, []) // eslint-disable-line

		return <Panel fadeIn {...rest}>
			<Field.Input 
				title='Tokens to remove' 
				value={100} 
				className={'amount'}
			/>

			<Amount 
				title='Collateralization Ratio (%)'
				info={`Min:%`}
				className={'ratio'}
				//min={min}
				//max={max}
				withPresets
				dp={2}
				disabled
				presets={[
					['110%', 0.55],
					['125%', 0.625],
					['150%', 0.75],
					['200%', 1],
				]}
			/>

			<Panel.Divider light>
				{/*<Asset.ContractApproval
					//approvals={approvalParams}
					//onUpdate={setApprovalStatus}
				/>*/}
			</Panel.Divider>

			<Panel.Footer>
				<Panel.Footer.Group>
					<Stat title='Unlock' small>200 PERL</Stat>
				</Panel.Footer.Group>
				<Panel.Footer.Group>
					<Button.Primary>Decrease Collateral</Button.Primary>
				</Panel.Footer.Group>
			</Panel.Footer>
		</Panel>
	})`
	
	`

export default ({className}) => {
	const { address } = useParams();

	return <Layout back title='Decrease Collateral' className={className}>
		<Layout.Main>
			<Main address={address}/>
		</Layout.Main>
		<Layout.Aside>
			<Asset.Position address={address}/>
		</Layout.Aside>
	</Layout>
}