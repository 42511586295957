import React, { useState, useEffect } from 'react';
import {Panel, Grid, GateKeeper, Table } from '@components'
import assetController from '@app/App.Controller'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';

export default () => {
	const { perlinx, network } = assetController
	const [ admins, setAdmins ] = useState([])
	const [ perlAddress, setPerlAddress ] = useState()
	const [ perlinxAddress, setPerlinxAddress ] = useState()

	useEffect(() => {
		network.state.subscribe(
			'current', 
			network => {
				setPerlAddress(network.PERL)
				setPerlinxAddress(network.PERLX)
			},
			true 
		)
		perlinx.fetchAdmins(admins => {
			setAdmins(admins.map(admin => ({address: admin})))
		})
	}, []) // eslint-disable-line

	return <Panel 
		title='Addresses' 
		>
		<GateKeeper
			assertions={[
				{
					assert: () => admins?.length > 0,
					onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching data</Panel>,
				},
				{
					assert: () => !!perlAddress,
					onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching PERL address</Panel>,
				},
				{
					assert: () => !!perlinxAddress,
					onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching PerlinX address</Panel>,
				}
			]}
			dependencies={[admins?.length]}
			>
			<Grid cols={3}>
				<Table columns={[{Header: 'Admin Addresses', accessor: 'address'}]} data={admins} options={{pageSize: 5}}/>
				<Table columns={[{Header: 'PERL Address', accessor: 'address'}]} data={[{address: perlAddress}]}/>
				<Table columns={[{Header: 'PerlinX Address', accessor: 'address'}]} data={[{address: perlinxAddress}]}/>
			</Grid>
		</GateKeeper>
	</Panel>
}