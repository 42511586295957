import React, { useState, useEffect } from 'react';
import { Button, Grid, Panel, Field } from '@components'
import assetController from '@app/App.Controller'

export default () => {

	const { perlinx } = assetController
	const [ poolAddress, setPoolAddress ] = useState()
	const [ pools, setPools ] = useState([])

	useEffect(() => {
		const sub = perlinx.state.subscribe('pools', pools => {
			const poolList = Object.values(pools).map(pool => ({key: pool.address, value: `${pool.name} | ${pool.address}`}))
			setPools([{key: -1, value: 'Select Pool'}, ...poolList])
		}, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Panel>
		<Grid cols={2} width={[30,70]}>
			<Panel.Title>Delist any PERL pool</Panel.Title>
			<span>
				<Field.Select 
					title='Pool address' 
					onChange={setPoolAddress}
					options={pools}
				/>
			</span>
		</Grid>
		<Panel.Footer>
			<Panel.Footer.Group/>
			<Button.Primary 
				onClick={() => perlinx.delistPool(poolAddress)}
				>
				Delist TOKEN Pool
			</Button.Primary>
		</Panel.Footer>
	</Panel>
}