import { toWei, fromWei, BN } from '@util/helpers'
import moment from 'moment'
import SubsAndEventsClass from '@util/SubsAndEventsClass.js'

export class MintController extends SubsAndEventsClass{
	_SYNTHETICS
	_WALLET
	_EMP
	_TOKEN_PRICE
	_OPTIONS = true
	
	INPUT_AMOUNT = -1
	TOKEN0_BALANCE = 0
	TOKEN1_BALANCE = 0
	TX = {
		address: null,
		collateralAmount: 0,
		numTokens: 0
	}
	

	constructor(address, {synthetics, wallet}){
		super()
		this._ADDRESS = address
		this._SYNTHETICS = synthetics
		this._WALLET = wallet
	}

	init(){
		const sub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._TOKEN0 = emp?.token0
			this._TOKEN1 = emp?.token1
			this._TOKEN_PRICE = emp?.token0?.price
			this._update()

			const t0Bal = this._WALLET.state.subscribe(`balances.${this._TOKEN0.symbol}`, ({balance}) => {
				this.TOKEN0_BALANCE = balance
				this._update()
			}, true)
			this._SUBSCRIPTIONS.push(t0Bal)

			const t1Bal = this._WALLET.state.subscribe(`balances.${this._TOKEN1.symbol}`, ({balance}) => {
				this.TOKEN1_BALANCE = balance
				this._update()
			}, true)
			this._SUBSCRIPTIONS.push(t1Bal)


			this._calculateApprovals()
		}, true)
		
		this._SUBSCRIPTIONS.push(sub)
	}

	updateInputAmount(amount){
		amount = !amount || isNaN(amount) ? 0 : amount
		this.INPUT_AMOUNT = amount
		this._update()
	}

	updateOptions(addMore = true) {
		this._OPTIONS = addMore
		this._update()
	}

	// private
	_update(){
		if(!this._ADDRESS || !this._TOKEN_PRICE || !this._EMP) return
		
		let min = (fromWei(this._EMP?.minSponsorTokens)) - ((fromWei(this._EMP?.myPosition?.tokensOutstanding)))
		min = min > 0 ? min : 0 
		let amount = 0
		// Set the initial value
		if (this.INPUT_AMOUNT === -1) {
			amount = min > this.INPUT_AMOUNT ? min : this.INPUT_AMOUNT
		} else {
			amount = this.INPUT_AMOUNT
		}
		let collateralAmount = BN(amount).times(this._EMP?.globalCollateralisationRatio)
		collateralAmount = Math.ceil(collateralAmount)
		const noPosition = this._EMP?.myPosition?.collateral === "0" ? true : false
		const globalCR = BN(this._EMP?.totalCollateralAmount).dividedBy(this._EMP?.totalTokensOutstanding).toFixed(0)
		// usd
		const pairRate = (1/ Number(fromWei(this._TOKEN1.spotPrice))) / Number(fromWei(this._TOKEN0.spotPrice))
		const globalCRUsd = ((BN(this._EMP?.totalCollateralAmount).times(fromWei(this._TOKEN0.spotPrice))).dividedBy(this._EMP?.totalTokensOutstanding)).dividedBy(pairRate)
		const currentCR = (fromWei(this._EMP?.myPosition?.collateral)/ (fromWei(this._EMP?.myPosition?.tokensOutstanding)))
		let newCR = (Number(collateralAmount ) + Number(fromWei(this._EMP?.myPosition?.collateral))) / (Number(amount) + Number(fromWei(this._EMP?.myPosition?.tokensOutstanding)))
		let newCRUsd = newCR * (fromWei(this._TOKEN0.spotPrice) / pairRate)

		let maxToken = 0
		let maxCollateral = 0

		if (this._OPTIONS) {
			// add more collateral
			maxToken = fromWei(this.TOKEN0_BALANCE) / (this._EMP?.globalCollateralisationRatio)
			maxCollateral = fromWei(this.TOKEN0_BALANCE)
		} else {
			// use existing collateral
			maxToken  = ((Number(fromWei(this._EMP?.myPosition?.collateral)) ) / Number((globalCR))) - Number(fromWei(this._EMP?.myPosition?.tokensOutstanding)) 
			maxToken = maxToken > 0 ? maxToken : 0
			maxCollateral = BN(maxToken).times((this._EMP?.globalCollateralisationRatio))
			newCR =  Number(fromWei(this._EMP?.myPosition?.collateral)) / (Number(amount) + Number(fromWei(this._EMP?.myPosition?.tokensOutstanding)))
			newCRUsd = newCR * (fromWei(this._TOKEN0.spotPrice) / pairRate)
			collateralAmount = 0
		}
 
		// update
		this.dispatchEvent('params', {	
			address: this._ADDRESS,
			token: {
				amount: amount,
				min ,
				max:  maxToken ,
				symbol: this._EMP?.token1.symbol, 
			},
			collateral: {
				amount: (collateralAmount),
				min: min * (this._EMP?.globalCollateralisationRatio) ,
				max: maxCollateral, 
				symbol: this._EMP?.token0.symbol,
			},
			tx: this._formatTx(this._ADDRESS, toWei(collateralAmount), toWei(amount)),
			options: this._OPTIONS,
			noPosition,
			globalCR : (globalCR),
			globalCRUsd: Number(globalCRUsd),
			newCR : newCR,
			newCRUsd: Number(newCRUsd),
			currentCR: currentCR,
		});
	}

	_formatTx(address, collateralAmount=0, numTokens=0){
		return {
			address: address,
			collateralAmount: collateralAmount,
			numTokens: numTokens
		}
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._TOKEN0?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._TOKEN0?.address
			},
			// {
			// 	name: this._TOKEN1?.symbol,
			// 	toApprove: this._EMP?.address,
			// 	onContract: this._TOKEN1?.address
			// }
		]

		this.dispatchEvent('approvals', approvals);
	}
}

export class IncreaseController extends SubsAndEventsClass{
	_ADDRESS
	_SYNTHETICS
	_EMP
	_WALLET
	_MIN_AMOUNT = 1
	
	COLLATERAL_AMOUNT = this._MIN_AMOUNT
	TX = {
		address: null,
		collateralAmount: 0,
	}
	

	constructor(address, {synthetics, wallet}){
		super()
		this._ADDRESS = address
		this._SYNTHETICS = synthetics
		this._WALLET = wallet
	}

	init(){
		const sub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._TOKEN0 = emp?.token0
			this._TOKEN1 = emp?.token1
			this.TOKEN_SYMBOL = emp?.token0?.symbol
			this._update()
			this._calculateApprovals()

			// get token 0 (PERL) amount
			const sub1 = this._WALLET.state.subscribe(`balances.${this.TOKEN_SYMBOL}`, ({balance}) => {
				this.COLLATERAL_BALANCE = fromWei(balance)
				this._update()
			}, true)
			
			this._SUBSCRIPTIONS.push(sub1)
		})
		
		this._SUBSCRIPTIONS.push(sub)
	}

	updateInputAmount(amount){
		amount = !amount || isNaN(amount) || +amount < this._MIN_AMOUNT ? this._MIN_AMOUNT : amount
		this.COLLATERAL_AMOUNT = amount
		this._update()
	}

	// private
	_update(){
		if(!this._ADDRESS || !this.COLLATERAL_AMOUNT || !this._EMP) return
		
		// format the TX 
		this.TX = this._formatTx(this._ADDRESS, this.COLLATERAL_AMOUNT)

		const { myPosition } = this._EMP

		// update
		this.dispatchEvent('params', {	
			address: this._ADDRESS,
			symbol: this.TOKEN_SYMBOL,
			amount: this.COLLATERAL_AMOUNT,
			balance: this.COLLATERAL_BALANCE,
			min: this._MIN_AMOUNT,
			tx: this.TX,
			myPosition
		});
	}

	_formatTx(address, amount=0){
		return {
			address: address,
			collateralAmount: toWei(amount),
		}
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._TOKEN0?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._TOKEN0?.address
			},
			// {
			// 	name: this._TOKEN1?.symbol,
			// 	toApprove: this._EMP?.address,
			// 	onContract: this._TOKEN1?.address
			// }
		]

		this.dispatchEvent('approvals', approvals);
	}
}

export class RedeemController extends SubsAndEventsClass{
	_ADDRESS
	_SYNTHETICS
	_EMP
	_POOLS
	_MIN_AMOUNT = 0
	_USER_BALANCE = 0
	_FULL = false
	_CAN_REDEEM_PARTIAL = true
	_CAN_REDEEM_FULL = true
	
	TOKEN_AMOUNT = this._MIN_AMOUNT
	TX = {
		address: null,
		collateralAmount: 0,
	}
	

	constructor(address, {synthetics, wallet, pools}){
		super()
		this._ADDRESS = address
		this._SYNTHETICS = synthetics
		this._POOLS = pools
		this._WALLET = wallet
	}

	init(){
		const sub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._TOKEN0 = emp?.token0
			this._TOKEN1 = emp?.token1

			// fetching token1 balance
			const sub1 = this._WALLET.state.subscribe(`balances.${this._TOKEN1.symbol}`, ({balance}) => {
				this._USER_BALANCE = balance
				this._update()
			})
			this._SUBSCRIPTIONS.push(sub1)

			this._update()
			this._calculateApprovals()
		}, true)

		this._SUBSCRIPTIONS.push(sub)
	}

	updateInputAmount(amount){
		amount = !amount || isNaN(amount) || +amount < this._MIN_AMOUNT ? this._MIN_AMOUNT : amount
		this.TOKEN_AMOUNT = amount
		this._update()
	}

	updateRedeemFull(full=false){
		this._FULL = full
		this._update()
	}

	// private
	_update(){
		if(!this._EMP || !this._ADDRESS || isNaN(this.TOKEN_AMOUNT) || !this._TOKEN0 || !this._TOKEN1 || !this._EMP?.myPosition || !this._USER_BALANCE) return

		// calc totals
		const totals = this._calculateTotals(
			this._EMP, 
			this._USER_BALANCE
		)

		// format tx values
		const tx = this._formatTx(
			this._ADDRESS, 
			this.TOKEN_AMOUNT
		)
		
		// calc value in collateral
		const value = this._calculateValue(
			this._EMP?.myPosition?.collateral, 
			this._EMP?.myPosition?.tokensOutstanding, 
			this.TOKEN_AMOUNT
		)
 
		this.dispatchEvent('params', {	
			address: this._ADDRESS,
			...totals,
			value: fromWei(!isNaN(value) ? value: 0),
			symbol: this._TOKEN0.symbol,
			tx: tx,
			userBalance: this._USER_BALANCE
		});
	}

	// calc totals based on user values and this position
	_calculateTotals(emp, balance){
		// cal token amount
		let min = this._MIN_AMOUNT
		let max = 0
		let amount = this.INPUT_AMOUNT
		let full = this._FULL
		let canRedeemFull = true
		let canRedeemPartial = true

	
		// partial check
		if(BN(emp?.myPosition?.tokensOutstanding).minus(emp?.minSponsorTokens).toFixed() <= 0){
			full = true
			canRedeemPartial = false
		}else{
			canRedeemPartial = true
		}

		
		// full check
		if(+fromWei(balance) < +fromWei(BN(emp?.myPosition?.tokensOutstanding).toFixed())){
			canRedeemFull = false
		}else{
			canRedeemFull = true
		}

		
		// full exit
		if(full === true) {
			amount = min = max = +fromWei(emp?.myPosition?.tokensOutstanding)
		}
		// partial redeem
		else{
			max = +fromWei(BN(emp?.myPosition?.tokensOutstanding).minus(emp?.minSponsorTokens).toFixed())
			if(+fromWei(balance) < +max) max = +fromWei(balance)
		}

		return {
			amount: amount,
			min: min,
			max: max,
			full: full,
			canRedeemFull: canRedeemFull,
			canRedeemPartial: canRedeemPartial,
		}
	}

	_formatTx(address, amount=toWei(0)){
		return {
			address: address,
			numTokens: toWei(amount),
		}
	}

	_calculateValue(collateral, outstanding, amount){
		return BN(collateral).times(BN(toWei(amount))).dividedBy(BN(outstanding)).toFixed(0)
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._TOKEN1?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._TOKEN1?.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}
}

export class LiquidationController extends SubsAndEventsClass{
	_ADDRESS
	_SPONSOR
	_SPONSOR_POSITION
	_SYNTHETICS
	_EMP
	_MIN_INPUT_AMOUNT = 0
	_USER_BALANCE = 0
	_FULL = false
	_CAN_LIQUIDATE_PARTIAL = true
	_CAN_LIQUIDATE_FULL = true

	INPUT_AMOUNT = 1
	TX = {
		address: null,
		collateralAmount: 0,
		numTokens: 0
	}
	
	constructor(address, sponsor, {synthetics, wallet}){
		super()
		this._ADDRESS = address
		this._SPONSOR = sponsor
		this._SYNTHETICS = synthetics
		this._WALLET = wallet
	}

	init(){
		const empSub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._calculateApprovals()

			// fetch position 
			const posSub = this._SYNTHETICS.getSponsorPosition(this._ADDRESS, this._SPONSOR, position => {
				this._SPONSOR_POSITION = position
				this._TOTAL_TOKENS = this._SPONSOR_POSITION?.tokensOutstanding
				this._TOTAL_COLLATERAL = this._SPONSOR_POSITION?.collateral
				this._update()
				
			})
			this._SUBSCRIPTIONS.push(posSub)

			// fetching token1 balance
			const tokenBalSub = this._WALLET.state.subscribe(`balances.${this._EMP?.token1?.symbol}`, ({balance}) => {
				this._USER_BALANCE = balance
				this._update()
			})
			this._SUBSCRIPTIONS.push(tokenBalSub)


		}, true)
		
		this._SUBSCRIPTIONS.push(empSub)
	}

	updateAmount(amount){
		this.INPUT_AMOUNT = !amount || isNaN(amount) ? 0 : amount
		this._update()
	}

	updateLiquidateFull(full=false){
		this._FULL = full
		this._update()
	}

	// private
	_update(){
		if(!this._ADDRESS || !this.INPUT_AMOUNT || !this._TOTAL_TOKENS || !this._TOTAL_COLLATERAL || !this._EMP || !this._USER_BALANCE) return

		const totals = this._calculateTotals(
			this._EMP, 
			this._SPONSOR_POSITION, 
			this._USER_BALANCE
		)
		
		const tx = this._formatTx(
			this._ADDRESS, 
			this._SPONSOR, 
			this._EMP.minCollateral, 
			this._EMP.collateralRequirement,
			this.INPUT_AMOUNT,
			this._EMP.expirationTimestamp,
		)

		const collateralAmount = this._TOTAL_COLLATERAL / 100 * (100 / this._TOTAL_TOKENS * totals.amount)

		// update
		this.dispatchEvent('params', {	
			symbol: this._EMP?.token1.symbol,
			...totals,
			collateralAmount: collateralAmount,
			collateralSymbol: this._EMP?.token0.symbol,
			tx: tx,
		});
	}

	// calc totals based on user values and this position
	_calculateTotals(emp, position, balance){
		// cal token amount
		let min = this._MIN_INPUT_AMOUNT
		let max = 0
		let amount = this.INPUT_AMOUNT
		let full = this._FULL
		let canLiquidateFull = true
		let canLiquidatePartial = true

		// partial check
		if(BN(toWei(position?.tokensOutstanding)).minus(emp?.minSponsorTokens).toFixed() <= 0){
			full = true
			canLiquidatePartial = false
		}else{
			canLiquidatePartial = true
		}

		// full check
		if(+fromWei(balance) < +position?.tokensOutstanding){
			canLiquidateFull = false
		}else{
			canLiquidateFull = true
		}
		
		// full liquidation
		if(full === true) {
			amount = min = max = +position?.tokensOutstanding
		}
		// partial liquidation
		else{
			max = +fromWei(BN(toWei(position?.tokensOutstanding)).minus(emp?.minSponsorTokens).toFixed())
			if(+fromWei(balance) < +max) max = +fromWei(balance)
		}

		return {
			min: min,
			max: max,
			amount: amount,
			full: full,
			canLiquidateFull: canLiquidateFull,
			canLiquidatePartial: canLiquidatePartial,
		}
	}

	_formatTx(){

		const dept = this._SPONSOR_POSITION?.tokensOutstanding
		const collateral = this._SPONSOR_POSITION?.collateral

		const collateralPerToken = BN(collateral).dividedBy(dept)
		const minCollateralPerToken = BN(collateralPerToken).times(0.5).toFixed(0)
		const maxCollateralPerToken = BN(collateralPerToken).times(1.5).toFixed(0)

		return {
			address: this._ADDRESS,
			sponsor: this._SPONSOR,
			minCollateralPerToken: toWei(minCollateralPerToken),
			maxCollateralPerToken: toWei(maxCollateralPerToken),
			maxTokensToLiquidate: toWei(this.INPUT_AMOUNT),
			deadline: moment().add(this._EMP?.liquidationLiveness, 'seconds').add(60, 'seconds').unix()
		}
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._EMP?.token0?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._EMP?.token0?.address
			},
			{
				name: this._EMP?.token1?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._EMP?.token1?.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}
}


export class WithdrawController extends SubsAndEventsClass{
	_ADDRESS
	_SYNTHETICS
	_EMP
	_POOLS
	_WALLET
	_FAST = true

	AMOUNT = 0

	TX = {
		address: null,
		collateralAmount: 0,
	}

	constructor(address, {synthetics, wallet, pools}){
		super()
		this._ADDRESS = address
		this._SYNTHETICS = synthetics
		this._POOLS = pools
		this._WALLET = wallet
	}

	init(){
		const sub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._TOKEN0 = emp?.token0
			this._TOKEN1 = emp?.token1
			this.TOKEN_SYMBOL = emp?.token0?.symbol
			this._update()
			this._calculateApprovals()

			
		})
		
		this._SUBSCRIPTIONS.push(sub)
	}

	updateFastWithdraw(fast=true){
		this._FAST = fast
		this.AMOUNT = 0
		this._update()
	}

	updateInputAmount(amount){
		// amount = !amount || isNaN(amount) || +amount < this._MIN_AMOUNT ? this._MIN_AMOUNT : amount
		this.AMOUNT = amount
		this._update()
	}

	// private
	_update(){
		if(!this._EMP || !this._ADDRESS || !this._EMP?.myPosition ) return

		const cumulativeFeeMultiplier = this._EMP?.cumulativeFeeMultiplier
		// Calculates my raw CR
		const collateral = this._EMP?.myPosition?.collateral
		const fairCollateral = BN(collateral).times(cumulativeFeeMultiplier).toFixed(0)
		// const remainingCollateral = BN(collateral).minus(BN(toWei(this.AMOUNT))).times(cumulativeFeeMultiplier).toFixed(0)
		const tokensOutstanding = this._EMP?.myPosition?.tokensOutstanding

		const myCR = BN(fairCollateral).dividedBy(tokensOutstanding).toFixed(0)
		// Calculates global CR
		const rawTotalPositionCollateral = this._EMP?.rawTotalPositionCollateral
		const totalCollateralAmount = BN(rawTotalPositionCollateral).times(cumulativeFeeMultiplier).toFixed(0)
		const totalTokensOutstanding = this._EMP?.totalTokensOutstanding
		const gcr = BN(totalCollateralAmount).dividedBy(totalTokensOutstanding).toFixed(0)

		const max = (fromWei(myCR) - fromWei(gcr) > 0 ) ? BN(myCR).minus(BN(gcr)).times(tokensOutstanding).dividedBy(cumulativeFeeMultiplier).toFixed(0) : 0
		const withdrawalLiveness = this._EMP?.withdrawalLiveness

		const isOngoingWithdraw = this._EMP?.myPosition?.withdrawalRequestPassTimestamp ==="0" ? true : false

		// Calculates new CR
		const newCR = (Number(fromWei(collateral)) - Number(this.AMOUNT)) /  (Number(fromWei(this._EMP?.myPosition?.tokensOutstanding))   )
		
		// usd
		const pairRate = (1/ Number(fromWei(this._TOKEN1.spotPrice))) / Number(fromWei(this._TOKEN0.spotPrice))
		const newCRUsd = newCR * (fromWei(this._TOKEN0.spotPrice) / pairRate)

		// format tx values
		const tx = this._formatTx(
			this._ADDRESS, 
			this.AMOUNT
		)

		this.dispatchEvent('params', {
			address: this._ADDRESS,
			fast: this._FAST,
			amount: this.AMOUNT	,
			symbol: this.TOKEN_SYMBOL,
			max : (max),
			withdrawalLiveness,
			newCR,
			newCRUsd : Number(newCRUsd),
			myCollateral : {
				collateral,
				fairCollateral,
				tokensOutstanding,
				// remainingCollateral,
				cr: myCR
			},
			globalCollateral : {
				collateral : rawTotalPositionCollateral,
				fairCollateral : totalCollateralAmount,
				tokensOutstanding : totalTokensOutstanding,
				cr: gcr
			},
			tx : tx,
			isOngoingWithdraw
		});
	}

	_formatTx(address, amount=toWei(0)){
		return {
			address: address,
			numTokens: toWei(amount),
		}
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._TOKEN0?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._TOKEN0?.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}



}

export class RedeemAllController extends SubsAndEventsClass{
	_ADDRESS
	_SYNTHETICS
	_EMP
	_POOLS
	_WALLET

	constructor(address, {synthetics, pools, wallet}) {
		super()
		this._ADDRESS = address
		this._SYNTHETICS = synthetics
		this._POOLS = pools
		this._WALLET = wallet
	}

	init() {
		const sub = this._SYNTHETICS.state.subscribe(`emps.${this._ADDRESS}`, emp => {
			this._EMP = emp
			this._TOKEN0 = emp?.token0
			this._TOKEN1 = emp?.token1
			// this.TOKEN_SYMBOL = emp?.token0?.symbol
			this._update()
			this._calculateApprovals()			
		})
		
		this._SUBSCRIPTIONS.push(sub)
	}

	// private
	_update(){
		if(!this._EMP ) return

		const tx = {
			address : this._ADDRESS
		}

		this.dispatchEvent('params', {	
			expiryStatus : Number(this._EMP.contractState) ,
			expiryPrice : this._EMP.expiryPrice,
			tx
		});
	}

	_calculateApprovals(){
		const approvals = [
			{
				name: this._TOKEN1?.symbol,
				toApprove: this._EMP?.address,
				onContract: this._TOKEN1?.address
			}
		]

		this.dispatchEvent('approvals', approvals);
	}

}