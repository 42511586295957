import { useState, useEffect } from 'react';
import assetController from '@app/App.Controller'

export default ({onFail=()=>null, children}) => {
	const { perlinx } = assetController
	const [ content, setContent ] = useState(null)
	
	useEffect(() => {
		if(perlinx.state.isAdmin === true){
			setContent(children)
		}else{
			const fail = onFail()
			setContent(fail)
		}
	}, []) // eslint-disable-line

	return content
}