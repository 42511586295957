import React, { useState, useEffect, Fragment } from 'react';
import { Amount, Grid, Panel, Stat, Tabbed, GateKeeper, LazyBoi } from '@components'
import { Asset } from '@archetypes'
import { fromWei, formatCommas } from '@util/helpers'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';
import { SwapController } from './_controller'
import assetController, { Status } from '@app/App.Controller'

const SwapPanel = ({address, action}) => {
	const { balancer } = assetController

	// approval params
	const [ approvalStatus, setApprovalStatus ] = useState()
	const [ approvalParams, setApprovalParams ] = useState()
	const [ controller, setController ] = useState()
	const [ swapParams, setSwapParams ] = useState()

	useEffect(() => {
		const swapController = new SwapController(address, action, assetController)
		swapController.on('approvals', setApprovalParams)
		swapController.on('update', setSwapParams)
		setController(swapController)
		swapController.init()
		return () => swapController.cleanup()
	}, [action]) // eslint-disable-line

	return <Panel>
		<GateKeeper
			controllerAssertions={[
				{
					controller: 'account',
					key: 'status',
					assert: val => val === Status.READY,
					onFail: 'Connect your wallet to start staking',
				}
			]}
			assertions={[
				{
					assert: () => !!swapParams,
					onFail: () => <Fragment><Refresh className='rotate'/> Fetching account info</Fragment>,
				},
				{
					assert: () => +swapParams?.balance > 0,
					onFail: () => <Fragment>You have no {swapParams?.tokenSell?.symbol||'tokens'} to sell</Fragment>,
				}
			]}
			dependencies={[swapParams]}
			>
			<Amount.Group separator='='>
				<Amount 
					title={swapParams?.tokenSell?.symbol} 
					info={`Balance: ${formatCommas(+fromWei(swapParams?.balance))} ${swapParams?.tokenSell?.symbol}`} 
					min={0}
					max={fromWei(swapParams?.balance)}
					onChange={pc => controller?.updateInputPercent(pc)}
					withPresets
					asPercent
				/>
				<Amount 
					title={<Fragment>{swapParams?.tokenBuy?.symbol} {!swapParams?.toAmount && <Refresh className='rotate'/>}</Fragment>}
					value={fromWei(swapParams?.toAmount)}
					min={0}
					disabled
				/>
			</Amount.Group>

			<Panel.Divider>
				<Asset.ContractApproval
					approvals={approvalParams}
					onUpdate={setApprovalStatus}
				/>
			</Panel.Divider>

			<Panel.Footer>
				<Panel.Footer.Group>
					<Grid cols={2}>
						<Stat title='Slip' small>
							<LazyBoi value={swapParams?.slippage} suffix={'%'} fromWei/>
						</Stat>
						<Stat title='Liquidity provider fee' small>
							<LazyBoi.Wei value={swapParams?.providerFee} suffix={swapParams?.tokenSell?.symbol} dp={2}/>
						</Stat>
					</Grid>
				</Panel.Footer.Group>

				<Panel.Footer.Group>
					<Asset.ContractApproval.Button
						{...approvalStatus}
						onClick={() => balancer.swap(swapParams.poolAddress, swapParams.tx)}
						>
						{action === 'buy' ? `Buy ${swapParams?.tokenBuy?.symbol}` : `Sell ${swapParams?.tokenSell?.symbol}`}
					</Asset.ContractApproval.Button>
				</Panel.Footer.Group>
			</Panel.Footer>
		</GateKeeper>
	</Panel>
}

const Swap = ({address}) => {

	const { perlinx } = assetController
	const [ pool, setPool ] = useState()

	useEffect(() => {
		let sub = perlinx.state.subscribe(`pools.${address}`, setPool, true)
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Tabbed>
		<Tabbed.Child title={`Buy ${pool?.token1?.symbol||''}`}>
			<SwapPanel address={address} action={'buy'}/>
		</Tabbed.Child>
		<Tabbed.Child title={`Sell ${pool?.token1?.symbol||''}`}>
			<SwapPanel address={address} action={'sell'}/>
		</Tabbed.Child>
	</Tabbed>
}

export default Swap