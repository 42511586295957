import React, { useState, useEffect  } from 'react';
import styled from 'styled-components'
import { AddressLink } from '@components'
import assetController from '@app/App.Controller'
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as IconCheck } from '@assets/Icon_Check.svg';
import { ReactComponent as IconCancel } from '@assets/Icon_Cancel.svg';

const TransactionItem = styled(
	({status, title, info, hash, className}) => {
		return <div className={`transaction ${className}`}>
			<span>
				{status === 'pending' && <IconRefresh className='icon-pending rotate'/>}
				{status === 'success' && <IconCheck className='icon-success'/>}
				{status === 'error' && <IconCancel className='icon-error'/>}
				{status === 'warning' && <IconCancel className='icon-warning'/>}
			</span>
			<span>
				<div className="-title">{title}</div>
				{
					(info || hash) && <div className="-info">
						{info}
						{hash && <AddressLink hash={hash} text='View on etherscan'/>}
					</div>
				}
				
			</span>
		</div>
	})`
	font-size: var(--font-size-normal);
	display: flex;
	align-items: flex-start;
	padding: 2rem 0;
	
	>*:first-child{
		margin-right 1em;
		svg{
			font-size: var(--font-size-large);
		}
	}
	
	.-title{
		display: block;
		white-space: nowrap;
	}

	.-info{
		display: block;
		font-size: var(--font-size-small);
		color: var(--color-label);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 22rem;
		margin-top: 0.5rem;
	}

	.-link{
		display: flex;
		align-items: center;
		white-space: nowrap;
		font-size: var(--font-size-small);
		color: var(--color-label);

		.offsite{
			margin-left: 0.5rem;
		}
	}
	`

const Transactions = styled(
	({className}) => {
		const { transactions } = assetController
		const [ items, setItems ] = useState([])

		useEffect(() => {

			const sub = transactions.state.subscribe('items', items => {
				const reversedItems = Object.values(items).reverse()
				setItems(reversedItems)
			}, true)

			return () => sub.unsubscribe()
		}, []) // eslint-disable-line

		return <div className={`transactions ${className}`}>
			{items.map((item, i) => <TransactionItem key={i} {...item}/>)}
		</div>
	})`
	line-height: 1em;	
	overflow: hidden;
	transition: inherit;

	.transaction{
		&:first-child{ padding-top: 1rem}
		&:last-child{ padding-bottom: 1rem}
		& + .transaction{
			border-top: 1px solid rgba(255,255,255, 0.1);
		}
	}


	`

Transactions.Button = styled(
	({className}) => <span 
		className={`transaction-button ${className}`}
		//onMouseLeave={handleClose}
		//onMouseEnter={handleOpen}
		>
		<span className='-content'>
			<Transactions/>
		</span>
		<span className="-trigger" >
			<IconRefresh className='-trigger'/>
		</span>
	</span>
	)`
	position: relative;
	user-select: none;

	.-trigger{
		cursor: pointer;
		transition: all 0.15s;
		white-space: nowrap;
		display: block;

		>*{
			display: block;
		}
		
		&:hover:after{
			opacity: 1;
		}
	}
	
	.-content{
		box-shadow: 0 0 1rem rgba(0,0,0,0.1);
		background: var(--popover--background-color, white);
		color: var(--popover--color, black);
		border: 1px solid var(--popover--border-color, transparent);
		border-radius: var(--popover--border-radius, 0);

		max-height: 0;
		overflow: hidden;
		overflow-y: scroll;
		border-width: 0;

		position: absolute;
		top: calc(100% + 0.3em);
		right: 0;
		
		transition: all 0.15s ease-in-out;
	}

	&:hover{
		.-content{
			max-height: 20rem;
			border-width: 1px;
		}
	}

	
	`

export default Transactions