import React from 'react';
import styled from 'styled-components'
import { Button } from '@components'

export default styled(
	({icon, title, text, links=[], className, ...rest}) => {
		return <section className={`placeholder ${className}`}>
			{icon}
			{title && <h1>{title}</h1>}
			{text && <p>{text}</p>}
			{links.length && 
				<div className="links">
					{links.map(({to, text}, i) => <Button key={i} small to={to}>{text}</Button>)}
				</div> 
			}
		</section> 
	})`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;

	>*{
		margin: 0;
		//display: block;
		//margin: 0 auto;
		//text-align: center;
	}

	>svg{
		font-size: 5.4rem;
		margin-bottom: 2rem;
	}

	>h1{
		margin-bottom: 1.2rem;
		font-size: var(--font-size-medium);
	}

	>p{
		color: var(--color-label)
	}

	.links{
		margin-top: 2.8rem;
	}
	`