import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Refresh } from '@assets/Icon_Refresh.svg';

const Stat = styled(
	({ title, animateChange=false, children, className, truncate=true }) => {
		
		const [loading, setloading] = useState(false)

		useEffect(() => {
			if(!!animateChange){
				setloading(true)
				setTimeout(() => setloading(false), 500)
			}
		}, [children]) // eslint-disable-line

		return <div 
			className={`stat ${className}`}
			//title={`${title}: ${children}`}
			data-loading={loading}
			>
			{ truncate 
			?<span className="title">{title} <Refresh className="rotate"/></span>
			:<span className="title" style={{  overflow :"visible"}}>{title} <Refresh className="rotate"/></span>

			}
			<span className="info">{children}</span>
		</div>
	})`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0.6em 0;
	letter-spacing: 0.016rem;
	
	>*{
		margin: 0;
		line-height: 1.2em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	>.title{
		font-size: var(--font-size-normal, 14px);
		color: var(--color-label, lightblue);

		.rotate{
			margin-left: 0.2rem;
			font-size: 0.8em;
			opacity: 0;
			transition: opacity 0.2s;
		}
	}

	>.info{
		font-size: var(--font-size-large);
		color: var(--color-white, white);
		margin-top: 0.6em;

	}

	&[data-loading="true"] >.title .rotate{
		opacity: 0.5
	}

	${({small}) => {
		return !!small && `
			padding: 0.9rem 0;
			>.title{ font-size: var(--font-size-small); }
			>.info{ font-size: var(--font-size-normal); }
		`
	}}
	
	`

Stat.Inline = styled(
		props => <Stat {...props}/> 
	)`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	>.title,
	>.info{
		margin: 0;
		display: flex;
		align-items: center;
	}
`

export default Stat