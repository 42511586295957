import React, { useState, useEffect } from 'react';
import { Button, Table, Panel, Layout, GateKeeper, Placeholder } from '@components'
import { Asset } from '@archetypes'
import { formatTimestamp, truncateEthAddress, numberToMaxDp } from '@util/helpers'
import appController from '@app/App.Controller'
import { ReactComponent as IconCancel } from '@assets/Icon_Cancel.svg';
import { ReactComponent as IconRefresh } from '@assets/Icon_Refresh.svg';
import { ReactComponent as IconPoolsEmpty } from '@assets/Icon_PoolsEmpty.svg';


const columns = [
	{
		Header: 'Asset',
		accessor: 'asset',
	},
	{
		Header: 'Owner',
		accessor: 'owner',
	},
	{
		Header: 'Expiry',
		accessor: 'expiry',
	},
	{
		Header: 'Collateral Size',
		accessor: 'lockedCollateral',
	},
	{
		Header: 'Amount Owing',
		accessor: 'tokensOutstanding',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Amount to Liquidate',
		accessor: 'tokensLiquidated',
	},
	{
		Header: '',
		accessor: 'actions',
	}
]

const LiquidationRow = ({empAddress, id, asset, sponsor, expiry, lockedCollateral, tokensOutstanding, tokensLiquidated, status, position, handleDispute}) => <Table.Uncontrolled.Row 
	fields={{
		asset: <Asset.Icon token={asset}/>,
		owner: truncateEthAddress(sponsor),
		expiry: formatTimestamp(expiry),
		collateralSize: numberToMaxDp(lockedCollateral, 4),
		tokensOutstanding: numberToMaxDp(tokensLiquidated, 4),
		status: status,
		tokensLiquidated: numberToMaxDp(tokensLiquidated, 4),
		actions: <Button
			disabled={status === 'PendingDispute'}
			small 
			icon={<IconCancel/>}
			onClick={() => handleDispute({
				address: empAddress, 
				liquidationId: id, 
				sponsor: sponsor
			})}
			>
			Dispute
		</Button>
	}}
/>

export default () => {
	const { synthetics } = appController
	const [ liquidations, setLiquidations ] = useState()

	useEffect(() => {
		const sub = synthetics.ongoingLiquidations(liquidations => setLiquidations([...liquidations]))
		return () => sub.unsubscribe()
	}, []) // eslint-disable-line

	return <Layout 
		title="Ongoing Liquidations">
		<Layout.Main>
			<Panel fadeIn>
				<GateKeeper
					assertions={[
						{
							assert: () => !!liquidations,
							onFail: () => <Panel fadeIn><IconRefresh className='rotate'/>Fetching Ongoing Liquidations</Panel>,
						},
						{
							assert: () => liquidations.length > 0,
							onFail: () => <Placeholder 
								icon={<IconPoolsEmpty style={{marginLeft: '0.3em'}}/>} 
								title='No current liquidations' 
								text='View all synthetic assets' 
								links={[
									{
										to: '/assets',
										text: 'View All Synthetic Assets'
									}
								]} 
							/>
						}
					]}
					dependencies={[liquidations]}
					>
					<Table.Uncontrolled columns={columns}>
						{liquidations && liquidations.map((liquidation, i) => <LiquidationRow key={i} {...liquidation} handleDispute={data => synthetics.dispute(data)}/>)}
					</Table.Uncontrolled>
				</GateKeeper>
			</Panel>
		</Layout.Main>
	</Layout>
}