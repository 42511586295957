import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Liquidation } from '@archetypes'

export default () => {
	let { path } = useRouteMatch();
	return <Switch>
		<Route exact path={path} component={Liquidation.Home}/>
		<Route exact path={`${path}/dispute/:address/:sponsor`} component={Liquidation.Dispute}/>
		<Redirect to={path}/>
	</Switch>
}