import Liquidity from './Liquidity'
import LiquidityOverview from './LiquidityOverview'
import Stake from './Stake'
import Withdraw from './Withdraw'
import StakeInfo from './StakeInfo'
import MyStake from './MyStake'
import Swap from './Swap'
import SwapInfo from './SwapInfo'
import Disclaimer from "./Disclaimer"

const Pool = () => {}

Pool.Liquidity = Liquidity
Pool.LiquidityOverview = LiquidityOverview
Pool.Stake = Stake
Pool.Withdraw = Withdraw
Pool.StakeInfo = StakeInfo
Pool.MyStake = MyStake
Pool.Swap = Swap
Pool.SwapInfo = SwapInfo
Pool.Disclaimer = Disclaimer

export default Pool