import React from 'react';
import styled from 'styled-components'
import { Button } from "@components";
import { ReactComponent as Logo } from '@assets/PerlinX-Logo.svg';

const ErrorMessage = ({message, links=[]}) => {
	return <p className="-error">
		{message}
		{links.map(({text, ...props}) => <Button small {...props}>{text}</Button>)}
	</p>
}

export default styled(
	({message, errors={}, className}) => {
		return <section className={`loadingscreen ${className}`}>
			<div className="-inner">
				<Logo className="-logo"/>
				{message && <h3 className="-message">{message}</h3>}
				{Object.values(errors).map(error => <ErrorMessage {...error}/>)}
			</div>
		</section>
	})`
	//display: block;	
	//padding: 0.65em 2.5em;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.-inner{
		position: absolute;
		top: 40%;
		left: 50%;
		max-width: 90vw;
		max-height: 90vh;
		transform: translate(-50%, -50%);
		width: 80rem;
		height: auto;
		padding: 3rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		//background: rgba(0,0,0,0.1);
		
		.-logo{
			width: 16rem;
			height: auto;
			margin-bottom: 2rem;
		}

		.-message{
			font-weight: 100;
		}

		.-error{
			color: var(--color-label);

			.button{
				margin-left: 1em;
				color: var(--color-label);
			}
		}

	}

	
	`